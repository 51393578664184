
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import helpers from "@/helpers/global";

import LogicTestScores from "@/components/LogicTest/Scores.vue";

let loadingEl: any;
Chart.register(ChartDataLabels);

export default defineComponent({
  name: "MainCardReults",
  props: ["disabledRating", "locale", "isRespondent"],
  components: {
    LogicTestScores,
  },
  watch: {
    error(value) {
      console.error(value);
    },
    locale(value) {
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(value);
      this.$store.commit("assessment/locale", value, { root: true });
    },
    loading(value) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Sending...",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
    data(value) {
      if (value) {
        // this component used by candidate as a result not company view
        if (this.isRespondent) {
          if (value && value.lang) {
            let locale = String(value.lang) || "en";
            const lang = this.$router.currentRoute.value.meta.lang;
            if (lang && lang == "TH") {
              locale = "th";
            } else if (lang && lang == "EN") {
              locale = "en";
            }
            (this as any).$i18n.setLocale(locale);
            this.$store.commit("assessment/locale", locale, { root: true });
          }
        }

        this.focusUser.firstname = value.firstname;
        this.focusUser.companyName = value.company_name;
      }
    },
    formTemplate(newValue) {
      this.applyFormTemplate(newValue);
    },
    summaries(newValue) {
      this.applySummaries(newValue);
    },
  },
  data() {
    return {
      assessmentsName: "Culture Fit for Jan 2022 Batch",
      culturePreferenceFocus: {
        value: 82,
        title: "Overall",
        title_th: "ภาพรวม",
        id: "Overall",
        status: "green",
        description: "Select a dimension to learn more",
        candidate: {
          clan: 0,
          create: 0,
          complete: 0,
          control: 0,
        },
        company: {
          clan: 0,
          create: 0,
          complete: 0,
          control: 0,
        },
      },
      focusUser: {
        id: "no_user",
        name: "-",
        firstname: "-",
        lastname: "-",
        companyName: "-",
        email: "=",
        status: "Completed",
        joined: "=",
        completed: "=",
        score: "-",
        yourRating: 0,
        valuesAndTraits: {
          overall: "72%",
          values: "88%",
          valueTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "yellow",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "red",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
          traits: "72%",
          traitTags: [
            {
              label: "Integrity",
              id: "Integrity",
              status: "green",
            },
            {
              label: "Entrepreneurship",
              id: "Entrepreneurship",
              status: "green",
            },
            {
              label: "Respect",
              id: "Respect",
              status: "green",
            },
            {
              label: "Honesty",
              id: "Honesty",
              status: "green",
            },
            {
              label: "Excellence",
              id: "Excellence",
              status: "yellow",
            },
            {
              label: "Growth",
              id: "Growth",
              status: "yellow",
            },
            {
              label: "Winning",
              id: "Winning",
              status: "yellow",
            },
            {
              label: "Collaboration",
              id: "Collaboration",
              status: "red",
            },
          ],
        },
        culturePreference: {
          overall: "76%",
          overallStatus: "green",
          data: [
            {
              value: "82%",
              title: "Overall",
              id: "Overall",
              status: "green",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "82%",
              title: "Dominant Characteristics",
              id: "Dominant Characteristics",
              status: "green",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "81%",
              title: "Organizational Leadership",
              id: "Organizational Leadership",
              status: "green",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "79%",
              title: "Management of Employees",
              id: "Management of Employees",
              status: "yellow",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "75%",
              title: "Organization Glue",
              id: "Organization Glue",
              status: "yellow",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "70%",
              title: "Strategic Emphases",
              id: "Strategic Emphases",
              status: "yellow",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
            {
              value: "59%",
              title: "Criteria of Success",
              id: "Criteria of Success",
              status: "red",
              description:
                "The overall overlap between the candidate's ideal work culture and your organization's work culture.",
              user: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
              company: {
                clan: 11,
                create: 12,
                complete: 14,
                control: 12,
              },
            },
          ],
        },
        acceptableBehaviors: {
          overall: "76%",
          scenarioData: [
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description: "Regularly showing up 5-min late to work",
              matchingStatus: true,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Frowned Upon",
              userStatus: "green",
            },
            {
              id: "soelja-5a2e58x-a41e2d",
              title: "Scenario 2",
              description:
                "Unresponsive over chat for most of the day without an away status.",
              matchingStatus: false,
              companyValueLabel: "Frowned Upon",
              companyStatus: "green",
              userValueLabel: "Acceptable",
              userStatus: "red",
            },
            {
              id: "soelja-5ae58x-a41ed",
              title: "Scenario 1",
              description:
                "Regularly showing up 5-min late to work, It is description too longgggggggggggggggg ggggggggg woard on here and show what is going on, testset settset",
              matchingStatus: true,
              companyValueLabel: "Frowned test",
              companyStatus: "yellow",
              userValueLabel: "Frowned test",
              userStatus: "yellow",
            },
          ],
        },
      },
      logicTestRenderChart: false,
      logicTestSelected: {
        id: "",
        label: "",
        value: "",
      },
      culturePreferenceChartTag: "",
      radarChartVal: {},
      culturePreferenceRenderChart: false,
      doughnutChartVal: {},
      tooltipOncultureStatus: true,
      tooltipOncultureLabelPrefix: "",
      tooltipOncultureLabel: {
        collaborate: "",
        create: "",
        compete: "",
        control: "",
      },
      id: "",
      skills: [] as any,
    };
  },
  async created() {
    const user = this.$store.getters["user/user"];
    if (!user) {
      await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
    }
  },
  computed: {
    error() {
      return this.$store.getters["assessment/error"];
    },
    loading() {
      return this.$store.getters["assessment/loading"];
    },
    data() {
      return this.$store.getters["assessment/data"] || {};
    },
    logictestSection() {
      return this.$store.getters["assessmentSection/logicTest"] || {};
    },
    unselectedQuestions() {
      return this.$store.getters["assessment/unselectedQuestions"] || {};
    },
    unselectedValueQuestions() {
      const unselectedQuestions = this.unselectedQuestions;
      return unselectedQuestions.defining_your_values;
    },
    unselectedTraitQuestions() {
      const unselectedQuestions = this.unselectedQuestions;
      return unselectedQuestions.identifying_key_traits;
    },
    formTemplate() {
      return this.$store.getters["assessment/formTemplate"] || {};
    },
    isV2(): boolean {
      return Object.prototype.hasOwnProperty.call(
        this.formTemplate,
        "values_and_traits_v_2"
      );
    },
    hasAcceptableBehaviors() {
      const data = this.data;
      const hasAcceptableBehaviors = data.acceptable_behaviors || false;
      return hasAcceptableBehaviors;
    },
    summaries() {
      return this.$store.getters["assessment/summaries"] || {};
    },
    companyColor() {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    workStyleFocusedType() {
      let result = "";
      const data = this.data || {};
      if (data && data.summaries && data.summaries.work_style_identifier) {
        const overall = data.summaries.work_style_identifier.overall;
        let objects = _.sortBy(
          [
            {
              name: "People",
              value: overall.people,
            },
            {
              name: "Process",
              value: overall.process,
            },
            {
              name: "Product",
              value: overall.product,
            },
          ],
          "value"
        );
        const max = objects[objects.length - 1].value;
        objects = _.filter(objects, { value: max });
        for (let i = 0; i < objects.length; i++) {
          let sep = i > 0 ? ", " : "";
          if (objects.length > 1 && i == objects.length - 1) sep = " and ";
          result += sep + objects[i].name + "-focused";
        }
      }
      return result;
    },
  },
  async mounted() {
    this.$store.dispatch("forceReady", null, { root: true });
    const valid = this.checkPropsId();
    if (valid) {
      await this.initialize();
      this.renderWorkStyleDoughnutChart();
    }
  },
  unmounted() {
    this.$store.dispatch("assessment/unsubscribeSummaries", null, {
      root: true,
    });
  },
  methods: {
    applyFormTemplate(formTemplate: any) {
      if (
        !formTemplate.parsed &&
        _.get(
          formTemplate,
          "values_and_traits_custom_fields.values_included_options"
        )
      ) {
        const questions = _.get(
          formTemplate,
          "values_and_traits_custom_fields.values_included_options"
        );
        if (_.get(formTemplate, "values_and_traits_v_2.values")) {
          for (const section in formTemplate.values_and_traits_v_2.values) {
            const map =
              formTemplate.values_and_traits_v_2.values[section] || {};
            let items = [];
            for (const qid in map) {
              const val = map[qid];
              const question = _.find(questions, { key: qid });
              const lang = (this as any).$i18n.getLocale() || "en";
              const name = lang == "en" ? question.labelEN : question.labelTH;
              items.push({
                qid: qid,
                val: val,
                name: name,
              });
            }
            items = _.sortBy(items, ["name"]);
            const newMap: any = {};
            for (const i in items) {
              newMap[items[i].qid] = items[i].val;
            }
            formTemplate.values_and_traits_v_2.values[section] = newMap;
          }
          formTemplate.parsed = true;
        }
      }
      return formTemplate;
    },
    applySummaries(summaries: any) {
      // this view want to sort values and traits by score
      const hasOwnProperty = Object.prototype.hasOwnProperty;
      if (hasOwnProperty.call(summaries, "values_and_traits")) {
        for (const i in summaries.values_and_traits.scenarios) {
          const scenario = summaries.values_and_traits.scenarios[i];
          const questions = scenario.questions;
          const name = _.snakeCase(scenario.name);
          const values = summaries.values_and_traits.candidate[name];
          questions.sort((a: any, b: any) => {
            const scoreA = values[a.id];
            const scoreB = values[b.id];
            if (scoreA == scoreB) {
              if (a && b) {
                const titleA = a.name;
                const titleB = b.name;
                return titleA > titleB;
              }
            }
            return scoreB - scoreA;
          });
        }
      }
      return summaries;
    },
    has(variable: any, key: string) {
      return _.has(variable, key);
    },
    get(variable: any, key: string) {
      return _.get(variable, key);
    },
    getCultureProfileDesc(row: number, col: number) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      const index = row * 2 + col;
      const orderedFields = [
        "Dominant_Characteristics",
        "Organizational_Leadership",
        "Management_of_Employees",
        "Organization_Glue",
        "Strategic_Emphases",
        "Criteria_of_Success",
      ];

      // AI Analytic
      let path = `summaries.oaic_archetypes.prompt_result_json_${lang}`;
      let desc = "";
      if (_.has(this.data, path)) {
        const promptResult = _.get(this.data, path);
        desc = _.get(promptResult, _.get(orderedFields, index - 1));
      }

      // Default description
      if (!desc) {
        const field = lang == "en" ? "sum_description" : "sum_description_th";
        path = `summaries.oaic_archetypes.scenarios[${index}].${field}`;
        desc = _.get(this.data, path);
      }

      return desc;
    },
    customSurveyGetAnswer(scenarioIndex: string) {
      const data = this.data || {};
      const value = _.get(data, "custom_survey." + scenarioIndex, {});
      const values = Object.values(value);
      const firstValue = values[0];
      return firstValue;
    },
    customSurveyIsCheeck(scenarioIndex: string, choiceIndex: number) {
      const userValues = this.customSurveyGetAnswer(scenarioIndex);
      // const index = parseInt(scenarioIndex.split("_")[1]) - 1;
      // const choices = _.get(
      //   this.formTemplate,
      //   `custom_survey_custom_fields.${index}.choices`,
      //   []
      // );
      if (Array.isArray(userValues)) {
        return _.indexOf(userValues, choiceIndex) !== -1;
      } else {
        return choiceIndex === userValues;
      }
    },
    log(val: any) {
      console.log(val, "val");
      console.log(Object.keys(val), "keys of val");
    },
    async initialize() {
      const u_id = String(this.$router.currentRoute.value.query.u_id);
      const _id = decodeURIComponent(atob(u_id));
      this.id = _id;

      // @note DO NOT compute score all time
      this.$store.commit("assessment/ignoreLoading", true);
      await this.$store.dispatch(
        "assessment/getSummaries",
        { _id },
        {
          root: true,
        }
      );
      this.$store.commit("assessment/ignoreLoading", false);

      // for graph rendering
      this.focusUser.firstname = this.data.firstname;
      this.focusUser.companyName = this.data.company_name;

      if (this.data.summaries && this.data.summaries.oaic_archetypes) {
        for (const i in this.data.summaries.oaic_archetypes.candidate_avg) {
          this.data.summaries.oaic_archetypes.candidate_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.candidate_avg[i]
          );
          this.data.summaries.oaic_archetypes.company_avg[i] = Math.round(
            this.data.summaries.oaic_archetypes.company_avg[i]
          );
        }
      }
      // (this as any).$i18n.setLocale("en");
      // console.log((this as any).$i18n.getLocale());

      this.chartLogicTestPreference();

      const lang = (this as any).$i18n.getLocale() || "en";
      const humanSkills = _.get(this.data, "summaries.human_skills");
      if (humanSkills) {
        // Order is important!
        // Left: Written comunication, Empathy, Situmation awareness, Critical Thinking
        // Right: Courage to fail, Initiative making, Responsiveness, Excellent

        // Fix: only Thai content because the result is difference
        let humanSkillsRows = _.get(
          this.data,
          "summaries.human_skills.human_skills"
        );
        let humanSkillsRowsThai = _.get(
          this.data,
          "summaries.human_skills.human_skills_th"
        );

        const coreValuesRows: any = {};
        const coreValuesRowsThai: any = {};

        let temp: any = {};
        for (const key in humanSkillsRows) {
          if (key.indexOf("core_value_") === 0) {
            coreValuesRows[key] = humanSkillsRows[key];
          } else {
            temp[key] = humanSkillsRows[key];
          }
        }
        humanSkillsRows = temp;

        temp = {};
        for (const key in humanSkillsRowsThai) {
          if (key.indexOf("core_value_") === 0) {
            coreValuesRowsThai[key] = humanSkillsRowsThai[key];
          } else {
            temp[key] = humanSkillsRowsThai[key];
          }
        }
        humanSkillsRowsThai = temp;

        this.skills = [];
        let leftColumns: any[] = [];
        let rightColumns: any[] = [];

        // Left Columns
        for (const i in humanSkillsRows) {
          const humanSkill = humanSkillsRows[i];

          const label = i.split(/[_-]/g).map(helpers.ucfirst).join(" ");

          const contentEN = humanSkill.full_explanation;
          const contentTH =
            _.get(humanSkillsRowsThai[i], "full_explanation") ||
            _.get(coreValuesRowsThai[i], "full_explanation");
          const content =
            lang == "en" ? contentEN : contentTH ? contentTH : contentEN;

          const labelKey = label.replaceAll(" ", "_");
          const labelByLang = helpers.t("assessmentResults." + labelKey);
          leftColumns.push({
            label: labelByLang,
            content: content,
            value: humanSkill.score,
          });
        }

        leftColumns.sort((a: { label: string }, b: { label: string }) => {
          const indexMap = {
            "Written Communication": 1,
            Empathy: 2,
            "Situational Awareness": 3,
            "Critical Thinking": 4,
          };
          const aIndex = a.label || "";
          const bIndex = b.label || "";
          const $a = _.get(indexMap, aIndex);
          const $b = _.get(indexMap, bIndex);
          return $a - $b;
        });

        // const isV2 = _.has(this.formTemplate, "values_and_traits_v_2");
        const valuesOptions = _.get(
          this.formTemplate,
          "values_and_traits_custom_fields.values_included_options",
          []
        );

        const valuesOptionsMap: any = {};
        _.each(valuesOptions, (valuesOption) => {
          let key = valuesOption.labelEN || "";
          if (!key) return;

          let newKey = key;
          newKey = newKey.toLowerCase();
          newKey = newKey.replaceAll(" ", "_");
          newKey = newKey.replaceAll("/", "_");
          newKey = newKey.replaceAll("(", "_");
          newKey = newKey.replaceAll(")", "_");

          valuesOptionsMap[newKey] = valuesOption;
        });

        // Right Columns

        // Compatible with old version
        if (humanSkills.core_values) {
          let coreValues = humanSkills.core_values;
          if (lang == "th" && _.has(humanSkills, "core_values_th")) {
            coreValues = humanSkills.core_values_th;
          }
          for (const i in coreValues) {
            const coreValue = coreValues[i];
            let label = i.split(/[_-]/g).map(helpers.ucfirst).join(" ");
            label = label.replaceAll("Core Value", "");

            const newLabel = _.trim(label.toLowerCase());
            if (_.has(valuesOptionsMap, newLabel)) {
              const val = _.get(valuesOptionsMap, newLabel);
              if (val) {
                label = lang == "en" ? val.labelEN : val.labelTH;
              }
            }
            const content = coreValue.full_explanation;
            rightColumns.push({
              label: label,
              content: content,
              value: coreValue.score,
            });
          }
        } else if (Object.keys(coreValuesRows).length) {
          for (const key in coreValuesRows) {
            const coreValuesRow = coreValuesRows[key] || {};
            const coreValuesRowThai = coreValuesRowsThai[key] || {};

            const newKey = key.substr("core_value_".length);
            const valuesOption = valuesOptionsMap[newKey];
            if (!valuesOption) continue;
            // console.log(valuesOption, "valuesOption");

            const contentEN = coreValuesRow.full_explanation;
            const contentTH = coreValuesRowThai.full_explanation;
            const content = lang == "en" ? contentEN : contentTH;
            const label =
              lang == "en" ? valuesOption.labelEN : valuesOption.labelTH;

            rightColumns.push({
              label: label,
              content: content,
              value: coreValuesRow.score,
            });
          }
        } else {
          if (leftColumns.length == 4) {
            rightColumns = leftColumns.slice(2, 4);
            leftColumns = leftColumns.slice(0, 2);
          }
        }

        const maxRows = Math.max(leftColumns.length, rightColumns.length);
        const bogusColumn = {
          label: "",
          content: "",
          value: "",
          visibility: false,
        };
        for (let i = 0; i < maxRows; i++) {
          if (leftColumns[i]) {
            this.skills.push(leftColumns[i]);
          } else {
            this.skills.push(bogusColumn);
          }
          if (rightColumns[i]) {
            this.skills.push(rightColumns[i]);
          } else {
            this.skills.push(bogusColumn);
          }
        }
      }

      await this.$store.dispatch(
        "assessment/subscribeSummaries",
        { _id: _id, form_id: this.data.form_id, email: this.data.email },
        {
          root: true,
        }
      );
    },

    getQuestionNameByQid(qid: string): string {
      const questions = _.get(
        this.formTemplate,
        "values_and_traits_custom_fields.values_included_options"
      );
      const question = _.find(questions, { key: qid });
      if (!question) return "";
      const lang = (this as any).$i18n.getLocale() || "en";
      return lang == "en" ? question.labelEN : question.labelTH;
    },
    getCompanyClsByQid(qid: string, activeCls: string): string {
      const questions = _.get(
        this.summaries,
        "values_and_traits.scenarios[0].questions"
      );
      if (questions && qid) {
        const question = _.find(questions, { id: qid });
        return question ? activeCls : "unselect";
      }
      return "";
    },
    dateFormat(datetime: string) {
      return helpers.dateFormat(datetime);
    },
    donwloadPDF(value: any) {
      console.log("donwloadPDF", value);
    },
    checkPropsId() {
      let valid = true;
      const as_id = this.$router.currentRoute.value.query.as_id;
      const u_id = this.$router.currentRoute.value.query.u_id;
      if (as_id) {
        if (u_id) {
          this.focusUser.id = u_id + "";
        }
      } else {
        valid = false;
      }
      return valid;
    },
    renderChartculturePreference() {
      if (!this.culturePreferenceRenderChart) {
        this.chartculturePreference();
      } else {
        setTimeout(() => {
          this.renderChartculturePreference();
        }, 300);
      }
    },
    chartculturePreference() {
      this.culturePreferenceRenderChart = true;
      //const chartContent = this.$refs.culture_preference_chart;

      const canvas = document.getElementById("culture_preference_chart");
      const currentChart = Chart.getChart("culture_preference_chart");

      if (currentChart != undefined) {
        currentChart.destroy();
      }

      try {
        const ctx = (canvas as any).getContext("2d");
        const options = {
          elements: {
            line: {
              backgroundColor: "rgba(0, 0, 0, 0)",
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              ticks: {
                display: false,
                color: "rgba(0, 0, 0, 0)",
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              pointLabels: {
                color: "#000",
                fontSize: 14,
                font: {
                  size: 14,
                  weight: "700",
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        };
        const user = [
          this.culturePreferenceFocus.candidate.clan,
          this.culturePreferenceFocus.candidate.create,
          this.culturePreferenceFocus.candidate.complete,
          this.culturePreferenceFocus.candidate.control,
        ];
        const company = [
          this.culturePreferenceFocus.company.clan,
          this.culturePreferenceFocus.company.create,
          this.culturePreferenceFocus.company.complete,
          this.culturePreferenceFocus.company.control,
        ];

        const daatsets = [];
        daatsets.push({
          label: this.focusUser.companyName,
          // data: this.insertValues(company),
          data: company,
          pointStyle: "line",
          borderColor: this.companyColor,
          borderDash: [3, 1],
        });

        daatsets.push({
          label: this.focusUser.firstname,
          // data: this.insertValues(user),
          data: user,
          pointStyle: "line",
          // borderColor: "#30CCB4",
          borderColor: "#28be79",
          borderDash: [3, 1],
        });
        // console.log(daatsets, "daatsets");
        this.radarChartVal = new Chart(ctx, {
          type: "radar",
          data: {
            /*
            labels: [
              // "Collaborate people-oriented",
              " ",
              " ",
              // "Create dynamic & entrepreneurial",
              " ",
              " ",
              // "Complete result-oriented",
              " ",
              " ",
              // "Control process-oriented",
              " ",
              " ",
            ],
            //*/
            labels: [
              " ",
              " ",
              " ",
              " ",
              // "Collaborate people-oriented",
              // "Create dynamic & entrepreneurial",
              // "Complete result-oriented",
              // "Control process-oriented",
            ],
            datasets: daatsets,
          },
          options: options,
        });
      } catch (e) {
        console.log(e);
      }

      setTimeout(() => {
        this.culturePreferenceRenderChart = false;
      }, 1000);
    },
    checkUserAnswersLogicTest(
      answerId: any,
      answerData: any = null,
      baseAnswerSection: any = null
    ) {
      const answer = answerData[answerId];
      const baseAnswer = this.logictestSection.scenarios[
        baseAnswerSection
      ].questions.find((x: any) => x.id == answerId).answer;
      //return answer === baseAnswer ? "@/assets/image/check_mark.svg" : "@/assets/image/cross_mark.svg";
      // if (answer == baseAnswer) return true;
      // return false;
      return answer == baseAnswer;
    },
    radarChartLogicTestCandidateData(): any[] {
      const data = this.data;
      // let items: any[] = [];
      if (data) {
        const summary = data.summaries || {};
        if (summary) {
          const candidate_avg = summary.logic_test.candidate_avg;
          const dataset = [];
          const dataKeys = [
            "pattern_recognition",
            "logical_reasoning",
            "data_interpretation",
            "problem_resolving",
          ];
          for (const dataKey of dataKeys) {
            dataset.push(candidate_avg[dataKey]);
          }

          const colors = "#5b94f0";
          // const borderColor = colors[0 % colors.length];

          let item = {
            name: data.firstname,
            id: "",
            data: dataset,
            score: data.score,
            pointStyle: "line",
            color: colors,
          };

          return [item];
        }
      }
      return [];
    },
    chartLogicTestPreference() {
      this.logicTestRenderChart = true;
      const chartContent = this.$refs.logic_test_chart;

      if (this.radarChartVal && Object.keys(this.radarChartVal).length) {
        (this.radarChartVal as any).destroy();
      }
      if (chartContent) {
        const ctx = (chartContent as any).getContext("2d");
        const options = {
          elements: {
            line: {
              borderWidth: 2,
            },
          },
          scales: {
            r: {
              grid: {
                display: false,
              },
              min: 0,
              max: 100,
              ticks: {
                display: false,
                beginAtZero: false,
                stepSize: 10,
                callback: function () {
                  return "";
                },
              },
              gridLines: {
                lineWidth: 2,
                color: "lightgreen",
                borderDash: (context: any) =>
                  context.index == 6 ? [] : [6, 4],
              },
            },
          },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        };

        const daatsets = [];
        const radarData = this.radarChartLogicTestCandidateData();

        if (radarData.length > 0) {
          const selectedCandidate = radarData[0];
          const item = {
            label: selectedCandidate.name,
            data: selectedCandidate.data,
            pointStyle: "line",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: selectedCandidate.color,
            score: selectedCandidate.score,
          };
          daatsets.push(item);
        }

        this.radarChartVal = new Chart(ctx, {
          type: "radar",

          data: {
            labels: ["", "", "", ""],
            datasets: daatsets,
          },
          options: options,
        });
      }
      setTimeout(() => {
        this.logicTestRenderChart = false;
      }, 1000);
    },

    insertValues(arr: number[]): number[] {
      const newArr: number[] = [];
      const length = arr.length;

      for (let i = 0; i < length; i++) {
        newArr.push(arr[i]);

        if (i !== length - 1) {
          const average = (arr[i] + arr[i + 1]) / 2;
          newArr.push(average);
        }
      }
      newArr.push((arr[length - 1] + arr[0]) / 2);
      return newArr;
    },
    drilldownOnCulture(data: any) {
      if (data.id == "overall") {
        this.tooltipOncultureStatus = true;
      } else {
        this.tooltipOncultureStatus = false;
        this.tooltipOncultureLabelPrefix =
          (this.lang(data, "admin_description") || "") + " ";
        this.tooltipOncultureLabel.collaborate =
          data.tooltipOncultureLabel.collaborate || "";
        this.tooltipOncultureLabel.create =
          data.tooltipOncultureLabel.create || "";
        this.tooltipOncultureLabel.compete =
          data.tooltipOncultureLabel.compete || "";
        this.tooltipOncultureLabel.control =
          data.tooltipOncultureLabel.control || "";
      }
      const dataValue = _.cloneDeep(data);
      dataValue.value = Math.round(dataValue.value);
      for (const i in dataValue.company) {
        dataValue.company[i] = Math.round(dataValue.company[i]);
        dataValue.candidate[i] = Math.round(dataValue.candidate[i]);
      }
      this.culturePreferenceFocus = dataValue;
      this.renderChartculturePreference();
    },
    renderWorkStyleDoughnutChart() {
      let chartData = [0, 0, 0];
      if (this.data.summaries && this.data.summaries.work_style_identifier) {
        const summary = this.data.summaries.work_style_identifier;
        chartData = [
          Math.round(summary.overall.product * 100),
          Math.round(summary.overall.process * 100),
          Math.round(summary.overall.people * 100),
        ];
      }

      const getChart = this.$refs.work_style_chart;
      if (getChart && this.culturePreferenceFocus) {
        try {
          const data = {
            labels: ["Product", "Process", "People"],
            datasets: [
              {
                // data: [60, 30, 10],
                data: chartData,
                backgroundColor: ["#FFC200", "#FF4343", "#5EB8F6"],
                hoverOffset: 4,
                borderWidth: 6,
                borderRadius: 8,
              },
            ],
          };
          const ctx = (getChart as any).getContext("2d");
          const options = {
            cutout: "70%",
            responsive: true,
            plugins: {
              datalabels: {
                color: "#FFFFFF",
              },
              title: {
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          };

          this.doughnutChartVal = new Chart(ctx, {
            type: "doughnut",
            data: data,
            options: options,
          });
        } catch (e) {
          console.log(e, "error");
          return;
        }
      }
    },
    score(value: any) {
      return Math.round(value);
    },
    scoreCls(value: any) {
      const score = this.score(value);
      let cls = "red";
      if (score >= 80) {
        cls = "green";
      } else if (score >= 60) {
        cls = "yellow";
      }
      return cls;
    },
    sendEmailResults(data: any) {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.question_box_1.question_1") +
        data.firstname +
        (this as any).$t("popup.question.question_box_1.question_2"),
        (this as any).$t("popup.question.question_box_1.title") +
        data.firstname,
        {
          confirmButtonText: (this as any).$t("popup.optional.Yes"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("assessment/sentResultToCandidateEmail", data, {
          root: true,
        });
        ElMessage({
          type: "success",
          message: (this as any).$t("popup.message.message_1"),
        });
      });
    },
    coppyLink() {
      const _id = this.id;
      const actionUrl = this.data.action_url;
      let linkUrl = "";
      if (actionUrl) {
        const url = new URL(actionUrl);
        const asId = _id.split("-")[0];
        const uId = btoa(encodeURIComponent(_id));
        linkUrl =
          url.origin +
          "/assessment-results" +
          "?as_id=" +
          asId +
          "&u_id=" +
          uId;
      }

      const value = linkUrl;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(value);
      ElMessage({
        message: (this as any).$t("popup.message.message_19"),
        type: "success",
      });
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      // @note hold all this issue waiting all translation
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    t(text: string, ...values: any): string {
      text = helpers.t(text);
      if (arguments.length > 1) {
        for (let i = 0; i < values.length; i++) {
          const k = "$" + (i + 1);
          text = text.replace(k, values[i]);
        }
      }
      return text;
    },
    getValueAlignment() {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      if (lang == "th") {
        return _.has(
          this.data,
          "summaries.values_and_traits.values_alignment_th"
        )
          ? _.get(this.data, "summaries.values_and_traits.values_alignment_th")
          : _.get(this.data, "summaries.values_and_traits.values_alignment");
      } else {
        return _.get(this.data, "summaries.values_and_traits.values_alignment");
      }
    },
    getValueAlignmentV2(key: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let path = "summaries.values_and_traits.values_alignment_v2";
      if (lang != "en") {
        path += "_" + lang;
      }
      return (
        _.get(this.data, path + ".0." + key) ||
        _.get(this.data, path + "." + key)
      );
    },
    hasValueAlignmentV2(key: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let path = "summaries.values_and_traits.values_alignment_v2";
      if (lang != "en") {
        path += "_" + lang;
      }
      return (
        _.has(this.data, path + ".0." + key) ||
        _.has(this.data, path + "." + key)
      );
    },
    changeAcceptableScore(value: any) {
      const command = value.command || "";
      const data = value.data || {};

      const valueMap = [
        "Acceptable",
        "Frowned Upon",
        "Actively Discouraged",
        "Reprimanded",
        "Zero Tolerance Policy",
      ];
      const candidateValue = _.indexOf(valueMap, command);
      value.data.candidateValue = candidateValue;

      const changedData: any = {};
      changedData[data.scenarioName] = {};
      changedData[data.scenarioName][data.questionId] = candidateValue;

      const scenarios: any[] = [];
      for (const i in this.summaries.acceptable_behaviors.scenarios) {
        const scenario = this.summaries.acceptable_behaviors.scenarios[i];
        if (
          scenario.scenarioName == data.scenarioName &&
          scenario.questionId == data.questionId
        ) {
          scenario.userValueLabel = valueMap[candidateValue];
          scenario.userValueLabel_en = valueMap[candidateValue];
          scenario.userValueLabel_th = helpers.t(
            "question.acceptableBehaviors." +
            valueMap[candidateValue].replaceAll(" ", "_")
          );
        } else {
          changedData[scenario.scenarioName] = {};
          changedData[scenario.scenarioName][scenario.questionId] =
            scenario.candidateValue;
        }
        scenarios.push(scenario);
      }
      this.summaries.acceptable_behaviors.scenarios = scenarios;

      const formDetails = {
        _id: this.id,
        acceptable_behaviors: changedData,
      };

      this.$store.commit("assessment/clientAcScenarios", scenarios, {
        root: true,
      });
      this.$store.commit("assessment/doNotUpdateAcScenarios", true, {
        root: true,
      });
      this.$store.dispatch("assessment/updateAcceptableBehavior", formDetails, {
        root: true,
      });
    },
    overAllPercentageColorCode(value: number) {
      // #color_code
      const score = this.score(value);
      if (score >= 80) {
        return "#28BE79";
      } else if (score >= 60) {
        return "#FBC02D";
      } else {
        return "#CC308A";
      }
    },
    checkingNaN(value: number) {
      return isNaN(value) ? "-" : Math.round(value);
    },
    checkOpacityAndColor(value: number, maxBar: number) {
      let color = "#CC308A";
      if (value > 79) {
        color = "#22BC66";
      } else if (value > 59) {
        color = "#FACC24";
      }
      if (value < maxBar) {
        color += "50";
      }

      return `background-color: ${color}`;
    },
    showHumanTime(value: number, section?: string): string {
      if (!value) {
        let maxTime = section == "human_skills" ? 10 : 5;
        maxTime += Number(this.formTemplate.extra_time);
        value = maxTime * 60;
      }
      let minutes: any = Math.floor(value / 60);
      minutes = minutes >= 10 ? String(minutes) : "0" + String(minutes);
      let seconds: any = value % 60;
      seconds = seconds >= 10 ? String(seconds) : "0" + String(seconds);
      return `${minutes}:${seconds}`;
    },
    getLogicTestScoreBySectionOrder(order: number) {
      const logicTestSection = this.logictestSection;
      const path = `scenarios.section${order}.questions`;
      const questions = _.get(logicTestSection, path);
      _.sortBy(questions, "order");
      let sum = 0;
      let max = 0;
      _.each(questions, (question: any) => {
        const questionId = _.get(question, "id");
        const correctAnswer = _.get(question, "answer");
        const path = `logic_test.section_${order}.${questionId}`;
        const userAnswer = _.get(this.data, path);
        const correctPoint = parseInt(_.get(question, "point"));
        const userPoint = userAnswer == correctAnswer ? correctPoint : 0;
        sum += userPoint;
        max += correctPoint;
      });

      const text = (this as any).$t(
        "question.logicTest.sum_score_each_section_n",
        {
          sum_point: sum,
          max_point: max,
          percent: max > 0 ? (sum / max) * 100 : 0,
        }
      );
      return text;
    },
    // getCultureProfileLabel(key: string) {
    //   const lang = (this as any).$i18n.getLocale(0) || "en";
    //   let label = "";
    //   if (_.isObject(this.data)) {
    //     if (lang != "en") {
    //       label = this.data[key + "_" + lang] || "";
    //     }
    //     if (Object.prototype.hasOwnProperty.call(this.data, key)) {
    //       label = this.data[key] || "";
    //     }
    //   }
    //   return label;
    // },
    getCultureProfileLabel(type) {
      if (!this.culturePreferenceFocus) {
        return this.$t("assessmentResults.Collaborate")
      }
      // console.log(this.culturePreferenceFocus.id)

      switch (this.culturePreferenceFocus.id) {
        case 'dominant_characteristics':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.Family_Atmosphere")
            case 'compete': return this.$t("question.cultureProfile.Results_Driven") 
            case 'create': return this.$t("question.cultureProfile.Entrepreneurial_Spirit")
            case 'control': return this.$t("question.cultureProfile.Structured_Environment")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'organization_glue':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.Trust_and_Loyalty")
            case 'compete': return this.$t("question.cultureProfile.Achievement_Focus")
            case 'create': return this.$t("question.cultureProfile.Innovative_Commitment") 
            case 'control': return this.$t("question.cultureProfile.Structured_Stability")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'management_of_employees':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.Teamwork_Emphasis")
            case 'compete': return this.$t("question.cultureProfile.Competitive_Drive")
            case 'create': return this.$t("question.cultureProfile.Individual_Innovation")
            case 'control': return this.$t("question.cultureProfile.Stable_Employment")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'organizational_leadership':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.Nurturing_Leadership")
            case 'compete': return this.$t("question.cultureProfile.Aggressive_Focus")
            case 'create': return this.$t("question.cultureProfile.Innovative_Pioneers")
            case 'control': return this.$t("question.cultureProfile.Efficient_Coordination")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'criteria_of_success':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.People_Centric_Success")
            case 'compete': return this.$t("question.cultureProfile.Marketplace_Dominance")
            case 'create': return this.$t("question.cultureProfile.Innovative_Leadership")
            case 'control': return this.$t("question.cultureProfile.Operational_Efficiency")
            default: return this.$t("assessmentResults.Collaborate")
          }
        case 'strategic_emphases':
          switch(type) {
            case 'collaborate': return this.$t("question.cultureProfile.Human_Development")
            case 'compete': return this.$t("question.cultureProfile.Competitive_Achievement")
            case 'create': return this.$t("question.cultureProfile.Opportunity_Exploration")
            case 'control': return this.$t("question.cultureProfile.Operational_Stability")
            default: return this.$t("assessmentResults.Collaborate")
          }
        default:
          return this.$t("assessmentResults.Collaborate")
      }
    }
  },
});
