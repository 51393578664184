import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDoc,
  query,
  getDocs,
  where,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import helpers from "@/helpers/global";
import axios from "axios";
interface PaymentDetails {
  email: string;
  sku: string;
  amount: number;
  price: number;
  currency: string;
  payment_method: "omise";
  payment_title: "Omise";
  status: "PENDING";
  created_at: string;
}

export default {
  namespaced: true,
  state: {
    error: null,
    loading: false,
    ignoreLoading: false,
    lastInsertId: null,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(
      state: { loading: boolean; ignoreLoading: boolean },
      loading: boolean
    ): any {
      if (state.ignoreLoading) return;
      state.loading = loading;
    },
    ignoreLoading(
      state: { ignoreLoading: boolean },
      ignoreLoading: boolean
    ): any {
      state.ignoreLoading = ignoreLoading;
    },
    lastInsertId(state: { lastInsertId: string }, lastInsertId: string): any {
      state.lastInsertId = lastInsertId;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    ignoreLoading(state: { ignoreLoading: boolean }): any {
      return state.ignoreLoading;
    },
    lastInsertId(state: { lastInsertId: string }): string {
      return state.lastInsertId;
    },
  },
  actions: {
    check({ commit, rootState }: { commit: any; rootState: any }): boolean {
      const user = rootState.auth.user;
      if (!user) {
        commit(
          "auth/errorMessage",
          "You are not logged in. Please login and try again.",
          { root: true }
        );
        return false;
      }
      return true;
    },
    async load({ commit }: { commit: any }, email: string): Promise<any> {
      commit("loading", true);
      console.log(email, "email");
      commit("loading", false);
    },
    async pay({ commit }: { commit: any }, req: PaymentDetails): Promise<any> {
      commit("loading", true);

      const now = helpers.now();
      const currency = req.currency || helpers.getCurrency();
      const description = helpers.getDescriptionBySku(req.sku, currency);
      const paymentDetails = {
        ...req,
        ...{
          description: description,
          payment_method: "omise",
          payment_title: "Omise",
          status: "PENDING",
          created_at: now,
        },
      };

      const db = getFirestore();
      const docRef = await addDoc(
        collection(db, "payment_history"),
        paymentDetails
      );
      commit("lastInsertId", docRef.id);

      commit("loading", false);
    },
    async payAsUnlimited(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { sku }: { sku: string }
    ): Promise<any> {
      // console.log("payAsUnlimited()");
      if (!dispatch("check")) return;

      // const sku = "Unlimited";
      const price = helpers.getPriceBySku(sku);
      const amount = 1;
      // console.log(price, "price");

      commit("ignoreLoading", true);

      const user = rootState.auth.user;

      // store payment and waiting to approve after omise completed flow post to endpoint
      await dispatch("pay", {
        email: user.email,
        sku: sku,
        amount: amount,
        price: price,
        currency: helpers.getCurrency(),
        type: "unlimited",
      });

      // @todo add here in pay-secure.happily.ai when omise callback
      /*
      const credit = 99999999999;
      const request = {
        email: user.email,
        credit: credit,
        // pay as you go
        plan: "unlimited",
      };
      await dispatch("quota/addAdvancedQuotaTotal", request, {
        root: true,
      });
      //*/

      commit("ignoreLoading", false);
    },
    async payAsYouGo(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { sku, amount = 1 }: { sku: string; amount: number }
    ): Promise<any> {
      // console.log("payAsYouGo()");
      // @notes:
      // must be know price
      // open modal to pay
      // store in db for cross check
      // submit to form action
      // check from omise action, is it correct update

      if (!dispatch("check")) return;
      // console.log(`sku: ${sku}, amount: ${amount}`);

      const user = rootState.auth.user;
      const chunks = sku.split("_");
      const credit = parseInt(chunks[1]);
      const price = helpers.getPriceBySku(sku);

      commit("ignoreLoading", true);

      // store payment and waiting to approve after omise completed flow post to endpoint
      await dispatch("pay", {
        email: user.email,
        sku: sku,
        amount: amount,
        price: price,
        currency: helpers.getCurrency(),
        type: "payg",
        credit: credit,
      });

      // @todo add here in pay-secure.happily.ai when omise callback
      /*
      const request = {
        email: user.email,
        credit: credit,
        // pay as you go
        plan: "payg",
      };
      await dispatch("quota/addAdvancedQuotaTotal", request, {
        root: true,
      });
      //*/

      commit("ignoreLoading", false);
    },
    // monthly and yearly
    async payMonthly(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { sku, amount = 1 }: { sku: string; amount: number }
    ): Promise<any> {
      // console.log("payMonthly()");
      if (!dispatch("check")) return;

      const user = rootState.auth.user;

      // ex: MONTHLY_1_4900, YEARLY_1_44100
      const chunks = sku.split("_");
      const recurring = String(chunks[0]).toLowerCase();
      const total = chunks[1];
      const price = helpers.getPriceBySku(sku);
      console.log(`month: ${total}, price: ${price}`);

      let plan = "monthly";
      let months = 1;
      if (recurring == "yearly") {
        plan = "annual";
        months = 12;
      }

      commit("ignoreLoading", true);

      // store payment and waiting to approve after omise completed flow post to endpoint
      await dispatch("pay", {
        email: user.email,
        sku: sku,
        amount: amount,
        price: price,
        currency: helpers.getCurrency(),
      });

      // @todo add here in pay-secure.happily.ai when omise callback
      await dispatch(
        "quota/updateExpiredAt",
        {
          email: user.email,
          plan: plan,
          months: months,
        },
        { root: true }
      );

      commit("ignoreLoading", false);
    },
    async cancelSubscription({
      commit,
      dispatch,
      rootState,
    }: {
      commit: any;
      dispatch: any;
      rootState: any;
    }): Promise<any> {
      // console.log("cancelSubscription()");
      if (!rootState.quota || !rootState.quota.data) {
        commit("error", "Quota was not found.");
        return;
      }
      commit("loading", true);
      const user = rootState.user.user;
      const quotaData = rootState.quota.data;
      const plan = quotaData.plan;
      // console.log(plan, "plan");
      if (plan != "monthly" && plan != "annual") {
        commit("loading", false);
        return;
      }

      // @todo cancel subscription at Omise too
      const emailKey = helpers.emailKey(user.email);
      const $db = getFirestore();
      const docRef = doc($db, "omise_customers", emailKey);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const omiseCustomer = docSnap.data();
        // console.log(omiseCustomer, "omiseCustomer");
        const schedules = omiseCustomer.schedules || [];
        if (schedules.length) {
          const scheduleId = schedules.pop();
          const actionUrl =
            process.env.VUE_APP_OMISE_API_URL + "/omise/schedule-charge/delete";
          axios
            .delete(actionUrl, {
              data: {
                email: user.email,
                id: scheduleId,
              },
            })
            .then(async () => {
              // console.log(res, "res");

              // @todo move logic into quota after cancel subscirption Omise successfully
              let nextPlan = "free";
              if (quotaData.advanced_quota_total > 0) {
                nextPlan = "payg";
              }
              const email = user.email;
              const formDetails = {
                plan: nextPlan,
              };
              await dispatch(
                "quota/upsertQuota",
                { email, formDetails },
                { root: true }
              );
              await dispatch("quota/load", user, { root: true });

              commit("loading", false);
            })
            .catch((error) => {
              console.log(error, "error");
            });
        }
      } else {
        commit("error", "Omise customer was not found.");
        commit("loading", false);
        return;
      }
    },
    async resetPayment(
      { commit }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      commit("loading", true);
      const filter = where("email", "==", email);

      const fs = getFirestore();
      const q = query(
        collection(fs, "payment_history"),
        filter,
        orderBy("created_at", "desc")
      );
      const querySnapshot = await getDocs(q);
      for (const i in querySnapshot.docs) {
        const _id = querySnapshot.docs[i].id;
        await deleteDoc(doc(fs, "payment_history", _id));
      }
      commit("loading", false);
    },
  },
};
