// @see https://gist.github.com/vielhuber/afcb99fdf23febffe0199649e19642cb
// @see https://gist.github.com/vielhuber

import format from "date-fns/format";
import { formatInTimeZone } from "date-fns-tz";
import _ from "lodash";
import i18n from "../plugins/vue-i18n";

const I18N_TRANSLATE_ENABLED = true;

// typescript dynamic key value object
// @see https://www.codegrepper.com/code-examples/typescript/typescript+dynamic+object+keys
interface LooseObject {
  [key: string]: any;
}

export const DEFAULT_TIMEZONE = "Asia/Bangkok";
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";

const helpers = {
  now(): string {
    return formatInTimeZone(new Date(), DEFAULT_TIMEZONE, DEFAULT_DATE_FORMAT);
  },
  guid(): string {
    return format(new Date(), "yyyyMMddHHmmss");
  },
  isSafari(): boolean {
    // @see https://burningthumb.com/safari-javascript-date-format/
    // @see https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
    const userAgentString = navigator.userAgent || "";
    const chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1;
    if (chromeAgent && safariAgent) safariAgent = false;
    return safariAgent;
  },
  isTh(): boolean {
    return i18n.getLocale() == "th";
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  t(text: string, variables?: any): string {
    if (!I18N_TRANSLATE_ENABLED) return text;
    return i18n.t(text, variables);
  },
  objectLang(obj: { label?: string }, attr: string): string {
    const lang = i18n.getLocale() || "en";
    let label = "";
    if (_.isObject(obj)) {
      if (lang != "en") {
        attr = attr + "_" + lang;
      }
      if (Object.prototype.hasOwnProperty.call(obj, attr)) {
        label = (obj as any)[attr];
      }
    }
    return label;
  },
  dateFormat(datetime: string, dateFormat = "MMMM d, y"): string {
    if (!datetime) return "";
    if (this.isSafari()) {
      datetime = datetime.replace(/-/g, "/");
    }
    const result = format(new Date(datetime), dateFormat);
    // const monthNames = [
    //   "January",
    //   "February",
    //   "March",
    //   "April",
    //   "May",
    //   "June",
    //   "July",
    //   "August",
    //   "September",
    //   "October",
    //   "November",
    //   "December",
    // ];
    // for (const i in monthNames) {
    //   const monthNameTh = this.t("datetime.months." + monthNames[i]);
    //   if (monthNameTh) {
    //     result = result.replaceAll(monthNames[i], monthNameTh);
    //   }
    // }
    return result;
  },
  getCurrency(): string {
    const currency = "USD";
    return currency;
  },
  getOmisePriceFormat(realPrice: string): number {
    realPrice =
      realPrice.substring(0, realPrice.length - 2) +
      "." +
      realPrice.substring(realPrice.length - 2, realPrice.length);
    return parseFloat(realPrice);
  },
  getPriceBySku(sku: string, format = "default"): any {
    let price = 0;
    if (sku == "Unlimited_USD") {
      price = 499;
    } else if (sku == "Unlimited_THB") {
      price = 17000;
    } else {
      const chunks = sku.split("_");
      // const credit = chunks[1];
      price = this.getOmisePriceFormat(chunks[2]);
    }

    // Omise Price Format
    if (format.toLowerCase() == "omise") {
      price *= 100;
    }

    return price;
  },
  getDescriptionBySku(sku: string, currency: string): string {
    const price = this.getPriceBySku(sku, "omise");
    if (sku.indexOf("Unlimited") === 0) {
      return `${sku}x1=${price}${currency}`.toUpperCase();
    }
    // const chunks = sku.split("_");
    // const credit = parseInt(chunks[1]);
    // const currency = this.getCurrency();
    // const description = `${sku}x1=${price}${currency}`;
    const description = sku;
    return description;
  },
  workflowStatus(status: string): string {
    let label = status;
    if (status == "INVITED") {
      label = "Invited";
    } else if (status == "TODO") {
      label = "Started";
    } else if (status == "IN_PROGRESS") {
      label = "Incompleted";
    } else if (status == "COMPLETED") {
      label = "Completed";
    }
    return label;
  },
  // The Fisher-Yates algorith
  // @see https://dev.to/codebubb/how-to-shuffle-an-array-in-javascript-2ikj
  shuffleArray(array: any[]): any {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  },
  randomNumber(digits = 6): number {
    // @see https://www.codegrepper.com/code-examples/javascript/generate+6+digit+random+number+in+node+js
    const base = Math.pow(10, digits - 1);
    return Math.floor(base + Math.random() * (9 * base));
  },
  generateString(length = 5): string {
    // @see https://www.programiz.com/javascript/examples/generate-random-strings
    // declare all characters
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  filterEmail(value: string): string {
    value = String(value).toLowerCase();
    return value;
  },
  filterPath(path: string): string {
    return path.replace(/[.#]/g, "||");
  },
  emailKey(email: string): string {
    return this.filterPath(this.filterEmail(email));
  },
  validateEmail(value: string): boolean {
    //eslint-disable-next-line
    const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
    return valid;
  },
  getUserDisplayName(user: {
    displayName?: string;
    firstname?: string;
    lastname?: string;
  }): string {
    let displayName = "Anonymous";
    if (user) {
      if (user.displayName) {
        displayName = user.displayName;
        const pos = displayName.indexOf(" ");
        displayName =
          displayName.substring(0, pos) +
          " " +
          displayName.substring(pos + 1, pos + 2);
      } else {
        if (user.firstname && user.lastname) {
          displayName = user.firstname + " " + user.lastname[0];
        }
      }
    }
    return displayName;
  },
  noop(): any {
    // console.log("noop()");
    // console.log("no-empty-function");
  },
  transformValuesDto(
    values: { idx?: string },
    transformKey = "snakeCase"
  ): any {
    const newValues: LooseObject = {};
    type newKeys = keyof typeof newValues;
    if (_.isObject(values)) {
      type keys = keyof typeof values;
      for (const _key in values) {
        const key = _key as keys;
        let newKey: any = _key as newKeys;
        let value = values[key];
        let doTransform = false;
        if (_.isObject(value)) {
          value = this.transformValuesDto(value, transformKey);
          doTransform = true;
        } else {
          if (
            newKey.indexOf("valuesAndTraitsIsCustom") !== -1 ||
            newKey.indexOf("valuesAndTraitsVersionId") !== -1 ||
            newKey.indexOf("acceptableBehaviorsIsCustom") !== -1 ||
            newKey.indexOf("customSurveyIsCustom") !== -1 ||
            newKey.indexOf("customSurveyCustomFields") !== -1 ||
            newKey.indexOf("customSurveyTitle") !== -1 ||
            newKey.indexOf("customSurveyDescription") !== -1
          ) {
            doTransform = true;
          }
        }
        if (doTransform) {
          if (transformKey == "snakeCase" || transformKey == "camelCase") {
            newKey = _[transformKey](String(newKey));
          }
        }
        newValues[newKey] = value;
      }
    }
    return newValues;
  },
  getLocaleDate(datetime?: string): string {
    if (!datetime) return "";

    const dateFormat = "MMMM d, y";
    const result = this.dateFormat(datetime, dateFormat);

    return result;
  },
  // @see https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site
  _timeAgo(time: string | number | any): string {
    switch (typeof time) {
      case "number":
        break;
      case "string":
        time = +new Date(time);
        break;
      case "object":
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    const time_formats = [
      [60, "seconds", 1], // 60
      [120, "1 minute ago", "1 minute from now"], // 60*2
      [3600, "minutes", 60], // 60*60, 60
      [7200, "1 hour ago", "1 hour from now"], // 60*60*2
      [86400, "hours", 3600], // 60*60*24, 60*60
      [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
      [604800, "days", 86400], // 60*60*24*7, 60*60*24
      [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
      [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
      [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
      [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
      [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000,
      token = "ago",
      list_choice = 1;
    if (seconds == 0) {
      return "Just now";
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = "from now";
      list_choice = 2;
    }
    let i = 0,
      format: (string | number | any)[];
    while ((format = time_formats[i++]))
      if (seconds < format[0]) {
        if (typeof format[2] == "string") return format[list_choice];
        else
          return (
            Math.floor(seconds / format[2]) + " " + format[1] + " " + token
          );
      }

    return time;
  },
  timeAgo(time: string | number | any): string {
    const result = this._timeAgo(time);
    // console.log(result, "result");

    // const words = [
    //   "seconds",
    //   "1 minute ago",
    //   "1 minute from now",
    //   "minutes",
    //   "1 hour ago",
    //   "1 hour from now",
    //   "hours",
    //   "Yesterday",
    //   "Tomorrow",
    //   "days",
    //   "Last week",
    //   "Next week",
    //   "weeks",
    //   "Last month",
    //   "Next month",
    //   "months",
    //   "Last year",
    //   "Next year",
    //   "years",
    //   "Last century",
    //   "Next century",
    //   "centuries",
    //   "Just now",
    //   "from now",
    // ];

    // const newWords: any = {};
    // for (const i in words) {
    //   const key = words[i].replaceAll(" ", "_");
    //   newWords[key] = words[i];
    // }

    // {"seconds":"seconds","1_minute_ago":"1 minute ago","1_minute_from_now":"1 minute from now","minutes":"minutes","1_hour_ago":"1 hour ago","1_hour_from_now":"1 hour from now","hours":"hours","Yesterday":"Yesterday","Tomorrow":"Tomorrow","days":"days","Last_week":"Last week","Next_week":"Next week","weeks":"weeks","Last_month":"Last month","Next_month":"Next month","months":"months","Last_year":"Last year","Next_year":"Next year","years":"years","Last_century":"Last century","Next_century":"Next century","centuries":"centuries","Just_now":"Just now","from_now":"from now"}

    return result;
  },
  // @see https://stackoverflow.com/questions/7860392/determine-if-string-is-in-base64-using-javascript
  isBase64(str: string): boolean {
    if (str === "" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  },
  // @see https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
  //wc_hex_is_light(color: string): boolean {
  isLightColor(color: string): boolean {
    const hex = color.replace("#", "");
    const c_r = parseInt(hex.substring(0, 0 + 2), 16);
    const c_g = parseInt(hex.substring(2, 2 + 2), 16);
    const c_b = parseInt(hex.substring(4, 4 + 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
  },
  getReferralCode(url = ""): string {
    if (!url.length) {
      url = window.location.search;
    }
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const keys = Object.keys(params);
    if (keys.length) {
      return keys[0];
    }
    return "";
  },
  ucfirst(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  },
  getMyCoreValuesRoute(user: { coreValues?: any }): any {
    let tags = "";
    if (user && Object.prototype.hasOwnProperty.call(user, "coreValues")) {
      const coreValues = user.coreValues;
      if (Array.isArray(coreValues)) {
        tags = coreValues.join(",");
      }
    }
    const to: any = { name: "CoreValues" };
    if (tags.length) {
      to.query = { tags: tags };
    }
    return to;
  },
  nl2br(value: string): string {
    return value.replace(/([^>])\n/g, "$1<br />\n");
  },
};
export default helpers;
