const th = {
  lang_code: "th",
  f_thai: "ไทย",
  thai: "ไทย",
  f_english: "english",
  English: "English",
  test: "ทดสอบ",
  Name: "ชื่อ",
  Purpose: "จุดประสงค์",
  Purpose_with_role_varaiables:
    "{Purpose} สำหรับ {RoleFocus} ใน {OrganizationType}",
  Purpose_with_none_role_varaiables: "{Purpose} ใน {OrganizationType}",
  Language: "ภาษา",
  Add: "เพิ่ม",
  Detail: "รายละเอียด",
  Overall: "ภาพรวม",
  Rate: "คะแนน",
  Yes: "ใช่",
  No: "ไม่ใช่",
  Generate: "สร้าง",
  Clear: "ล้างข้อมูล",
  Copy_Text: "คัดลอกข้อความ",
  Error: "ข้อผิดพลาด",
  min_remaining: "เวลาที่เหลือ",
  Step_n: "ขั้นที่ {step}",
  Assessment: "แบบประเมิน",
  main: {
    dot: ".",
    Values_and_Traits: "ชุดค่านิยมและลักษณะนิสัย",
    Values_Alignment: "ความสอดคล้องกันของชุดค่านิยม",
    Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
    Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
    Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
    Human_Skills: "ทักษะด้านมนุษยสัมพันธ์",
    Logic_Test: "แบบทดสอบตรรกะ",
    Custom_Survey: "แบบสำรวจที่กำหนดเอง",
  },
  placeholder: {
    full_name: "ชื่อ-นามสกุล",
    email: "อีเมล",
    email_email_com: "email@email.com",
    Work_email: "อีเมลที่ทำงาน",
    First_name: "ชื่อ",
    Last_name: "นามสกุล",
    Company_name: "ชื่อบริษัท",
    What_is_your: "ตำแหน่งงานของคุณ",
    The_number_of_hires: "จำนวนพนักงานที่รับสมัครต่อปี (โดยประมาณ)",
    What_level_are_you: "คุณพัฒนาวัฒนธรรมองค์กรได้ในระดับใด?",
    Coupon_code: "รหัสคูปอง",
    Code: "รหัส",
    Key: "Key",
    Enter_coupon_code: "ระบุโค้ดคูปอง",
    Email_Address_start: "อีเมล*",
    Search_by_name_or: "ค้นหาจากชื่อหรืออีเมล",
    Assessment: "แบบทดสอบ",
    Your_rating: "ระดับคะแนน",
    Select: "เลือก",
    Search: "ค้นหา",
    Name_your_assessment: "ตั้งชื่อแบบทดสอบของคุณ",
    What_is_this_assessment_for: "แบบทดสอบนี้ต้องการวัดอะไร?",
    Number_of_employees: "จำนวนพนักงาน",
    Enter_your_email: "ระบุอีเมลของคุณ",
    Enter_your_name: "ระบุชื่อของคุณ",
    Search_by_name: "ค้นหาด้วยชื่อ",
    Search_by_name_or_email: "ค้นหาด้วยชื่อหรืออีเมล",
    Choose_Assessment: "เลือกแบบทดสอบ",
    Choose_Status: "เลือกสถานะ",
  },
  btn: {
    Continue: "ดำเนินการต่อ",
    Email: "อีเมล",
    ADD: "เพิ่ม",
    Next: "ต่อไป",
    Skip: "ข้าม",
    Save: "บันทึก",
    Edit: "แก้ไข",
    Clone: "ทำซ้ำ",
    Duplicate: "ทำสำเนา",
    Delete: "ลบ",
    Invite: "เชิญ",
    Quick_Invite: "เชิญด่วน",
    Get_Started: "เริ่ม",
    Close: "ปิด",
    Previous: "ย้อนกลับ",
    Finish: "เสร็จ",
    Submit: "ส่ง",
    Remove: "ลบ",
    Leave: "ออก",
    Do_it_later: "ไว้ทีหลัง",
    Copy: "คัดลอก",
    Send: "ส่ง",
    Re_send_Invit: "ส่งคำเชิญอีกครั้ง",
    Clear_Results: "ล้างผลลัพธ์",
    Cancel: "ยกเลิก",
    Copy_link: "คัดลอกลิงก์",
    Copy_key: "Copy key",
    Regenerate: "Regenerate",
    Close_Window: "ปิดหน้าต่าง",
  },
  optional: {
    Hiring_candidates: "ผู้สมัครงาน",
    New_Hires: "พนักงานใหม่",
    Current_team_members: "สมาชิกทีมปัจจุบัน",
    Invited: "ส่งคำเชิญแล้ว",
    Started: "เริ่มทำแล้ว",
    Incompleted: "ยังไม่เสร็จ",
    Completed: "เสร็จแล้ว",
    personal: "ส่วนตัว",
    shared: "แบ่งปัน",
  },
  login: {
    Please_confirm_who_you: "บอกเราอีกทีว่าคุณเป็นใคร",
    I_have_read_and: "ฉันได้อ่านและยอมรับ",
    terms_of_use: "เงื่อนไขการใช้บริการ",
    Send_me_free_stuff:
      "ส่งของกำนัลและอีเมลเกี่ยวกับ MyCulture เมื่อมีโปรโมชั่นหรือข้อมูลใหม่ๆ ที่น่าสนใจ",
  },
  timeout: {
    No_time_remaining: "หมดเวลา",
    The_allocated_time_for_this_section:
      "หมดเวลาในส่วนนี้แล้ว ไปยังขั้นตอนถัดไปกันเลย",
    Session_Time_Out: "เซสชันหมดเวลา",
    Please_proceed_to_the_next_section:
      "โปรดดำเนินการไปที่ส่วนถัดไปของแบบทดสอบ เพื่อทำการส่งแบบทดสอบ มิเช่นนั้นคำตอบของคุณอาจสูญหายได้ และอาจต้องทำแบบทดสอบอีกครั้ง",
  },
  pincode: {
    Enter_your_PIN_code: "ใส่รหัส PIN-code ที่ส่งไปยัง",
    Sign_in_using_a: "ลงชื่อเข้าใช้ด้วยบัญชีอื่น",
  },
  createAccount: {
    Get_started_with_your: "เริ่มต้นสร้างแบบทดสอบฟรี",
    Create_my_free_account: "สร้างบัญชีฟรี",
    Already_have_a: "มีบัญชีแล้วใช่ไหม?",
    Log_in_to: "เข้าสู่ระบบ My Culture",
    // Log_in_to: "เข้าสู่บัญชีของคุณ",
    Log_in: "เข้าสู่ระบบ",
    Sign_up: "สร้างบัญชีใหม่",
    Do_not_have: "ยังไม่มีบัญชีใช่ไหม?",
    OR: "หรือ",
    Continue_with_Google: "ดำเนินการต่อด้วยบัญชี Google",
    Continue_with_Microsoft: "ดำเนินการต่อด้วยบัญชี Microsoft",
    By_signing_up: "ก่อนจะสมัคร ฉันได้รับทราบ",
    Terms_and_Conditions: "ข้อกำหนดและเงื่อนไข",
    Tell_us_a_bit: "บอกเราซักนิด เกี่ยวกับคุณและบริษัทของคุณ",
    Give_your_assessments: "ใส่สไตล์บริษัทของคุณให้กับแบบทดสอบ",
    // -
    Upload_your_Logo: "อัปโหลดโลโก้ของคุณ",
    Finish_sign_up: "ลงทะเบียนให้สำเร็จ",
    more_than: "มากกว่า",
    // new ui
    Let_s_get_started_with:
      "เริ่มต้นทำแบบทดสอบของคุณกันเถอะ ระบุข้อมูลที่จำเป็นในช่องที่เรากำหนดให้",
    Sign_in_with: "ล็อกอินด้วย",
    Sign_up_to_My_culture: "สร้างบัญชี My Culture",
    Create_My_Free_Account: "สร้างบัญชีฟรี",
    Enter_pin_code_send: "ระบุรหัสที่ได้รับทางอีเมล",
    Language: "ภาษา",
    Choose_language: "เลือกภาษา",
    Name: "ชื่อ",
    Enter_your_first_name: "ระบุชื่อของคุณ",
    Last_Name: "นามสกุล",
    Enter_your_last_name: "ระบุนามสกุลของคุณ",
    Job_title: "ตำแหน่งงาน",
    Enter_your_job_title: "ระบุตำแหน่งงานของคุณ",
    Enter_your_code_color: "ระบุรหัสสีของคุณ",
    Company_name: "ชื่อบริษัท",
    Enter_company_name: "ระบุชื่อบริษัทของคุณ",
    Number_of_employees: "จำนวนพนักงาน",
    Choose_quantity: "เลือกจำนวน",
    Drag_drop_or: "ลากแล้ววางที่นี่ หรือ",
    choose_file: "เลือกไฟล์",
    to_upload: "เพื่ออัปโหลด",
    to_uplChoose_brand_coloroad: "เลือกสีของแบรนด์",
    Company_Values_n_Branding: "ค่านิยมของบริษัทและการสร้างแบรนด์",
    Please_help_us_with_the_final_details:
      "อีกนิดเดียวเท่านั้น ช่วยบอกรายละเอียดให้เราฟังอีกหน่อย เพื่อให้ได้แบบทดสอบที่ตอบโจทย์สำหรับคุณโดยเฉพาะ",
    Enter_your_core_values_n_max: "ระบุค่านิยมของคุณ (สูงสุด {max})",
    Resend_pin_code_available_in_second:
      "ส่งรหัส PIN อีกครั้ง (ใช้ได้ใน {seconds} วินาที)",
    Resend_pin_code_available_in_seconds:
      "ส่งรหัส PIN อีกครั้ง (ใช้ได้ใน {seconds} วินาที)",
    Resend_code: "ส่งรหัสอีกครั้ง",
  },
  myAccount: {
    Log_out: "ออกจากระบบ",
    My_Account: "บัญชีของฉัน",
    Help_n_Support: "ช่วยเหลือและสนับสนุน",
    Need_Help: "ต้องการความช่วยเหลือ?",
    Please_leave_us_a_message:
      "กรุณาฝากข้อความถึงเราแล้วเราจะติดต่อกลับโดยเร็วที่สุด",
    Message: "ข้อความ",
    Write_your_message_here: "เขียนข้อความของคุณที่นี่",
    Upload_an_attachment: "อัปโหลดไฟล์แนบ",
    Thank_you_for_submitting_your_messasge:
      "ขอบคุณสำหรับการส่งข้อความของคุณ ทีมงานของเราจะติดต่อกลับภายใน 3-48 ชั่วโมงข้างหน้า",
    My_Profile: "โปรไฟล์ของฉัน",
    My_Company: "บริษัทของฉัน",
    Plan_and_Billing: "แผนและราคา",
    Team: "ทีม",
    Teams: "ทีม",
    My_Team: "ทีมของฉัน",
    Team_Info: "ข้อมูลทีม",
    Team_Member: "สมาชิก",
    Team_Members: "สมาชิก ({total})",
    Personal: "ส่วนตัว",
    Create_a_new_team: "สร้างทีมใหม่",
    Create_a_team: "สร้างทีม",
    Edit_a_team: "แก้ไขชื่อทีม",
    Please_enter_a_name_that_is_unique_and_recognizable:
      "โปรดป้อนชื่อที่ไม่ซ้ำใครและเป็นที่รู้จัก",
    To_invite_members_to_a_team:
      "หากต้องการเชิญสมาชิกเข้าร่วมทีม คุณต้องสร้างทีม",
    Team_name: "ชื่อทีม",
    Copy_from_Personal_Account: "คัดลอกจากบัญชีส่วนตัว",
    company_and_branding: "ข้อมูลบริษัทและการสร้างแบรนด์",
    all_assessments_and_responses: "การประเมินและการตอบกลับทั้งหมด",
    Refer_a_friend: "แนะนำเพื่อน",
    API_Key: "API Key",
    API_Key_intro:
      "This is your unique API key. It's like a personal handshake between your apps and our software. Keep it safe!",
    API_Key_generating_warning:
      "Regenerating your API Key is irreversible and any integration using the key will no longer work. Are you sure you want to proceed?",
    Invite_Members: "เชิญสมาชิก",
    role_admin: "ผู้ดูแลระบบ",
    role_member: "สมาชิก",
    Invite_a_Member: "เชิญสมาชิก",
    Add_a_member_to_your_team_by_email_address:
      "เพิ่มสมาชิกในทีมของคุณด้วยที่อยู่อีเมล",
    Member_Email: "อีเมลสมาชิก",
    You_do_not_have_permission_to_access_the_requested_page:
      "คุณไม่ได้รับอนุญาตให้เข้าถึงหน้าที่ร้องขอ",
    Pending: "Pending",
    First_Name: "ชื่อ",
    Last_Name: "นามสกุล",
    Language: "ภาษา",
    Company_Name: "ชื่อบริษัท",
    Logo: "โลโก้",
    Upload_your_Logo: "อัปโหลดโลโก้ของคุณ",
    Brand_Color: "สีของแบรนด์",
    Brand_Assets: "สินทรัพย์แบรนด์",
    Unlimited: "ไม่จำกัด",
    __remaining: "เหลือ",
    credit: "เครดิต",
    credits: "เครดิต",
    remaining: "คงเหลือ",
    this_month: "สำหรับเดือนนี้",
    Add_Credit: "เพิ่มเครดิต",
    Company_Info: "รายละเอียดบริษัท",
    Enter_your_company_name: "ระบุชื่อบริษัทของคุณ",
    Switch_to_Team: "เปลี่ยนเป็นทีม",
    Switch_to_Personal: "เปลี่ยนเป็นส่วนตัว",
    Switch_to_Team_Variable: "เปลี่ยนเป็นทีม {teamName}",
    You_have_been_removed_from_the_team: "คุณถูกถอดออกจากทีมแล้ว",
    refer_a_friend: {
      Special_offer: "ดีลพิเศษ",
      Refer_a_friend_and: "แนะนำเพื่อนเพื่อรับเครดิตฟรี",
      When_a_friend_signs:
        "หากเพื่อนสร้างบัญชีกับ MyCulture และสร้างแบบทดสอบ คุณจะได้รับ 10 เครดิตฟรี",
      Your_unique_referral_link: "ลิงก์เฉพาะคุณ สำหรับแนะนำเพื่อน",
      Email_your_invite: "อีเมลเพื่อเชิญเพื่อน",
      we_do_not_recognise: "ขอโทษนะ เราไม่พบอีเมลนี้",
      Successful_Referrals: "การแนะนำที่สำเร็จ",
      refer_a_friend_success: "เชิญอีเมล {email} แล้ว",
    },
    planAndBill: {
      Your_current_plan: "แพ็กเกจปัจุบันของคุณ",
      Free_Plan: "แพ็กเกจฟรี",
      Unlimited_credits: "เครดิตแบบไม่จำกัด",
      Unlimited_credits_used: "เครดิตแบบไม่จำกัด",
      You_are_used: "คุณใช้ไปแล้ว",
      out_of: "จาก",
      credits: "เครดิต",
      this_mounth: "ในเดือนนี้",
      credits_remaining: "เครดิตคงเหลือ",
      A_credit_gets_used: "เครดิตจะถูกใช้เมื่อผู้ทำแบบทดสอบได้ทำแบบทดสอบสำเร็จ",
      // ---
      You_must_have_available: "คุณต้องมีเครดิตเพียงพอเพื่อที่จะส่งคำเชิญ",
      // ---
      All_plans_have_access_to_the_same_benefit:
        "ทุกแพ็คเกจสามารถเข้าถึงสิทธิประโยชน์เหล่านี้ได้เหมือนกัน",
      Unlimited_assessment_variations: "แบบการทดสอบไม่จำกัด",
      Access_to_all_available: "การเข้าถึงทุกหมวดหมู่ (อัปเดตอยู่เสมอ)",
      // ---
      Group_analytics: "การวิเคราะห์ผลแบบกลุ่ม",
      Bulk_invites: "การส่งคำเชิญแบบกลุ่ม",
      Custom_branding: "การกำหนดอัตลักษณ์องค์กรเอง",
      Email_Support: "การสนับสนุนดูแลทางอีเมล",
      Continue: "ต่อไป",
      Downgrade_to: "ลดเกรดสู่",
      Upgrade_to: "อัปเกรดสู่",
      Pay_as_you_go: "แพ็กเกจ Pay as you go",
      Unlimited: "Unlimited",
      For_businesses_that_occasionally:
        "สำหรับธุรกิจที่ต้องการใช้บางโอกาส จ่ายเมื่อใช้งาน สำหรับธุรกิจที่ต้องการใช้บางโอกาส และต้องการความหยืดหยุ่น",
      A_one_time_purchase_to_get_unlimited_credits:
        "A one-time purchase to get unlimited credits towards assessments and the manager toolbox.",
      Pay_one_per_credit: "จ่าย THB 35 ต่อเครดิต",
      Buy_credit: "ซื้อเครดิต",
      Buy_credit_n: "ซื้อเครดิต {price}",
      Buy_for_n: "ซื้อเครดิต {price}",
      Cancel_Subscription: "ยกเลิกการเป็นสมาชิก",
      Minimum_purchase_10_credits: "ซื้อเครดิตขั้นต่ำ 10 เครดิต",
      Limited_time_offer: "Limited time offer",
      Manage_your_plan: "จัดการแพ็คเกจของคุณ",
      Annual_Subscriptionogo: "สมาชิกรายปี",
      Monthly_Subscription: "แพ็กเกจ Monthly Subscription",
      For_businesses_wiht_high:
        "สมัครสมาชิกแบบรายเดือน สำหรับธุรกิจที่มีการจ้างงานสูง",
      _49_per_month: "จ่าย $49 ต่อเดือน",
      for_unlimited_credits: "เพื่อรับเครดิตแบบไม่จำกัด",
      Subscribe: "สมัครสมาชิก",
      Change_to_Annual: "เปลี่ยนเป็นรายปี",
      Change_to_Monthly: "เปลี่ยนเป็นรายเดือน",
      Select_the_annual_plan: "เลือกแพ็คเกจรายปีเพื่อรับส่วนลด 25%",
      PAY_AS_YOU_GO: "จ่าย เมื่อ ใช้งาน",
      Available_credits_allow_you:
        "คุณสามารถใช้เครดิต เพื่อส่งคำเชิญให้ทำแบบทดสอบ และเปิดใช้งานลิงก์คำเชิญของคุณ เครดิตจะถูกหักเมื่อมีคนทำแบบทดสอบสำเร็จ",
      Buy_10_for_S10: "ซื้อ 10 จ่าย $10",
      Buy_20_for_S18: "ซื้อ 20 จ่าย $18 (ส่วนลด 10%)",
      Buy_30_for_S24: "ซื้อ 30 จ่าย $24 (ส่วนลด 20%)",
      Buy_40_for_S28: "ซื้อ 40 จ่าย $28 (ส่วนลด 30%)",
      Buy_n_credits: "ซื้อ {credit} เครดิต",
      Buy_n_credits_discount: "ซื้อ {credit} เครดิต (ส่วนลด {discount}%)",
      Price_per_credits: "{currency} {price} ต่อเครดิต",
      Price_with_currency: "{currency} {price}",
      Redeem: "แลกสิทธิ์",
      SUBSCRIPTION: "การสมัครสมาชิก",
      Access_unlimited_credits_during:
        "คุณจะได้รับเครดิตแบบไม่จำกัดในระหว่างการเป็นสมาชิก และสามารถยกเลิกได้ทุกเมื่อ เพื่อทำให้แพ็คเกจของคุณไม่ถูกต่อ",
      Monthly_for_S49: "แบบรายเดือน เพียง $49",
      Billed_today_and_on: "ชำระค่าบริการวันนี้ และทุกวันที่ 6 ของเดือน",
      Annual_for_S441: "แบบรายปี เพียง $441 (ส่วนลด 25%)",
      Billed_today_and_next: "ชำระค่าบริการวันนี้ และทุกวันที่ 6 ของเดือน",
      Coupon: "คูปอง",
      Redeem_Free_Credits: "แลกเครดิตฟรี",
      Enter_your_coupon:
        "ใส่โค้ดคูปองที่นี่ หากต้องการฟรีเครดิต ติดต่อเรามาได้เลยที่ ",
      sales_happily_ai: "sales@happily.ai",
      Coupon_Code: "โค้ดคูปอง",
      We_re_sorry: "ขอโทษด้วย โค้ดนี้ไม่สามารถใช้งานได้",
      credits_redeemed_1: "ได้รับ",
      credits_redeemed_2: "เครดิต",
      You_have_successfully: "คุณใช้คูปองโค้ดแลกเครดิตสำเร็จแล้ว",
      // --
      Failed_redeem: "แลกรางวัลไม่สำเร็จ",
      credits_used: "credits used",
    },
    paymentInteruptPopup: {
      title: "การชำระเงินเร็ว ๆ นี้",
      body: "ขอขอบคุณที่สนใจซื้อเครดิต กำลังจะมาในเร็วๆ นี้ ในระหว่างนี้แนะนำเพื่อนเพื่อรับ +10 เครดิตฟรี",
      confirmButtonText: "แนะนำให้เพื่อนใช้",
      cancelButtonText: "ยกเลิก",
    },
  },
  fourZeroFour: {
    Looks_like_something: "ดูเหมือนมีบางอย่างผิดพลาด",
    the_page_or: "ไม่พบหน้าเว็บ หรือลิงก์ที่คุณกำลังค้นหา",
    Our_Website: "เว็บไซต์ของเรา",
  },
  assessments: {
    My_Core_Values: "ค่านิยมของฉัน",
    My_assessments: "แบบทดสอบของฉัน",
    My_respondents: "ผลการทดสอบของฉัน",
    Create_new_assessment: "สร้างแบบทดสอบใหม่",
    Create_assessment: "สร้างแบบทดสอบ",
    New_Assessment: "แบบทดสอบใหม่",
    Get_started: "เริ่มต้น",
    by_creating_a_new: "โดยการสร้างแบบทดสอบใหม่",
    We_re_generating_your_first_assessment:
      "อดใจรอสักหน่อย เรากำลังสร้างแบบทดสอบแรกให้คุณอยู่",
    Our_AI_is_working_with_your_organization_type:
      "ระบบ AI ของเรากำลังวิเคราะห์ประเภทองค์กรและคุณค่าหลักในองค์กร เพื่อสร้างแบบทดสอบที่ตรงโจทย์ ตรงใจของคุณที่สุด",
  },
  assessmentsDetails: {
    sections: "ส่วน",
    Invite_Candidates: "เชิญผู้ทำแบบทดสอบ",
    By_public_link: "ผ่านลิงก์สาธารณะ",
    Public_link: "ลิงก์สาธารณะ",
    By_Email: "ผ่านทางอีเมล",
    Invite_in_Bulk: "เชิญแบบกลุ่ม",
    INVITE_MULTIPLE_CANDIDATES: "เชิญแบบกลุ่ม",
    by_Email: "ผ่านทางอีเมล",
    emails_detected: " อีเมลที่ถูกเลือก",
    Comma_separate_multiple: "ใช้เครื่องหมาย , เพื่อแยกแต่ละอีเมล",
    Advanced_Invite_Sender: "ส่งคำเชิญแบบขั้นสูง",
    ADVANCED_INVITE_SENDER: "ส่งคำเชิญแบบขั้นสูง",
    Email_Subject: "หัวข้ออีเมล",
    Email_Body: "เนื้อหาอีเมล",
    Email_Sender: "ผู้ส่ง",
    Recipient_s: "ผู้รับ",
    Tip_Paste_contact: "เคล็ดลับ: วางข้อมูลติดต่อจากสเปรดชีต",
    First_Name: "ชื่อ",
    Last_Name: "นามสกุล",
    Email: "อีเมล",
    Send_Test_Email: "ส่งอีเมลแบบทดสอบ",
    Send_Invite: "ส่งคำเชิญ",
    The_email_will_include: "อีเมลนี้จะรวมส่วนหัวและส่วนท้ายอีเมล",
    Scenario: "Scenario",
    Scenario_Alignment: "Scenario Alignment",
    Created_by: "สร้างโดย",
  },
  create: {
    Create_new_assessment: "สร้างแบบทดสอบใหม่",
    Edit_assessment: "แก้ไขแบบทดสอบ",
    Untitled_assessment: "แบบทดสอบไม่มีชื่อ",
    Next_Step: "ขั้นตอนถัดไป",
    Next: "ถัดไป",
    Previous: "ก่อนหน้า",
    Your_Sections: "ส่วนของคุณ",
    Add_a_Section: "เพิ่มส่วน",
    SET_UP_REQUIRED: "จำเป็นต้องตั้งค่า",
    Culture_Specific: "วัฒนธรรมเฉพาะขององค์กร",
    General: "ทั่วไป",
    STEP_1: "ขั้นตอนที่ 1",
    STEP_2: "ขั้นตอนที่ 2",
    STEP_3: "ขั้นตอนที่ 3",
    Default_Thank_You_Page: "หน้าเว็บขอบคุณผู้ใช้งานแบบพื้นฐาน",
    Custom_Page: "กำหนดรูปแบบหน้าเว็บเอง",
    Name_Assessment: "ระบุชื่อแบบทดสอบ",
    Add_Section: "เพิ่มแบบทดสอบ",
    Remove: "นำออก",
    Fill_necessary_info: "ระบุข้อมูลที่จำเป็นสำหรับแบบทดสอบของคุณ",
    Lets_set_up_your_assessment:
      "ตั้งค่าการประเมินของคุณด้วยข้อมูลพื้นฐานบางอย่าง",
    Assessment_Name: "ชื่อแบบทดสอบ",
    Assessment_Name_Placeholder: "เช่น ทีมขาย ไตรมาสที่ 1 ปี 2567",
    Select_Sections: "เลือกหมวดหมู่",
    Your_Culture_Model: "ต้นแบบวัฒนธรรมองค์กรของคุณ",
    Publish: "เผยแพร่",
    Review_and_Publish: "ตรวจสอบและเผยแพร่",
    Details: "รายละเอียด",
    Selected: "เลือกแล้ว",
    Select: "เลือก",
    Values_and_Traits: "ค่านิยมและลักษณะนิสัย",
    Values_and_traits_influence:
      "ค่านิยมและลักษณะนิสัยจะส่งผลต่อพฤติกรรมของคน เมื่อคนในทีมมีค่านิยมที่ตรงกัน การทำงานร่วมกันก็จะง่ายและเป็นธรรมชาติ และคนในทีมมีลักษณะนิสัยสอดคล้องกัน ก็จะมุ่งเป้าการทำงานไปในทิศทางเดียวกัน",
    Values_Alignment: "ความสอดคล้องกันของค่านิยม",
    Values_Alignment_Description:
      "ค่านิยมเป็นตัวกำหนดพฤติกรรมของคน ถ้าค่านิยมส่วนตัวสอดคล้องกับค่านิยมหลักขององค์กร การทำงานจะง่ายและเป็นธรรมชาติ จึงต้องทำความเข้าใจเรื่องนี้ก่อนถ้าอยากรู้ว่าคนคนนั้นจะเข้ากันกับวัฒนธรรมองค์กร (culture fit) ได้แค่ไหน",
    Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
    Organizational_culture_is_driven:
      "วัฒนธรรมองค์กรถูกขับเคลื่อนด้วยการแข่งขันทางค่านิยม (Competing Values) 4 แบบด้วยกัน ลองเลือกรูปแบบวัฒนธรรมที่ใช่สำหรับบริษัทของคุณจากตัวเลือกเหล่านี้",
    Clan_Create_Collaborate_and:
      "การร่วมมือกัน (Collaborate), การลงมือทำ (Create), การแข่งขัน (Compete) และการควบคุม (Control)",
    across_six_dimensions_of: "ใน 6 มิติการทำงาน",
    Determine_culture_profile: "บ่งบอกความเข้ากันในด้านวัฒนธรรม",
    Determine_values_traits:
      "กำหนดค่านิยม และลักษณะนิสัยที่เข้ากับองค์กรของคุณ",
    Determine_value_alignment: "บ่งบอกความสอดคล้องของค่านิยม",
    Behaviors_are_considered_toxic:
      "พฤติกรรมจะกลายเป็นพิษ (Toxic) ถ้าไม่ตรงกับความคาดหวัง จึงต้องเข้าใจว่าพฤติกรรมใดบ้างที่คุณยอมรับได้ จะไม่ส่งเสริม หรือยอมรับไม่ได้เลย และดูว่ามีส่วนไหนที่คิดไม่ตรงกัน",
    Determine_expected_behaviors_and:
      "กำหนดพฤติกรรมที่องค์กรคาดหวัง และผลที่ตามมา",
    Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
    Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
    Identify_a_candidate_work_style:
      "ค้นหาสไตล์การทำงานของผู้ทำแบบทดสอบจากแนวคิด 3P",
    Product_Process_or_People:
      "ผลลัพธ์ (Product) ขั้นตอน (Process) และผู้คน (People)",
    Understanding_the_inclination_for:
      "การเข้าใจแนวโน้มของการทำงานแต่ละแบบ จะช่วยให้เห็นภาพมากขึ้นว่าแต่ละคนมีวิธีการทำงานให้สำเร็จ หรือจัดลำดับความสำคัญของงานยังไง",
    Setup_your_company_values:
      "เลือกค่านิยมและลักษณะนิสัยที่บ่งบอกถึงบริษัทของคุณ เพื่อหาสิ่งที่ทำให้องค์กรของคุณแตกต่างจากที่อื่น",
    Setup_your_culture_profile:
      "กำหนดรูปแบบวัฒธรรมองค์กรของคุณ ด้วยการแบ่งว่าองค์กรของคุณมีค่านิยมอยู่ในสัดส่วนใดบ้างตามกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values) ใน 6 มิติด้านการทำงาน",
    Setup_your_acceptable_behaviors:
      "เลือกพฤติกรรมที่คุณยอมรับได้ ด้วยการตัดสินจากตัวอย่างสถานการณ์การทำงาน",
    No_set_up_required:
      "ส่วนนี้คุณไม่ต้องกำหนด เนื่องจากเราจะประเมินจากแนวคิด 3P",
    We_recommend_selecting_core_values_to_help_configure_your_assessment: "We recommend selecting your core values to help us configure your assessment more effectively",
    // ---
    Communication_Styles: "สไตล์การสื่อสาร",
    Ready: "พร้อม",
    Set_up: "ตั้งค่า",
    SECTIONS: "หมวด",
    Sections: "หมวด",
    DURATION: "ระยะเวลา",
    Languages_required_to_support: "ภาษาที่ต้องการ",
    Advanced_Options: "ตัวเลือกขั้นสูง",
    Additional_configurations_to: "การตั้งค่าเพิ่มเติมสำหรับแบบทดสอบนี้",
    // ---
    Extra_Time: "ให้เวลาเพิ่ม",
    min: "นาที",
    Sharing_Results: "แชร์ผลลัพธ์แบบทดสอบอัตโนมัติ",
    Auto_send_assessment: "ส่งผลลัพธ์ให้ผู้ทำแบบทดสอบโดยอัตโนมัติ",
    Open_ended_Feedback: "ข้อเสนอแนะ",
    Allow_Comments: "ข้อเสนอแนะ",
    Allow_respondents_to:
      "เปิดให้ผู้ทำแบบทดสอบแบ่งปันข้อเสนอแนะในตอนท้ายของแต่ละหมวด",
    Human_Skills: "ทักษะด้านมนุษยสัมพันธ์",
    Human_skills_soft_skills:
      "ทักษะด้านมนุษยสัมพันธ์ (Soft skills) - การสื่อสารทางการเขียน ความเข้าอกเข้าใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ เป็นสิ่งสำคัญที่จะทำให้การสร้างความสัมพันธ์สำเร็จลุล่วง และทำให้การให้เหตุผลและการตัดสินใจมีความน่าเชื่อถือ",
    Evaluate_human_skills: "ประเมินทักษะด้านมนุษยสัมพันธ์",
    No_set_up_required_to_assess_written:
      "ในการประเมินการสื่อสารทางการเขียน ความเข้าอกเข้าใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ คุณไม่ต้องกำหนดค่าใด ๆ",
    Your_Core_Values: "ค่านิยมหลักของคุณ",
    Define_your_organizational:
      "กำหนดค่านิยมของบริษัทซึ่งจะเป็นตัวกำหนดรูปแบบวัฒนธรรมองค์กร แบบทดสอบแต่ละส่วนจะถูกสร้างและประเมินจากค่านิยมของคุณ",
    Quick_Select_or_Add: "เลือกจากคลัง หรือเพิ่มค่านิยมใหม่",
    Start_AI_Assisted_Setup: "ตั้งค่าด้วย AI-Assisted",
    Proceed_with_Manual_Setup: "ตั้งค่าเอง",
    Pending_Review: "รอการตรวจสอบ",
    Generate: "กำลังสร้าง..",
    Redirect_respondents_to: "ไปยังลิงก์นี้เพื่อดำเนินการให้เรียบร้อย",
    Carefully_check_all: "โปรดตรวจสอบข้อมูลทั้งหมดที่คุณให้อย่างระมัดระวัง",
    If_something_is_incorrect: "หากระบุข้อมูลผิดสามารถกลับไปแก้ไขได้",
    Assessment_Permissions: "การเข้าถึงของทีม",
    TeamAccess: {
      personal: "<strong>ส่วนตัว:</strong> ไม่อนุญาตให้บุคคลอื่นเข้าถึง",
      shared:
        "<strong>สาธารณะ:</strong> อนุญาตให้สมาชิกในทีมทุกคนสามารถเข้าถึงได้",
    },
    Logic_Test: "แบบทดสอบตรรกะ",
    Logical_reasoning_is_key_to_problem_solving: `การให้เหตุผลเชิงตรรกะนั้นเป็นหัวใจสำคัญของการแก้ปัญหาและการตัดสินใจ แบบทดสอบนี้จะประเมินความสามารถการหารูปแบบความพันธ์ การหาข้อสรุปด้วยหลักเหตุผล และการแก้ปัญหาที่ซับซ้อน`,
    Determine_logical_reasoning_proficiency:
      "ตัวชี้วัดความสามารถในการให้เหตุผลเชิงตรรกะ",
    Full_Details: `"แบบทดสอบนี้ประกอบด้วยปริศนาที่ท้าทายตรรกะหลากหลายรูปแบบ ทั้งการหารูปแบบความสัมพันธ์ การใช้เหตุผลเชิงนิรนัย และในเชิงปริภูมิ แต่ละคำถามถูกออกแบบมาเพื่อวัดระดับความสามารถในการเข้าใจข้อมูล หาข้อสรุป และประยุกต์ใช้หลักเหตุผลในสถานการณ์ที่แตกต่างกัน

    ผู้สมัครจะได้เจอกับโจทย์หลากหลายแบบ ตั้งแต่การหารูปแบบความสัมพันธ์ของข้อมูลไปจนถึงการสรุปผลลัพธ์จากสิ่งที่โจทย์กำหนดให้ แบบทดสอบจะประกอบด้วยโจทย์การใช้ตรรกะจากรูปภาพ ซึ่งจะวัดทักษะการใช้เหตุผลเชิงปริภูมิ ทักษะนี้จำเป็นต่อตำแหน่งที่ต้องใช้ความสามารถการวิเคราะห์ในระดับสูง
    
    การทดสอบนี้ไม่ได้บ่งบอกแค่ความสามารถการใช้เหตุผลของผู้สมัครเพียงอย่างเดียว แต่ยังสามารถบ่งบอกได้ว่าผู้สมัครสามารถแก้ปัญหานี้ได้อย่างไร ซึ่งจำเป็นต่อการทำงานในปัจจุบัน"`,
    Custom_Survey: "แบบสำรวจที่กำหนดเอง",
    Custom_Survey_Description: `แบบสำรวจที่กำหนดเองช่วยให้คุณสามารถสร้างแบบสอบถามที่เหมาะกับองค์กรของคุณ ใช้เพื่อรวบรวมคำติชมเฉพาะจากพนักงานหรือผู้สมัครของคุณ`,
    Custom_Survey_Description_Footer: `ออกแบบแบบสำรวจที่กำหนดเองของคุณเพื่อรวบรวมข้อมูลเชิงลึกที่มีความสำคัญที่สุดต่อบริษัทของคุณ`,
  },
  setup: {
    Setup_Complete: "สร้างเสร็จเรียบร้อยแล้ว",
    Thank_you_for_setting: "ขอบคุณสำหรับการสร้าง",
    You_can_now_close_this_window: "ปิดหน้าต่างนี้ได้ทันที",
    _Use_the_same_setup: "- ใช้ข้อมูลจากแบบทดสอบเดิม -",
    Proceed_with_a_new_setup: "เริ่มต้นสร้างใหม่",
    Auto_generated_for_form_name: "สร้างโดยอัตโนมัติสำหรับ {formName}",
    Resume_setup: "ดำเนินการสร้างต่อ",
    Use_the_same_setup_as_a_previous_assessment: "ใช้ข้อมูลจากแบบทดสอบเดิม",
    create_new_setup: "เริ่มต้นสร้างใหม่",
    Create_your_first_assessment_to_get_started:
      "สร้างการประเมินครั้งแรกของคุณเพื่อเริ่มต้น",
    Create_an_assessment_and_invite_candidates_or_team_members_to_align_on_your_culture_amp_values: `สร้างการประเมินและเชิญผู้สมัครหรือสมาชิกในทีมให้สอดคล้องกับวัฒนธรรมและค่านิยมของคุณ`,
    Get_Started_by_Creating_a_New_Assessment:
      "เริ่มต้นด้วยการสร้างการประเมินใหม่",
    Youre_just_10_minutes_away_from_evaluating_cultural_fit_for_your_next_hire_new_team_member_or_team_alignment: `คุณเหลือเวลาเพียง 10 นาทีในการประเมินความเหมาะสมทางวัฒนธรรมสำหรับการจ้างงานครั้งต่อไป สมาชิกทีมใหม่ หรือการจัดแนวทีม`,
    valueAndTraits: {
      Setup_your_Values_and_Traits: "กำหนดค่านิยม และลักษณะนิสัย",
      Setup_your_Values: "กำหนดค่านิยม",
      Select_Core_Values: "เลือกค่านิยมหลัก",
      Identify_your_most_important_values: "ระบุคุณค่านิยมที่สำคัญที่สุดของคุณ",
      Select_Permission_to_play_values: "เลือกค่านิยมการอนุญาตให้เล่น",
      Identify_your_basic_ethical_values: "ระบุค่านิยมทางจริยธรรมพื้นฐานของคุณ",
      Select_Non_essential_Values: "เลือกค่านิยมที่ไม่จำเป็น",
      Identify_values_that_do_not_guide_behaviors_here:
        "ระบุค่านิยมที่ไม่เป็นแนวทางพฤติกรรมที่นี่",
      Review: "ทบทวน",
      Confirm_your_value_assignments: "ยืนยันการกำหนดมูลค่านิยมของคุณ",
      Not_at_all_important: "ไม่สำคัญเลย",
      Very_important: "สำคัญมาก",
      Your_company_core_values:
        "ค่านิยมองค์กร เป็นเอกลักษณ์เฉพาะขององค์กรที่สามารถสร้างความได้เปรียบทางการแข่งขันให้กับคุณได้ ส่วนลักษณะนิสัย (Traits) เป็นสิ่งที่ทำให้คนในองค์กรสามารถประพฤติ และปฏิบัติได้ตามชุดค่านิยมที่องค์กรมี",
      In_the_next_steps_do:
        "ขั้นตอนถัดไป โปรดเลือกค่านิยม และลักษณะนิสัยที่มีความสำคัญต่อองค์กรของคุณที่สุด",
      Your_company_s_core_values_are:
        "ค่านิยมหลักของบริษัทของคุณนั้นไม่เหมือนใครสำหรับคุณ และทำให้คุณมีความได้เปรียบในการแข่งขัน ค่าการอนุญาตให้เล่นเป็นข้อกำหนดทางจริยธรรมของคุณ และคุณค่าที่ไม่จำเป็นของคุณจะทำให้ชัดเจนว่าสิ่งใดเป็นแนวทางในการตัดสินใจในที่นี้",
      Remember_that_every_value:
        "โปรดจำไว้ว่าทุกค่าที่คุณเลือกที่จะรวบรวมนั้นมีค่าใช้จ่าย",
      Defining_your_values: "กำหนดค่านิยมของคุณ",
      Select_and_manage_value:
        "เลือก และจัดการชุดค่านิยมที่คุณต้องการให้มีอยู่ในแบบทดสอบ",
      You_can_add_values: "คุณสามารถเพิ่มค่านิยมได้เองด้วย",
      Values_Library: "คลังค่านิยม",
      Do_not_include: "รายการนำออก",
      Add_Value: "เพิ่มค่านิยม",
      Add_a_value: "เพิ่มค่านิยม",
      Add_a_value_to_the:
        "เพิ่มค่านิยมในคลัง โปรดตรวจสอบว่ามันไม่ซ้ำ หรือใกล้เคียงเกินไปกับรายการที่มีอยู่แล้ว",
      New_Value_English: "ค่านิยมใหม่ (ภาษาอังกฤษ)",
      New_Value_Thai: "ค่านิยมใหม่ (ภาษาไทย)",
      Enter_value_here: "ใส่ค่านิยมที่นี่",
      Identifying_key_traits: "กำหนดลักษณะนิสัยสำคัญ",
      Select_and_manage_traits:
        "เลือก และจัดการชุดลักษณะนิสัยที่คุณต้องการให้มีอยู่ในแบบทดสอบ",
      You_can_add_traits: "คุณสามารถเพิ่มลักษณะนิสัยได้เองด้วย",
      Traits_Library: "คลังลักษณะนิสัย",
      Add_traits: "เพิ่มลักษณะนิสัย",
      Add_a_trait: "เพิ่มลักษณะนิสัย",
      Add_a_trait_to:
        "เพิ่มลักษณะนิสัยในคลัง โปรดตรวจสอบว่ามันไม่ซ้ำ หรือใกล้เคียงเกินไปกับรายการที่มีอยู่แล้ว",
      New_Trait_English: "ลักษณะนิสัย (ภาษาอังกฤษ)",
      New_Trait_Thai: "ลักษณะนิสัย (ภาษาไทย)",
      Enter_trait_here: "ใส่ลักษณะนิสัยที่นี่",
      core_values: "ค่านิยมหลักของคุณ",
      permission_to_play: "ค่าอนุญาตในการเล่นของคุณ",
      non_essential: "ค่านิยมที่ไม่จำเป็นของคุณ",
      moderately_important: "ค่านิยมความสำคัญปานกลางของคุณ",
      Setup_Completed: "การตั้งค่าเสร็จสมบูรณ์",
      CORE_VALUE: "ค่านิยมหลัก",
      PERMISSION_TO_PLAY: "สิทธิ์ในการเล่น",
      Thank_you_for_setting_up:
        "ขอบคุณสำหรับการตั้งค่าของคุณ ตอนนี้คุณสามารถใช้เพื่อประเมินค่าที่เหมาะสมกับผู้สมัครของคุณได้แล้ว",
      What_are_your_core_values_that_set:
        "<u>ค่านิยมหลัก</u>ของคุณที่ทำให้คุณแตกต่างจากคนอื่นๆ คืออะไร",
      These_are_the_unique_values_that:
        "สิ่งเหล่านี้คือค่านิยมเฉพาะที่กำหนดองค์กรและแยกออกจากองค์กรอื่นๆ หากบริษัทสูญเสียคุณค่าเหล่านี้ไป มันก็จะไม่เป็นบริษัทเดิมอีกต่อไป <u>เลือกได้สูงสุดหกรายการ</u>",
      What_are_your_permission_to_play:
        "<u>ค่าสิทธิ์ในการเล่น</u>ของคุณคือเท่าใด",
      These_are_basic_ethical_values:
        "สิ่งเหล่านี้คือค่านิยมพื้นฐานทางจริยธรรมที่บริษัทของคุณควรมี พวกเขาจำเป็นต้องรักษาไว้แต่ไม่ซ้ำกับองค์กร <u>เลือกได้สูงสุดหกรายการ</u>",
      What_are_non_essential_values_that_do_not:
        "<u>ค่านิยมที่ไม่จำเป็น</u>ที่ไม่เป็นแนวทางในการตัดสินใจและการดำเนินการในที่นี้คืออะไร",
      These_are_values_that_do_not_significantly:
        "สิ่งเหล่านี้เป็นค่านิยมที่ไม่มีอิทธิพลอย่างมีนัยสำคัญต่อการกระทำและการตัดสินใจภายในองค์กร ค่าเหล่านี้อาจเป็นค่านิยมที่ฟังดูดีบนกระดาษ แต่ในความเป็นจริงแล้ว อย่าชี้นำองค์กรหรือพนักงานในทางที่มีความหมาย <u>เลือกอย่างน้อยหนึ่งรายการ</u>",
      Review_your_values_selection:
        "ตรวจสอบการเลือกค่าและการให้คะแนนความสำคัญของคุณ",
      Confirm_the_assignment_of_each_value:
        "ยืนยันการกำหนดค่าแต่ละค่าให้กับแต่ละหมวดหมู่ที่แสดงถึงระดับความสำคัญที่คุณค่ามีต่อวัฒนธรรมองค์กรของคุณ ลากและวางเพื่อทำการปรับเปลี่ยนขั้นสุดท้าย",
      Set_up_Values_n_Traits: "ตั้งค่าคุณค่าและคุณลักษณะ",
      Set_up_Values_Alignment: "ตั้งค่าการจัดแนวค่านิยม",
      Identify_the_attributes:
        "ระบุคุณลักษณะที่สร้างวัฒนธรรมที่มีประสิทธิภาพสูงโดยให้คะแนนค่านิยมและคุณลักษณะที่เลือกตามความสำคัญ",
      Identify_your_values:
        "ระบุแกนหลัก การอนุญาตให้เล่น และค่าที่ไม่จำเป็นที่เป็นแนวทางในการตัดสินใจและการกระทำของคุณ",
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Covered_Skills: "ทักษะที่ครอบคลุม",
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      Brief_Description:
        "ค่านิยมทำหน้าที่เป็นเข็มทิศนำทางการกระทำและพฤติกรรมของเรา โมดูลการประเมินค่าจะพิจารณาว่าค่านิยมส่วนบุคคลของผู้สมัครสอดคล้องกับค่านิยมหลัก ไม่จำเป็น และอนุญาตให้เล่นของบริษัทอย่างไร โดยกำหนดรากฐานสำหรับการทำงานร่วมกันอย่างมีประสิทธิภาพและกลมกลืน",
      Full_Details: `โมดูลการประเมินค่านิยมแบบครอบคลุมนี้ได้รับการออกแบบมาเพื่อวัดระดับความสอดคล้องระหว่างค่านิยมส่วนบุคคลของผู้สมัครกับค่านิยมที่บริษัทยึดมั่น มันไม่ได้เกี่ยวกับการค้นหาค่าที่ "ถูก" หรือ "ผิด" แต่มุ่งเน้นไปที่การค้นหาความเข้ากันได้และการจัดตำแหน่งแทน การจับคู่ระหว่างค่านิยมของผู้สมัครและค่านิยมของบริษัทสามารถปูทางสำหรับการบูรณาการเข้ากับทีมได้อย่างราบรื่นมากขึ้น การทำงานร่วมกันราบรื่นขึ้น และโอกาสที่จะพึงพอใจในงานสูงขึ้น


      โมดูลนี้ใช้รายการค่าที่คัดสรรมาอย่างดีพร้อมคำอธิบายเพื่อให้ผู้สมัครเปิดเผยค่าของตนได้ รายการนี้ได้รับการออกแบบมาให้ไม่เป็นผู้นำ ไม่ลำเอียง และเป็นกลาง เพื่อให้มั่นใจว่าคำตอบมีความถูกต้อง ผลลัพธ์จากโมดูลนี้จะถูกอ้างอิงโยงกับค่านิยมหลักของบริษัท (ค่าที่ไม่สามารถต่อรองได้และอยู่ภายในตัวตนของบริษัท) ค่าอนุญาตในการเล่น (ค่าที่พนักงานต้องปฏิบัติตามจึงจะเป็นส่วนหนึ่งขององค์กร) และค่าที่ไม่จำเป็น (ค่าที่แม้จะชื่นชม แต่ก็ไม่ได้บังคับสำหรับการบูรณาการที่ประสบความสำเร็จ)
     
      กระบวนการนี้ไม่ได้เกี่ยวกับการคัดผู้สมัครที่มีค่านิยมที่แตกต่างกัน แต่เป็นการค้นหาผู้ที่จะรู้สึกเหมือนอยู่บ้านในวัฒนธรรมของบริษัทและมีส่วนสนับสนุนในเชิงบวก ท้ายที่สุดแล้ว โมดูลค่านิยมของเรามีเป้าหมายเพื่อคาดการณ์ว่าผู้สมัครจะมีปฏิสัมพันธ์กับทีมที่มีอยู่อย่างไร พวกเขาจะจัดการกับการตัดสินใจอย่างไร และพวกเขาจะตอบสนองต่อวัฒนธรรมของบริษัทอย่างไร
      `,
      Covered_Skills_Text: `- การระบุคุณค่าส่วนบุคคล
      - การสะท้อนตนเองและวิปัสสนา
      - ทำความเข้าใจบทบาทของค่านิยมในการตัดสินใจ
      - ความเข้ากันได้กับวัฒนธรรมองค์กรและการเปลี่ยนแปลงของทีม`,
      This_test_is_relevant_for_Text: `- ระดับ C
      - ผู้นำทางธุรกิจ
      - ผู้นำด้านทรัพยากรบุคคล
      - ผู้จัดการฝ่ายทรัพยากรบุคคล`,
    },
    cultureProfile: {
      Setup_your_Culture_Profile: "กำหนดรูปแบบวัฒนธรรมองค์กรของคุณ",
      The_Happily_Culture_Profile:
        "รูปแบบวัฒนธรรมองค์กร ที่ MyCulture ใช้นั้นถูกประยุกต์มาจากกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values Framework) และถูกใช้โดยองค์กรต่าง ๆ มากกว่า 10,000 องค์กรทั่วโลก",
      Identify_the_culture_type:
        "จาก 6 ด้านขององค์กรคุณ ในแต่ละด้านมีวัฒธรรมใดที่โดดเด่นบ้าง มาดูกันว่าระหว่าง การร่วมมือ การควบคุม การแข่งขัน หรือการสร้างสรรค์สิ่งใหม่  คุณให้ความสนใจสิ่งใดมากกว่ากัน",
      Setup_Completed: "การตั้งค่าเสร็จสมบูรณ์",
      Thank_you_for_setting_up:
        "ขอบคุณสำหรับการตั้งค่า “รูปแบบวัฒนธรรมองค์กร” ตอนนี้คุณสามารถใช้สำหรับการสร้างการประเมินได้",
      Rate: "คะแนน",
      Overall: "Overall",
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Covered_Skills: "ทักษะที่ครอบคลุม",
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      Brief_Description:
        "วัฒนธรรมเป็นกระดูกสันหลังขององค์กรซึ่งกำหนดโดยเมทริกซ์ของค่านิยมที่แข่งขันกัน โมดูลโปรไฟล์วัฒนธรรมช่วยให้คุณระบุและจับคู่ผู้สมัครกับประเภทวัฒนธรรมในอุดมคติในมิติการทำงานที่สำคัญ 6 มิติ ได้แก่ ทำงานร่วมกัน สร้างสรรค์ แข่งขัน และควบคุม",
      Full_Details: `โมดูลการประเมินโปรไฟล์วัฒนธรรมนำเสนอแนวทางเฉพาะในการทำความเข้าใจและสอดคล้องกับวัฒนธรรมของบริษัทของคุณ โดยใช้กรอบคุณค่าการแข่งขันที่ได้รับการพิสูจน์แล้วซึ่งนำไปใช้โดยบริษัทมากกว่า 10,000 แห่งทั่วโลก ไม่ใช่แค่การระบุวัฒนธรรมในที่ทำงานที่ผู้สมัครชื่นชอบเท่านั้น แต่ยังเป็นการประเมินความเข้ากันได้กับประเภทวัฒนธรรมเฉพาะขององค์กรของคุณด้วย สิ่งนี้สามารถเพิ่มความพึงพอใจ ผลผลิต และการรักษาพนักงานได้อย่างมาก


      ในโมดูลนี้ ผู้สมัครจะสำรวจสถานการณ์สมมติที่กระตุ้นความคิดและคำถามที่สำรวจประเด็นสำคัญ 6 ประการของชีวิตการทำงาน คำตอบช่วยกำหนดแนวโน้มของผู้สมัครต่อหนึ่งในสี่วัฒนธรรมประเภท: การทำงานร่วมกัน (วัฒนธรรมที่เน้นการทำงานเป็นทีม การมีส่วนร่วม และฉันทามติ) สร้าง (วัฒนธรรมที่ส่งเสริมนวัตกรรม ความยืดหยุ่น และความเป็นเอกลักษณ์) การแข่งขัน (วัฒนธรรมที่ให้ความสำคัญกับความสำเร็จ ผลลัพธ์ และชัยชนะ) และการควบคุม (วัฒนธรรมที่เน้นประสิทธิภาพ ความมั่นคง และการทำสิ่งที่ถูกต้อง)
     
      การทำความเข้าใจว่าผู้สมัครมีคุณสมบัติตรงตามเกณฑ์ทางวัฒนธรรมเหล่านี้สามารถเปลี่ยนแปลงได้อย่างไร ไม่เพียงแต่ช่วยให้ผู้สมัครอยู่ในตำแหน่งที่พวกเขามีแนวโน้มที่จะประสบความสำเร็จมากขึ้นเท่านั้น แต่ยังช่วยส่งเสริมความสามัคคีและประสิทธิภาพการทำงานโดยรวมภายในทีมของคุณอีกด้วย
      `,
      Covered_Skills_Text: `- การปรับตัวให้เข้ากับวัฒนธรรมการทำงานต่างๆ
      - ความเข้าใจเกี่ยวกับพลวัตขององค์กร
      - ความสามารถในการจัดลำดับความสำคัญของการตั้งค่า
      - ความตระหนักรู้ถึงอิทธิพลซึ่งกันและกันระหว่างสไตล์ส่วนตัวและความสำเร็จขององค์กร`,
      This_test_is_relevant_for_Text: `- ระดับ C
      - ผู้นำทางธุรกิจ
      - ผู้นำด้านทรัพยากรบุคคล
      - ผู้จัดการฝ่ายทรัพยากรบุคคล`,
    },
    acceptableBehaviors: {
      Setup_your_Acceptable_Behaviors: "กำหนดพฤติกรรมที่คุณยอมรับได้",
      Identify_areas_of_misalignment:
        "กำหนดขอบเขตให้กับสิ่งที่อาจเห็นไม่ตรงกันในเรื่องพฤติกรรมที่ยอมรับได้ เพื่อขจัดพฤติกรรมที่เป็นพิษ และสร้างความคาดหวังที่ตรงกัน",
      In_each_scenario:
        "โปรดจัดระดับว่า พฤติกรรมใดยอมรับได้ หรือยอมรับไม่ได้ในแต่ละสถานการณ์ เพื่อเป็นบรรทัดฐานในการเปรียบเทียบ",
      Review_the_acceptable_behaviors:
        "ตรวจสอบพฤติกรรมที่ยอมรับได้ที่คุณต้องการประเมิน เพิ่มหรือลบสถานการณ์ตามต้องการ เราขอแนะนำให้คุณมีสถานการณ์ทั้งหมดไม่เกิน 15 สถานการณ์",
      Workplace_Scenarios: "สถานการณ์สมมติในที่ทำงาน",
      Add_a_workplace_scenario: "สถานการณ์สมมติในที่ทำงาน",
      Add_a_scenario_that:
        "เพิ่มสถานการณ์เพื่อให้องค์กร และผู้ทำแบบทดสอบประเมินว่า ยอมรับได้ ไม่พอใจ ไม่สนับสนุน ควรมีการตำหนิลงโทษ หรือ ยอมรับไม่ได้อย่างเด็ดขาด",
      New_Scenario_English: "สถานการณ์ใหม่ (ภาษาอังกฤษ)",
      New_Scenario_Thai: "สถานการณ์ใหม่ (ภาษาไทย)",
      Enter_scenario_here: "ใส่สถานการณ์สมมติในที่ทำงานที่นี่",
      Setup_your_Values: "ตั้งค่าพฤติกรรมที่ยอมรับได้ของคุณ",
      Setup_Completed: "การตั้งค่าเสร็จสมบูรณ์",
      Thank_you_for_setting_up:
        "ขอขอบคุณสำหรับการตั้งค่า “พฤติกรรมที่ยอมรับได้“ ตอนนี้คุณสามารถใช้มันเพื่อสร้างการประเมินได้",
      Add_Scenario: "เพิ่มสถานการณ์",
      scenario: "สถานการณ์",
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Covered_Skills: "ทักษะที่ครอบคลุม",
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      Brief_Description:
        "พฤติกรรมอาจก่อกวนได้หากไม่สอดคล้องกับบรรทัดฐานที่คาดหวัง โมดูลพฤติกรรมที่ยอมรับได้ ได้รับการออกแบบมาเพื่อกำหนดและทำความเข้าใจพฤติกรรมที่ได้รับการส่งเสริม ท้อแท้ หรือไม่ยอมรับ ซึ่งจะช่วยระบุพฤติกรรมที่ไม่ตรงกันที่อาจเกิดขึ้น",
      Full_Details: `การทำความเข้าใจและรักษาพฤติกรรมที่ยอมรับได้ถือเป็นสิ่งสำคัญสำหรับสถานที่ทำงานที่ดีต่อสุขภาพและมีประสิทธิผล โมดูลการประเมินพฤติกรรมที่ยอมรับได้ได้รับการออกแบบเพื่อให้ความกระจ่างเกี่ยวกับมาตรฐานเหล่านี้ ช่วยให้ทั้งผู้สมัครและนายจ้างพิจารณาความเหมาะสมได้


      ในโมดูลนี้ ผู้สมัครจะได้รับการนำเสนอชุดสถานการณ์สมมติที่สะท้อนถึงสถานการณ์ในที่ทำงานในชีวิตจริง การตอบสนองต่อสถานการณ์เหล่านี้ช่วยระบุความโน้มเอียงทางพฤติกรรมของผู้สมัคร ซึ่งจะถูกเปรียบเทียบกับมาตรฐานที่บริษัทกำหนดไว้เกี่ยวกับพฤติกรรมที่ยอมรับ ท้อแท้ และไม่ยอมรับ
     
      กระบวนการนี้ช่วยให้เราตรวจพบความไม่ตรงกันที่อาจเกิดขึ้นได้ตั้งแต่เนิ่นๆ และสามารถมีส่วนสำคัญในการสร้างสถานที่ทำงานที่มีความเคารพ ครอบคลุม และกลมกลืนกัน นอกจากนี้ยังช่วยลดความเสี่ยงของพฤติกรรมที่เป็นพิษซึ่งอาจขัดขวางประสิทธิภาพการทำงาน ส่งผลกระทบต่อขวัญกำลังใจของทีม และนำไปสู่การลาออกของพนักงานที่สูงขึ้น
     
      นอกจากนี้ ยังกำหนดความคาดหวังที่ชัดเจนเกี่ยวกับพฤติกรรมที่คาดหวังภายในบริษัท ช่วยให้ผู้สมัครเข้าใจวัฒนธรรมที่พวกเขาอาจเข้าร่วมได้ดีขึ้น ความชัดเจนนี้สามารถปรับปรุงทั้งกระบวนการจ้างงานและการบูรณาการของผู้สมัครเข้ากับบทบาทใหม่ได้อย่างมาก`,
      Covered_Skills_Text: `- การจัดพฤติกรรมส่วนบุคคลให้สอดคล้องกับบรรทัดฐานของบริษัท
      - ความเข้าใจถึงผลกระทบของพฤติกรรมต่อสถานที่ทำงาน
      - การปฏิบัติตามมาตรฐานพฤติกรรม
      - การเคารพขอบเขตขององค์กร`,
      This_test_is_relevant_for_Text: `- ระดับ C
      - ผู้นำทางธุรกิจ
      - ผู้นำด้านทรัพยากรบุคคล
      - ผู้จัดการฝ่ายทรัพยากรบุคคล
      - ผู้จัดการทีม`,
    },
    humanSkills: {
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Covered_Skills: "ทักษะที่ครอบคลุม",
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      Brief_Description:
        "ทักษะของมนุษย์หรือทักษะทางอารมณ์มีบทบาทสำคัญในการส่งเสริมความสัมพันธ์ที่ประสบความสำเร็จและอำนวยความสะดวกในการตัดสินใจที่ดี โมดูลนี้มุ่งเน้นไปที่การประเมินทักษะที่สำคัญของมนุษย์ เช่น การสื่อสารด้วยลายลักษณ์อักษร การเอาใจใส่ การตระหนักรู้ในสถานการณ์ และการคิดอย่างมีวิจารณญาณ",
      Full_Details: `โมดูลการประเมินทักษะมนุษย์ได้รับการออกแบบอย่างพิถีพิถันเพื่อตรวจสอบและประเมินทักษะที่ละเอียดอ่อนแต่มีความสำคัญมากขึ้น ซึ่งจะทำให้ผู้สมัครประสบความสำเร็จในทุกบทบาท เมื่อโลกแห่งการทำงานพัฒนาขึ้น ความสามารถในการเชื่อมต่อ ทำความเข้าใจ และตอบสนองต่อสถานการณ์ต่างๆ ได้อย่างมีประสิทธิภาพก็มีความสำคัญมากขึ้นเรื่อยๆ


      ในโมดูลนี้ ผู้สมัครต้องเผชิญกับสองสถานการณ์ที่วัดความสามารถในการสื่อสารที่เป็นลายลักษณ์อักษร นี่ไม่เพียงแค่เกี่ยวกับไวยากรณ์และคำศัพท์เท่านั้น แต่ยังเกี่ยวกับความชัดเจน ความกระชับ และความสามารถในการถ่ายทอดแนวคิดที่ซับซ้อนได้อย่างมีประสิทธิภาพ
     
      ความเห็นอกเห็นใจได้รับการประเมินโดยการสำรวจความสามารถของผู้สมัครในการทำความเข้าใจและแบ่งปันความรู้สึกของผู้อื่น ซึ่งเป็นสิ่งสำคัญในการส่งเสริมความสัมพันธ์ที่แข็งแกร่งภายในทีมและกับลูกค้า ความตระหนักในสถานการณ์ได้รับการทดสอบผ่านความสามารถของผู้สมัครในการรับรู้ เข้าใจ และตอบสนองต่อสถานการณ์หรือเหตุการณ์ที่เปลี่ยนแปลงตลอดเวลาในที่ทำงาน
     
      สุดท้ายนี้ เราประเมินการคิดอย่างมีวิจารณญาณ ซึ่งเป็นทักษะที่สนับสนุนการแก้ปัญหาและการตัดสินใจอย่างมีประสิทธิผล สิ่งนี้วัดจากความสามารถของผู้สมัครในการวิเคราะห์ข้อมูลอย่างเป็นกลาง ให้เหตุผลอย่างมีเหตุผล และสรุปผลที่เชื่อถือได้`,
      Covered_Skills_Text: `- มีทักษะในการสื่อสารด้วยลายลักษณ์อักษร
      - ความสามารถในการเอาใจใส่และเข้าใจผู้อื่น
      - ความตระหนักรู้ในสถานการณ์เฉียบพลันและการปรับตัว
      - มีความสามารถในการคิดอย่างมีวิจารณญาณและการตัดสินใจ`,
      This_test_is_relevant_for_Text: `- ระดับ C
      - ผู้นำทางธุรกิจ
      - ผู้นำด้านทรัพยากรบุคคล
      - ผู้จัดการฝ่ายทรัพยากรบุคคล
      - ผู้จัดการทีม`,
    },
    workStyleIdentifier: {
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Covered_Skills: "ทักษะที่ครอบคลุม",
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      Brief_Description:
        "บุคคลทุกคนมีแนวทางการทำงานที่แตกต่างกันออกไป โดยมีกรอบการทำงาน 3P ได้แก่ ผลิตภัณฑ์ กระบวนการ หรือบุคลากร โมดูลตัวระบุลักษณะการทำงานช่วยเปิดเผยรูปแบบการทำงานที่โดดเด่นของผู้สมัคร โดยให้ข้อมูลเชิงลึกอันมีค่าเกี่ยวกับวิธีการทำงานและจัดลำดับความสำคัญของงาน",
      Full_Details: `โมดูลการประเมินตัวระบุลักษณะการทำงานเป็นเครื่องมือที่มีประสิทธิภาพซึ่งจะเจาะลึกแนวทางการทำงานของผู้สมัคร ด้วยการใช้กรอบการทำงาน 3P เราจะสามารถเข้าใจได้ดีขึ้นว่าอะไรขับเคลื่อนผู้สมัครและพวกเขาทำงานตามธรรมชาติภายในสภาพแวดล้อมการทำงานอย่างไร


      ในหมวดหมู่ผลิตภัณฑ์ บุคคลจะถูกขับเคลื่อนด้วยผลลัพธ์ที่จับต้องได้และสิ่งที่ส่งมอบ โดยมุ่งเน้นไปที่ผลลัพธ์สุดท้าย ในหมวดกระบวนการ ผู้คนมีแนวโน้มที่จะมีวิธีการที่เป็นระบบ ทีละขั้นตอน และความสม่ำเสมอในการทำงานมากขึ้น สุดท้ายนี้ หมวดหมู่ผู้คนประกอบด้วยบุคคลที่จัดลำดับความสำคัญของความสัมพันธ์และการทำงานเป็นทีม โดยให้ความสำคัญกับองค์ประกอบของมนุษย์ในการทำงานเหนือสิ่งอื่นใด
     
      การประเมินจะเปิดเผยผ่านสถานการณ์ต่างๆ ในที่ทำงาน โดยขอให้ผู้สมัครเลือกความต้องการที่โดดเด่นของตนในแต่ละสถานการณ์ วิธีการนี้นำเสนอแนวทางในชีวิตจริงเพื่อทำความเข้าใจสไตล์การทำงานของผู้สมัคร ให้ข้อมูลเชิงลึกเกี่ยวกับวิธีการปฏิบัติงาน โต้ตอบกับผู้อื่น และปรับให้สอดคล้องกับขั้นตอนการทำงานของบริษัท
     
      การระบุรูปแบบการทำงานที่โดดเด่นของผู้สมัครไม่เพียงแต่ช่วยคาดการณ์ความเหมาะสมของพวกเขาในทีม แต่ยังช่วยให้ผู้จัดการสามารถจัดสรรงานได้อย่างมีประสิทธิภาพมากขึ้น ซึ่งท้ายที่สุดจะนำไปสู่ประสิทธิภาพของทีมและความพึงพอใจในงานที่ดีขึ้น
      `,
      Covered_Skills_Text: `- เข้าใจความชอบสไตล์การทำงานของตนเอง
      - การจัดรูปแบบการทำงานส่วนบุคคลให้สอดคล้องกับสถานการณ์
      - ตระหนักถึงผลกระทบของรูปแบบการทำงานที่มีต่อพลวัตของทีม
      - ความยืดหยุ่นและการปรับตัวในสภาพแวดล้อมการทำงานที่หลากหลาย`,
      This_test_is_relevant_for_Text: `- ระดับ C
      - ผู้นำทางธุรกิจ
      - ผู้นำด้านทรัพยากรบุคคล
      - ผู้จัดการฝ่ายทรัพยากรบุคคล
      - ผู้จัดการทีม`,
    },
    logicTest: {
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Brief_Description: `แบบทดสอบด้านตรรกะนี้เป็นเครื่องมือสำคัญในการประเมินความสามารถด้านการใช้เหตุผลของผู้สมัคร โดยใช้ปริศนาและสถานการณ์ที่ต้องใช้การวิเคราะห์ด้วยหลักเหตุผล เพื่อประเมินระดับความสามารถการหารูปแบบที่ซับซ้อน และแก้ปัญหาอย่างมีประสิทธิภาพ`,
      Full_Details: `"แบบทดสอบนี้ประกอบด้วยปริศนาที่ท้าทายตรรกะหลากหลายรูปแบบ ทั้งการหารูปแบบความสัมพันธ์ การใช้เหตุผลเชิงนิรนัย และในเชิงปริภูมิ แต่ละคำถามถูกออกแบบมาเพื่อวัดระดับความสามารถในการเข้าใจข้อมูล หาข้อสรุป และประยุกต์ใช้หลักเหตุผลในสถานการณ์ที่แตกต่างกัน

      ผู้สมัครจะได้เจอกับโจทย์หลากหลายแบบ ตั้งแต่การหารูปแบบความสัมพันธ์ของข้อมูลไปจนถึงการสรุปผลลัพธ์จากสิ่งที่โจทย์กำหนดให้ แบบทดสอบจะประกอบด้วยโจทย์การใช้ตรรกะจากรูปภาพ ซึ่งจะวัดทักษะการใช้เหตุผลเชิงปริภูมิ ทักษะนี้จำเป็นต่อตำแหน่งที่ต้องใช้ความสามารถการวิเคราะห์ในระดับสูง
      
      การทดสอบนี้ไม่ได้บ่งบอกแค่ความสามารถการใช้เหตุผลของผู้สมัครเพียงอย่างเดียว แต่ยังสามารถบ่งบอกได้ว่าผู้สมัครสามารถแก้ปัญหานี้ได้อย่างไร ซึ่งจำเป็นต่อการทำงานในปัจจุบัน"`,
      Covered_Skills: "ทักษะที่ครอบคลุม",
      Covered_Skills_Text: `- การแก้ปัญหาและการคิดวิเคราะห์ขั้นสูง
      - การหารูปแบบความสัมพันธ์และวิเคราะห์ลำดับด้วยตรรกะ
      - การให้เหตุผลเชิงปริภูมิ หรือคุณสมบัติที่มองเห็นได้
      - การให้เหตุผลเชิงนิรนัย และการทดสอบสมมติฐาน
      - การตัดสินใจเชิงวิเคราะห์ภายใต้ระยะเวลาจำกัด`,
      This_test_is_relevant_for_Text: ``,
    },
    customSurvey: {
      Set_up_Custom_Survey: "ตั้งค่าการสำรวจแบบกำหนดเอง",
      Setup_your_Custom_Survey: "ตั้งค่าการสำรวจแบบกำหนดเองของคุณ",
      Question_Block: "บล็อกคำถาม",
      Open_ended: "เปิดกว้าง",
      Multiple_Choice: "ตัวเลือกรายการเดียว",
      Multiple_Select: "เลือกหลายรายการ",
      Add_your_own_set_of_questions:
        "เพิ่มชุดคำถามของคุณเองที่ช่วยให้คุณรวบรวมข้อมูลที่จำเป็นและข้อเสนอแนะ",
      Title_of_Section: "หัวเรื่องของหมวด",
      Description_optional: "คำอธิบาย (ไม่บังคับ)",
      Short_Description: "คำอธิบายสั้น ๆ",
      Setup_Completed: "การตั้งค่าเสร็จสมบูรณ์",
      Thank_you_for_setting_up:
        "ขอขอบคุณที่คุณได้กำหนดส่วนการสำรวจที่กำหนดเองเป็นส่วนหนึ่งของการประเมินของคุณ",
      questionTypes: {
        "open-ended": "เปิดกว้าง",
        "multiple-choice": "ตัวเลือกรายการเดียว",
        "multiple-select": "เลือกหลายรายการ",
      },
      What_makes_you_happiest_at_work: "อะไรทำให้คุณมีความสุขที่สุดในการทำงาน?",
      Type_your_answer_here: "พิมพ์คำตอบของคุณที่นี่...",
      Add_Option: "เพิ่มตัวเลือก",
      Option_A: "ตัวเลือก A",
      Option_B: "ตัวเลือก B",
      Add_and_configure_questions: "เพิ่มและกำหนดค่าคำถาม",
      Select_from_different_question_and_content_blocks_to_create_an_easy_to_understand_survey:
        "เลือกจากบล็อกคำถามและเนื้อหาที่แตกต่างกันเพื่อสร้างแบบสำรวจที่เข้าใจง่าย",
      Review_your_questions_for_your_custom_survey:
        "ตรวจสอบคำถามของคุณสำหรับการสำรวจที่กำหนดเองของคุณ",
      addMultipleChoiceErrorMsg: "ถึงจำนวนตัวเลือกสูงสุดแล้วสำหรับแบบเลือกตอบ",
      addMultipleSelectErrorMsg:
        "ถึงจำนวนตัวเลือกสูงสุดสำหรับการเลือกหลายรายการแล้ว",
      removeMultipleChoiceErrorMsg:
        "จำนวนตัวเลือกจะน้อยกว่าสำหรับตัวเลือกแบบหลายตัวเลือก",
      removeMultipleSelectErrorMsg:
        "จำนวนตัวเลือกมีน้อยกว่าสำหรับตัวเลือกแบบหลายตัวเลือก",
      Preview: "ตัวอย่าง",
      Description: "คำอธิบาย",
      Details: "รายละเอียด",
      Brief_Description:
        "แบบสำรวจที่กำหนดเองเป็นเครื่องมือที่มีความยืดหยุ่นที่ช่วยให้คุณสร้างแบบสอบถามที่ปรับแต่งตามความต้องการขององค์กรของคุณโดยเฉพาะ โมดูลนี้ช่วยให้คุณรวบรวมข้อเสนอแนะและข้อมูลเชิงลึกที่แม่นยำจากพนักงานหรือผู้สมัคร ช่วยให้คุณตัดสินใจอย่างมีข้อมูลเกี่ยวกับความเหมาะสมทางวัฒนธรรมและการจัดแนวองค์กร",
      Full_Details: `โมดูลแบบสำรวจที่กำหนดเองให้โอกาสพิเศษในการเจาะลึกด้านต่างๆ ของวัฒนธรรมองค์กรของคุณที่การประเมินมาตรฐานอาจไม่ได้ครอบคลุม คุณสามารถออกแบบคำถามที่กล่าวถึงค่านิยม เป้าหมาย และความท้าทายเฉพาะขององค์กรของคุณ เพื่อให้แน่ใจว่าคุณจะรวบรวมข้อมูลที่เกี่ยวข้องและดำเนินการได้มากที่สุด

ในโมดูลนี้ คุณมีอิสระในการสร้างคำถามประเภทต่างๆ รวมถึงตัวเลือกหลายตัวเลือก ระดับคะแนน และคำตอบแบบปลายเปิด ความคล่องตัวนี้ช่วยให้คุณสำรวจทั้งด้านปริมาณและคุณภาพของมุมมองของผู้สมัครหรือพนักงาน ซึ่งจะทำให้เข้าใจอย่างครอบคลุมมากขึ้นเกี่ยวกับศักยภาพที่เหมาะสมของพวกเขาภายในองค์กรของคุณ

ด้วยการใช้แบบสำรวจที่กำหนดเอง คุณสามารถ:
1. กล่าวถึงความแตกต่างทางวัฒนธรรมเฉพาะที่มีลักษณะเฉพาะของบริษัทของคุณ
2. รวบรวมข้อมูลเชิงลึกเกี่ยวกับทักษะหรือคุณลักษณะเฉพาะที่สำคัญต่อองค์กรของคุณ
3. ประเมินความสอดคล้องกับภารกิจและวัตถุประสงค์ระยะยาวของบริษัทของคุณ
4. ระบุพื้นที่ที่มีศักยภาพสำหรับการปรับปรุงองค์กร

การทำความเข้าใจคำตอบจากแบบสำรวจที่กำหนดเองของคุณอาจช่วยเปลี่ยนแปลงชีวิตคุณได้ ซึ่งไม่เพียงแต่ช่วยในการตัดสินใจในการจ้างงานอย่างมีข้อมูลเพียงพอเท่านั้น แต่ยังให้ข้อมูลเชิงลึกอันมีค่าสำหรับการปรับปรุงวัฒนธรรมองค์กรโดยรวมและประสบการณ์ของพนักงานอีกด้วย`,
      Covered_Skills: "ทักษะที่ครอบคลุม",
      Covered_Skills_Text: `- ความสามารถในการให้ข้อเสนอแนะที่ตรงเป้าหมาย
- การตระหนักรู้และการไตร่ตรองในตนเอง
- การจัดแนวให้สอดคล้องกับค่านิยมเฉพาะขององค์กร
- ความเข้าใจในแนวทางปฏิบัติหรือวิธีการเฉพาะของบริษัท`,
      This_test_is_relevant_for: "การทดสอบนี้เกี่ยวข้องกับ",
      This_test_is_relevant_for_Text: `- พนักงานทุกระดับ
- ผู้สมัครงาน
- หัวหน้าแผนก
- ผู้เชี่ยวชาญด้านทรัพยากรบุคคล
- ทีมผู้นำ`,
    },
  },
  question: {
    common: {
      move_on_step_n: "ไปต่อขั้นที่ {step} กันเลย!",
      Start: "เริ่ม",
      thank_you: "ขอบคุณนะ",
      thank_you_msg:
        "เราขอขอบคุณที่คุณสละเวลาทำแบบประเมินและช่วยให้เราเข้าใจถึงเรื่องที่คุณให้ความสำคัญมากที่สุด",
      go_to_main_page: "Go to Main Page",
    },
    valueAndTraits: {
      Values_and_Traits: "ค่านิยม และลักษณะนิสัย",
      Your_values_guide_your:
        "ค่านิยมจะชี้นำ และแสดงให้เห็นถึงนิสัยและความชอบตามธรรมชาติของคุณ เรามักจะทำสิ่งต่างๆที่สอดคล้องกับค่านิยมของเรา ส่วนลักษณะนิสัยนั้น จะเป็นตัวอธิบายตัวตนของเรา",
      In_this_sectio_you_have: "ในหมวดนี้ คุณมีเวลา ",
      minutes: "นาที",
      to_identify_and_select_your:
        "ในการระบุ และเลือกค่านิยม กับลักษณะนิสัยที่สอดคล้องกับตัวตนของคุณ",
      Select_the: "เลือก",
      values_that_best_describe: "ค่านิยมที่สำคัญที่สุดสำหรับคุณ",
      traits_that_best_describe: "ลักษณะนิสัยที่อธิบายคุณได้ดีที่สุด",
      Click_on_a_word_to_add: "คลิกที่คำเพื่อเพิ่มหรือเอาออกจากตัวเลือก",
      // ---
      SELECT: "เลือก",
      MORE_ITEMS: "ตัวเลือกเพิ่มเติม",
      Rated_very_important_by: "ถูกเลือกว่าสำคัญโดย ",
      but_unselected_by: " แต่ไม่ได้ถูกเลือกโดย ",
      Values: "ค่านิยมส่วนบุคคล",
      get_started_message: `โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอบคุณและขอให้โชคดี!`,
      intro:
        "ค่านิยมของคุณถือเป็นแนวทางชี้นำแนวทางการตัดสินใจและการกระทำของคุณ เรามักต้องการที่จะกระทำสิ่งที่สอดคล้องกับค่านิยมที่เรายึดติดเป็นหลักการส่วนตัวเสมอ ลองพิจารณาว่าสิ่งใดเป็นสิ่งที่กระตุ้นให้คุณตัดสินใจและการกระทำของคุณ",
      intro_highlight: "พิจารณาและเลือกค่านิยมส่วนบุคคลที่แท้จริงของคุณ",
    },
    cultureProfile: {
      Culture_Profile: "รูปแบบวัฒนธรรมองค์กร",
      The_Happily_Culture_Profile:
        "รูปแบบวัฒนธรรมองค์กร ที่ MyCulture ใช้นั้นถูกประยุกต์มาจากกรอบแนวคิด การแข่งขันทางค่านิยม (Competing Values Framework) และถูกใช้โดยองค์กรต่าง ๆ มากกว่า 10,000 องค์กรทั่วโลก",
      Which_culture_type_should:
        "วัฒนธรรมรูปแบบใดที่โดดเด่น? คุณอยากให้ความสำคัญในเรื่องการร่วมมือ การควบคุม การแข่งขัน หรือการสร้างสรรค์สิ่งใหม่มากกว่ากัน",
      Takes: "ใช้เวลา",
      minutes: "นาที",
      to_go_evaluate_your_ideal:
        "ในการประเมินองค์กรในอุดมคติของคุณอิงจาก 6 ด้านที่แตกต่างกัน",
      Assessing_each_aspect_divide:
        "การประเมินในแต่ละด้าน แบ่ง 100 คะแนนออกเป็น 4 ตัวเลือก ให้คะแนนสูงสุดสำหรับตัวเลือกที่คล้ายกับองค์กรที่คุณอยากได้มากที่สุด และให้คะแนนลดลงมากับข้อที่ใกล้เคียงกับสิ่งที่คุณต้องการน้อยที่สุด",
      get_started_message: `ก่อนที่เราจะไปต่อในขั้นที่ {step} โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอขอบคุณที่สละเวลาในการเข้าร่วมและขอให้โชคดีในก้าวแรก!`,
      intro: `วัฒนธรรมองค์กรได้รับการดัดแปลงมาจาก Competing Values Framework (CVF) และเป็นที่ได้รับความนิยมในการไปปรับใช้อย่างกว้างขวางจากบริษัททั่วโลกกว่า 10,000 แห่ง 
      คุณคิดว่าวัฒนธรรมองค์กรแบบใดควรมีบทบาทสำคัญที่สุด? คุณอยากเน้นไปที่ด้านใดระหว่าง การทำงานร่วมกัน การควบคุม การแข่งขัน หรือความคิดสร้างสรรค์? ใช้เวลา 5 นาทีเพื่อประเมินถึงองค์กรในอุดมคติของคุณจาก 6 ด้านนี้`,
      Which_best_desribes_your_ideal_organization:
        "ข้อใดเล่าถึงองค์กรในฝันของคุณได้ดีที่สุด?",
      Which_best_desribes_your_ideal_leadership:
        "ข้อใดเล่าถึงผู้นำในฝันของคุณได้ดีที่สุด?",
      Which_best_desribes_the_ideal_role_managers_play:
        "ข้อใดเล่าถึงหัวหน้าในฝันของคุณได้ดีที่สุด?",
      Which_best_desribes_what_ideally_holds_the_organization_together:
        "ในอุดมคติของคุณ ข้อใดเป็นสิ่งที่หลอมรวมองค์กรเอาไว้ให้เป็นหนึ่งเดียว?",
      Which_best_desribes_the_basis_of_your_decisions:
        "ข้อใดอธิบายถึงหลักการที่มีผลต่อการตัดสินใจได้ดีที่สุด?",
      Which_best_desribes_what_success_looks_like:
        "ข้อใดอธิบายถึงคำว่า 'ความสำเร็จ' ได้ดีที่สุด?",
      Which_best_desribes_the_basis_of_how_decisions_should_be_made:
        "ข้อใดอธิบายถึงหลักการที่มีผลต่อการตัดสินใจได้ดีที่สุด",
      Family_Atmosphere: "Family Atmosphere",
      Entrepreneurial_Spirit: "Entrepreneurial Spirit",
      Results_Driven: "Results-Driven",
      Structured_Environment: "Structured Environment",
      Human_Development: "Human Development",
      Opportunity_Exploration: "Opportunity Exploration",
      Competitive_Achievement: "Competitive Achievement",
      Operational_Stability: "Operational Stability",
      Trust_and_Loyalty: "Trust & Loyalty",
      Innovative_Commitment: "Innovative Commitment",
      Achievement_Focus: "Achievement Focus",
      Structured_Stability: "Structured Stability",
      Teamwork_Emphasis: "Teamwork Emphasis",
      Individual_Innovation: "Individual Innovation",
      Competitive_Drive: "Competitive Drive",
      Stable_Employment: "Stable Employment",
      Nurturing_Leadership: "Nurturing Leadership",
      Innovative_Pioneers: "Innovative Pioneers",
      Aggressive_Focus: "Aggressive Focus",
      Efficient_Coordination: "Efficient Coordination",
      People_Centric_Success: "People-Centric Success",
      Innovative_Leadership: "Innovative Leadership",
      Marketplace_Dominance: "Marketplace Dominance",
      Operational_Efficiency: "Operational Efficiency",
    },
    acceptableBehaviors: {
      Acceptable_Behaviors: "พฤติกรรมที่ยอมรับได้",
      Evaluating_Acceptable_Behaviors: "การประเมินพฤติกรรมที่ยอมรับได้",
      In_the_workplace_it_is_not_uncommon:
        "ไม่ใช่เรื่องแปลกที่จะเจอกับพฤติกรรมที่ไม่พึงประสงค์ในที่ทำงาน ซึ่งบางเรื่องเราก็อาจจะอดทนกับมันได้มากกว่าคนอื่น",
      Please_provide_a_rating_for_each:
        "ช่วยจัดระดับให้กับแต่ละสถานการณ์ อ้างอิงจากความพึงพอใจ ระดับความอดทนและยอมได้ของคุณ",
      Acceptable: "ยอมรับได้",
      This_behavior_is_acceptable: "พฤติกรรมนี้ฉันยอมรับได้",
      Frowned_Upon: "ไม่พอใจ/ไม่ยอมรับ",
      This_behavior_is_not_acceptable:
        "พฤติกรรมนี้ไม่เป็นที่ยอมรับ และฉันอารมณ์เสียอย่างเห็นได้ชัดเมื่อมันเกิดขึ้น",
      Actively_Discouraged: "แสดงออกว่าไม่สนับสนุน",
      This_behavior_is_unacceptable_Discouraged:
        "พฤติกรรมนี้ยอมรับไม่ได้ ฉันจะไม่สนับสนุน และพยายามขัดขวางมัน",
      Reprimanded: "ต้องมีการตำหนิหรือลงโทษ",
      This_behavior_is_unacceptable_Reprimanded:
        "พฤติกรรมนี้ยอมรับไม่ได้ ควรจะมีบทลงโทษ และผู้กระทำต้องได้รับผลจากการกระทำนี้",
      Zero_Tolerance_Policy: "ยอมรับไม่ได้อย่างเด็ดขาด",
      This_behavior_is_irreprehensible:
        "พฤติกรรมนี้สมควรได้รับการประณาม และไม่ควรได้รับโอกาสครั้งที่สอง",
      Scenario: "สถานการณ์",
      get_started_message: `ก่อนที่เราจะไปต่อในขั้นที่ {step} โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอขอบคุณที่สละเวลาในการเข้าร่วมและขอให้โชคดีในก้าวแรก!`,
      intro: `เป็นเรื่องปกติที่ต้องเจอกับพฤติกรรมไม่พึงประสงค์ในที่ทำงาน เราแต่ละคนก็มีระดับความอดทนที่แตกต่างกันออกไป ขอให้ใช้เวลา 5 นาทีเพื่อให้คะแนนสำหรับแต่ละสถานการณ์โดยอิงตามความความรู้สึกและระดับความอดทนของคุณ`,
    },
    workStyleIdentifier: {
      Work_Style_Identifier: "ค้นหาสไตล์การทำงาน",
      identify_your_work_style:
        "ค้นหาสไตล์การทำงานของคุณโดยอิงจากหลัก 3P ที่ย่อมาจาก Product(ผลลัพธ์) Process(ขั้นตอน) และ People(ผู้คน) เพื่อให้เกิดความเข้าใจที่ดีขึ้น ว่าคุณมีวิธีในการจบงานและลำดับเรื่องที่คุณให้ความสำคัญในการทำงานอย่างไร",
      complete_each_workplace_scenario:
        "ตอบคำถามผ่านสถานการณ์สมมติในที่ทำงาน โดยเลือกจากการกระทำหลัก ลำดับความสำคัญ หรือความชอบของคุณ",
      Based_on_your_natural_tendencies:
        "โดยปกติแล้วคุณมีแนวโน้มที่จะตอบสนอง หรือปฏิบัติตัวอย่างไร ในสถานการณ์สมมตินี้",
      SCENARIO: "สถานการณ์",
      Scenario: "สถานการณ์",
      get_started_message: `ก่อนที่เราจะไปต่อในขั้นที่ {step} โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอขอบคุณที่สละเวลาในการเข้าร่วมและขอให้โชคดีในก้าวแรก!`,
      intro: `ค้นหาสไตล์การทำงานของคุณโดยอิงจากหลัก 3P ที่ย่อมาจาก Product(ผลลัพธ์) Process(ขั้นตอน) และ People(ผู้คน) เพื่อให้เกิดความเข้าใจที่ดีขึ้น ว่าคุณมีวิธีในการจบงานและลำดับเรื่องที่คุณให้ความสำคัญในการทำงานอย่างไร ตอบคำถามผ่านสถานการณ์สมมติในที่ทำงาน โดยเลือกจากการกระทำหลัก ลำดับความสำคัญ หรือความชอบของคุณ`,
    },
    humanSkills: {
      get_started_message: `ก่อนที่เราจะไปต่อในขั้นที่ {step} โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอขอบคุณที่สละเวลาในการเข้าร่วมและขอให้โชคดีในก้าวแรก!`,
      intro: `แสดงทักษะด้านบุคคลของคุณ ไม่ว่าจะเป็นทักษะการสื่อสาร ความเห็นอกเห็นใจ การอ่านสถานการณ์ ตลอดจนการคิดวิเคราะห์เชิงวิพากษ์ ผ่านการตอบคำถามปลายเปิดเหล่านี้่ด้วยความสามารถที่ดีที่สุดของคุณ ให้ใช้การคิดวิเคราะห์และแบ่งปันกระบวนการคิดของคุณพร้อมกับยกตัวอย่างประกอบเพื่ออธิบายให้ชัดเจนยิ่งขึ้น`,
      Showcase_your_communication_ability: "โปรดตอบคำถามเหล่านี้",
      // ---
      The_best_of_your: "อย่างสุดความสามารถ",
      Be_analytical_by_sharing:
        "เพื่อแสดงทักษะในการสื่อสาร ความเข้าอกเข้าใจ การตระหนักรู้ถึงสถานการณ์ และการคิดวิเคราะห์ โดยอธิบายถึงกระบวนการทางความคิดของคุณ และยกตัวอย่างเพื่อความชัดเจน",
      You_will_have_ten_min: "คุณมีเวลาทั้งสิ้น 10 นาทีในการตอบคำถามทั้งสองข้อ",
      // ---
      Write_your_response: "เขียนคำตอบของคุณที่นี่...",
      Your_answer: "คำตอบของคุณ",
      Write_your_answer_here: "เขียนคำตอบของคุณที่นี่",
    },
    logicTest: {
      Logic_Test: "แบบทดสอบตรรกะ",
      intro:
        "แบบทดสอบนี้จะประเมินทักษะของคุณในด้านการหารูปแบบความสัมพันธ์ การใช้เหตุผลแบบนิรนัย และการแก้ปัญหา โปรดใส่ใจรายละเอียดและคิดวิเคราะห์โดยใช้หลักเหตุผล แบบทดสอบนี้มีการจับเวลา สามารถวัดได้ทั้งความแม่นยำและประสิทธิภาพ โดยจะแบ่งออกเป็น 4 ส่วน",
      Section: "ส่วน",
      Question: "คำถาม",
      get_started_message: `โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b>ในการดำเนินการขั้นตอนนี้ นาฬิกาจะเริ่มจับเวลาทันทีเมื่อคลิกที่ปุ่ม <b>"เริ่ม"</b> ดังนั้น กรุณาจัดสรรเวลาให้เพียงพอเพื่อที่จะทำให้ขั้นตอนนี้เสร็จสมบูรณ์โดยไม่มีอะไรมารบกวน ขอบคุณและขอให้โชคดี!`,
      question_n: "คำถาม {n}",
      question_button_label: "{name} ({point} คะแนน)",
      answer_n:
        "<span style='font-size: 12px; font-weight: 700; color: {color}'>คำตอบ</span>: {n})",
      correct_answer_n:
        "<span style='font-size: 12px; font-weight: 700; color: {color}'>คำตอบที่ถูกต้อง</span>: {n})",
      sum_score_each_section_n: "{sum_point} out of {max_point} ({percent}%)",
    },
    customSurvey: {
      Custom_Surveys: "Custom Survey",
      intro:
        "แสดงความสามารถในการสื่อสาร ความเห็นอกเห็นใจ ความตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ของคุณโดยตอบคำถามในแบบสำรวจต่อไปนี้ วิเคราะห์โดยแบ่งปันกระบวนการคิดของคุณ",
      get_started_message: `โปรดทราบว่าคุณมีเวลา <b>{minute} นาที</b> ในการดำเนินการขั้นตอนนี้ให้เสร็จสิ้น ตัวจับเวลาจะเริ่มนับเมื่อคุณคลิกปุ่ม <b>"เริ่ม"</b> โปรดแน่ใจว่าคุณมีเวลาเพียงพอในการดำเนินการขั้นตอนนี้ให้เสร็จสิ้นโดยไม่มีการรบกวน
      Thank you good luck!`,
      Set_up_Custom_Survey: "ตั้งค่าการสำรวจแบบกำหนดเอง",
      Setup_your_Custom_Survey: "ตั้งค่าการสำรวจแบบกำหนดเองของคุณ",
      Question_Block: "บล็อกคำถาม",
      Add_your_own_set_of_questions:
        "เพิ่มชุดคำถามของคุณเองที่ช่วยให้คุณรวบรวมข้อมูลที่จำเป็นและข้อเสนอแนะ",
      Title_of_Section: "หัวเรื่องของหมวด",
      Description_optional: "คำอธิบาย (ไม่บังคับ)",
      Short_Description: "คำอธิบายสั้นๆ",
    },
  },
  assessmentTest: {
    Hello: "สวัสดี",
    minutes_remaining_defore: "เหลือเวลาอีก",
    minutes_remaining_after: "นาที",
    Thank_you_for_taking: "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบนี้กับเรา",
    // ---
    Thank_you_for_taking_end: "จนจบ",
    A_few_things_you_should: "เกร็ดเล็กน้อยที่ควรรู้ก่อนเริ่ม:",
    Plan_to_take: "ควรวางแผนในการทำแบบทดสอบนี้ โดยใช้เวลา ",
    minutes_to_complete_this: " นาทีต่อเนื่อง ไม่ให้มีการรบกวนใดๆ",
    // ---
    It_is_in_your_best_interest:
      "เพื่อประโยชน์ของคุณเอง ให้ตอบตามสิ่งที่คุณคิดจริงๆ",
    There_is_no_right: "แบบทดสอบนี้ไม่มีคำตอบที่ถูกหรือผิด",
    If_you_re_unsure: "ถ้าไม่มั่นใจว่าจะตอบอะไร ให้เชื่อสัญชาตญาณของตัวเอง",
    Thank_you_for_taking_to_complete:
      "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบในหมวดรูปแบบวัฒนธรรมองค์กรกับเรา",
    // ---
    Thank_you_for_taking_to_complete_the_assessment:
      "ขอบคุณที่สละเวลาเพื่อทำแบบทดสอบในหมวดโปรไฟล์วัฒนธรรมกับเรา",
    section_of_the_assessment: "กับเรา",
    You_ve_completed_this_section: "ทำหมวดนี้เสร็จแล้ว",
    You_ve_completed_all_section: "ทำเสร็จสิ้นครบทุกหมวดแล้ว",
    You_have_completed_all_sections:
      "คุณทำแบบทดสอบครบทุกหมวดแล้ว สามารถปิดหน้าต่างนี้ได้",
    Once_you_re_ready: "เมื่อคุณพร้อมแล้ว โปรดไปยังหมวดถัดไป",
    // ---
    Go_to_the_Next_Section: "ไปยังหมวดถัดไป",
    POINTS_REMAINING: "คะแนนที่เหลืออยู่",
    Optional: "ไม่บังคับ",
    Anyz_feedback_or_thoughts: "ข้อเสนอแนะหรือความคิดที่จะแบ่งปันเกี่ยวกับ ",
    values_and_traits_q: "ค่านิยมและลักษณะนิสัย",
    culture_profile_q: "รูปแบบวัฒนธรรมองค์กร",
    acceptable_behaviors_q: "พฤติกรรมที่ยอมรับได้",
    work_style_identifier_q: "สิ่งที่บ่งบอกสไตล์การทำงาน",
    human_skills_q: "ทักษะด้านมนุษยสัมพันธ์",
    Describe_a_time_when_you:
      "เล่าให้ฟังหน่อยว่าเมื่อคุณต้องแก้ปัญหาที่ซับซ้อนในงานของคุณ คุณมีกระบวนการคิดยังไง และคุณหาทางออกของปัญหาได้ยังไง?",
    Imagine_you_are_working_on:
      "สมมติว่าคุณกำลังทำโปรเจ็กต์ร่วมกับทีมโดยมีเวลากระชั้นชิด และคุณพบข้อบกพร่องสำคัญในการออกแบบโครงการ  คุณจะแก้ปัญหาด้วยขั้นตอนอะไรบ้าง และจะทำอย่างไรเพื่อให้มั่นใจว่างานยังคงเป็นไปตามแผนที่วางไว้",
  },
  assessmentResults: {
    Pattern_Recognition: "การรับรู้รูปแบบ",
    Logical_Reasoning: "เหตุผลเชิงตรรกะ",
    Data_Interpretation: "การตีความข้อมูล",
    Problem_Resolving: "การแก้ไขปัญหา",
    trend_analysis: "การวิเคราะห์แนวโน้ม",
    decision_making: "การตัดสินใจ",
    business_intelligence: "การตีความข้อมูลเชิงธุรกิจ",
    strategic_planning: "การวางแผนเชิงกลยุทธ์",
    pattern_recognition_detail:
      "ความสามารถในการแยกวิเคราะห์และประยุกต์รูปแบบที่ซับซ้อน ซึ่งเป็นสิ่งสำคัญในการระบุแนวโน้ม ข้อผิดพลาด หรือความผิดปกติในชุดข้อมูลต่างๆ",
    logical_reasoning_detail:
      "ความสามารถในการวิเคราะห์สถานการณ์และอนุมานผลลัพธ์เชิงตรรกะ ซึ่งมีความสำคัญต่อการวิเคราะห์ปัญหาและการตัดสินใจอย่างชาญฉลาดในสถานการณ์ที่ซับซ้อน",
    data_interpretation_detail:
      "ความสามารถในการตีความและดึงผลสรุปจากข้อมูลกราฟิก เป็นทักษะสำคัญที่จำเป็นในหลายบทบาทที่ต้องใช้การวิเคราะห์ข้อมูลและการตัดสินใจเชิงกลยุทธ์",
    problem_pesolving_detail:
      "ความสามารถในการแก้ปัญหาโดยใช้การคิดวิเคราะห์ เป็นทักษะสำคัญที่ช่วยให้สามารถรับมือกับอุปสรรคและใช้ทรัพยากรอย่างมีประสิทธิภาพในที่ทำงาน",
    A_logic_test_profile_for:
      "การซ้อนทับคะแนนตรรกะของผู้สมัครในสี่ส่วนที่ทดสอบทักษะที่จำเป็นต่อการแก้ปัญหาและการตัดสินใจอย่างมีประสิทธิภาพ",
    Overall: "ภาพรวม",
    Create: "การสร้าง (Create)",
    Compete: "การแข่งขัน (Compete)",
    Control: "การควบคุม (Control)",
    Collaborate: "ความร่วมมือ (Collaborate)",
    people_oriented: "ให้ความสำคัญกับผู้คน (people-oriented)",
    dynamic_entrepreneurial:
      "ความเป็นพลวัต และการเป็นผู้ประกอบการ (dynamic & entrepreneurial)",
    results_oriented: "ให้ความสำคัญกับเป้าหมาย (results-oriented)",
    process_oriented: "ให้ความสำคัญกับกระบวนการ (process-oriented)",
    Values: "ค่านิยม",
    and_: "และ",
    Traits: "ลักษณะนิสัย",
    Culture_: "รูปแบบ",
    Profile_: "วัฒนธรรมองค์กร",
    Acceptable_: "พฤติกรรมที่",
    Behaviors_: "ยอมรับได้",
    COPY_LINK: "คัดลอกลิงก์",
    SEND_RESULTS_TO: "ส่งผลทดสอบไปยัง",
    RE_SEND_RESULTS_TO: "ส่งผลทดสอบอีกครั้งไปยัง",
    Completed_on: "เสร็จสิ้นเมื่อ",
    Score_Summary: "ผลสรุปคะแนน",
    Values_alignment: "ความสอดคล้องของค่านิยม",
    Dominant_Characteristics: "ลักษณะเด่น",
    Organizational_Glue: "ตัวประสานองค์กร",
    Organizational_Leadership: "ภาวะผู้นำขององค์กร",
    Strategic_Emphases: "แน่นหนักกลยุทธ์",
    Management_of_Employees: "การจัดการพนักงาน",
    Criteria_of_Success: "เกณฑ์ความสำเร็จ",
    Step_desc: `จัดสรร 100 คะแนนในสี่ประเภทตามความคล้ายคลึงกับองค์กรของคุณ โดยให้คะแนนมากขึ้นสำหรับประเภทที่คล้ายกันมากที่สุด`,
    Written_Communication: "การสื่อสารที่เป็นลายลักษณ์อักษร",
    Empathy: "ความเห็นอกเห็นใจ",
    Situational_Awareness: "การตระหนักรู้สถานการณ์",
    Critical_Thinking: "การคิดวิเคราะห์",
    importance: "สถานการณ์",
    Scenario: "ที่สำคัญ",
    selection_of_the_candidate_values:
      "ส่วนนี้คือค่านิยมของผู้ทำแบบทดสอบ โดยแบ่งสีตามลำดับความสำคัญซึ่งวัดจากค่านิยมขององค์กร",
    Our_values_guide_our:
      "ค่านิยมของเราเป็นตัวชี้นำพฤติกรรมตามธรรมชาติ วิธีการทำงาน การกระทำ และการตัดสินใจของเรา",
    This_is_the_selection:
      "ส่วนนี้คือลักษณะนิสัยของผู้ทำแบบทดสอบ โดยแบ่งสีตามลำดับความสำคัญของลักษณะนิสัยในอุดมคติขององค์กร",
    Our_traits_guide_give:
      "ลักษณะนิสัยของเรานั้นจะบ่งบอกและช่วยให้เราสามารถแสดงออกหรือปฏิบัติตัวได้ตามค่านิยมที่เรามี",
    The_overall_overlap_between:
      "ภาพรวมความทับซ้อนระหว่างวัฒนธรรมองค์กรในอุดมคติของผู้ทำแบบทดสอบกับวัฒนธรรมองค์กรของคุณ",
    Hover_over_each_dimension: "เลื่อนเมาส์ไปที่แต่ละมิติเพื่อดู",
    how_each_value_is: "ว่าแต่ละค่านิยมนั้นปรากฎอย่างไรในส่วนนี้",
    A_comparison_between_what:
      "การเปรียบเทียบระหว่างสิ่งที่ผู้ทำแบบทดสอบรู้สึกว่าเป็นพฤติกรรมที่ยอมรับได้ กับสิ่งที่องค์กรยอมรับได้ หากมีส่วนที่ไม่ตรงกัน เราแนะนำว่าควรพูดคุยเพื่อให้เข้าใจความคาดหวังตรงกัน",
    The_candidate_primary_work_style:
      "สไตล์การทำงานหลักของผู้ทำแบบทดสอบจะทำให้เห็นวิธีการทำงานอันเป็นธรรมชาติของพวกเขา ไม่ใช่การบ่งบอกถึงข้อจำกัด เพียงแต่อธิบายว่าพวกเขามักจะชอบทำงานอย่างไร และทำงานกับผู้อื่นอย่างไร",
    Work_Style__: "สัดส่วนของ",
    Composition__: "สไตล์การทำงาน",
    Product: "ผลลัพธ์ (Product)",
    Doers_and_executors_They:
      "เป็นนักปฏิบัติและผู้บริหาร พวกเขาขับเคลื่อนชีวิตด้วยการทำงานให้สำเร็จลุล่วง แต่พวกเขาก็มักจะมองข้ามรายละเอียด และอารมณ์ความรู้สึกของคนอื่นๆ ในระหว่างการทำงาน",
    Process: "ขั้นตอน (Process)",
    Learners_and_thinkers_They:
      "นักคิด เรียนรู้และวางแผน พวกเขาเก่งกาจที่สุดในการคิดวิเคราะห์อย่างถี่ถ้วน และเน้นย้ำรายละเอียดในการจะทำอะไรให้สำเร็จ แต่พวกเขามักจะใช้เวลามากเกินไปในการทำให้ทุกอย่างออกมาสมบูรณ์แบบ แทนที่จะทำให้มันเสร็จในเวลา หรือใส่ใจดูแลผู้อื่นมากกว่านี้",
    People: "ผู้คน (People)",
    Relationship_builders_They:
      "นักสร้างสัมพันธ์ พวกเขาเข้าใจผู้คน และรู้ว่าจะดึงศักยภาพสูงสุดจากคนอื่นๆ มาได้อย่างไร แต่พวกเขามักจะใช้เวลามากเกินไปเพื่อทำให้คนอื่นรู้สึกดี แทนที่จะสนใจรายละเอียดอื่นๆ หรือทำงานให้เสร็จ",
    Select_a_dimension_to: "เลือกมิติเพื่อเรียนรู้เพิ่มเติม",
    Tap_on_a_row_in_the:
      "กดที่แถวด้านซ้ายเพื่อดูความสอดคล้องกันระหว่างคำตอบของผู้ทำแบบทดสอบและขององค์กร",
    Group_Results: "ผลลัพธ์แบบกลุ่ม",
    Analytics: "การวิเคราะห์",
    Legend: "สัญลักษณ์แสดงข้อมูลรายบุคคล",
    The_overlap_between_the__workplace:
      "ความทับซ้อนกันระหว่างที่ทำงานในอุดมคติของผู้ทำแบบทดสอบและสิ่งที่องค์กรของคุณเป็น",
    A_human_skills_profile_for:
      "ทักษะด้านมนุษยสัมพันธ์ของผู้สมัครแต่ละคนจะวัดจากทักษะการเขียนเพื่อสื่อสาร การเอาใจใส่ การตระหนักรู้ถึงสถานการณ์ และการคิดเชิงวิพากษ์",
    There_are_no_values_assigned:
      "ไม่มีค่านิยมที่ถูกเลือกไว้ในความสำคัญระดับนี้",
    Progress: "ความคืบหน้า",
    Assessment_Results_for: "ผลทดสอบสำหรับ",
    Human_: "ทักษะ",
    Skills_: "ด้านมนุษยสัมพันธ์",
    The_candidate_human_skills_are:
      "ทักษะด้านมนุษยสัมพันธ์ของผู้ทำแบบทดสอบจะถูกประเมินจากการตอบคำถามปลายเปิด โดยเรามีการประเมินการเขียนสื่อสาร ความเห็นอกเห็นใจ การตระหนักรู้ในสถานการณ์ และการคิดวิเคราะห์ รวมถึงวิธีการแสดงออกผ่านค่านิยม (การประเมินด้านค่านิยมนั้นจะไม่ถูกนำมารวมคะแนน แต่จะแสดงเพื่อหาความสอดคล้องกันเท่านั้น)",
    Completion_Time: "เวลาในการทำแบบสอบถาม",
    Values_of: "ค่านิยมของ $1",
    values_info: `ความเข้ากันกับค่านิยมของ $1`,
    Analysis: "ผลวิเคราะห์",
    Strong_Alignment: "ผลวิเคราะห์ที่แข็งแกร่ง",
    Weaker_Alignment: "ผลวิเคราะห์ที่อ่อนแอกว่า",
    Possible_Conflict: "ความขัดแย้งที่อาจเกิดขึ้น",
    analysis_result: `ค่านิยมของผู้สมัครสอดคล้องอย่างยิ่งกับหลักของบริษัทและค่านิยมในการอนุญาตให้เล่น โดยเฉพาะอย่างยิ่งในด้านต่างๆ เช่น ความสามารถในการปรับตัว จิตวิญญาณของผู้ประกอบการ ความเป็นเลิศ และความเปิดกว้าง ซึ่งอาจเป็นประโยชน์สำหรับการบูรณาการและการมีส่วนร่วมภายในองค์กร อย่างไรก็ตาม มีความคลาดเคลื่อนอย่างเห็นได้ชัดเกี่ยวกับความมั่งคั่ง ผู้สมัครให้คุณค่าอย่างมีนัยสำคัญในขณะที่องค์กรพิจารณาว่าเป็นคุณค่าที่ไม่จำเป็น ซึ่งบ่งชี้ถึงความขัดแย้งทางผลประโยชน์หรือแรงจูงใจที่อาจเกิดขึ้น ความแตกต่างนี้จะต้องมีการพูดคุยและแก้ไขอย่างเปิดเผยเพื่อให้แน่ใจว่าความทะเยอทะยานส่วนตัวของผู้สมัครสอดคล้องกับวัตถุประสงค์ของบริษัทและภารกิจโดยรวม`,
    Company_Values: `นี่คือการเลือกค่านิยมของ $1 ที่จัดหมวดหมู่ตามความสำคัญในการชี้แนะการดำเนินการและการตัดสินใจที่นี่`,
    Core_Values: "ค่านิยมหลัก",
    Core_Values_desc:
      "ค่านิยมเฉพาะตัวที่บ่งบอกความเป็นองค์กรของคุณ และทำให้องค์กรของคุณแตกต่างจากที่อื่น",
    Permission_to_play_values: "ค่านิยมอันพึงประสงค์",
    Permission_to_play_values_desc:
      "ค่านิยมพื้นฐานที่องค์กรของคุณควรยึดถือไว้ เป็นสิ่งที่จำเป็นแต่ไม่ได้เป็นเอกลักษณ์เฉพาะคุณ",
    Non_essential_values: "ค่านิยมที่ไม่สำคัญ",
    Non_essential_values_desc:
      "เป็นค่านิยมที่ไม่ได้มีอิทธิพลขับเคลื่อนการกระทำและการตัดสินใจที่เกิดขึ้นในองค์กร",
    Acceptable_Behaviors_Info: `เป็นการนำชุดพฤติกรรมที่ผู้ทำแบบทดสอบรู้สึกว่ายอมรับได้หรือไม่ได้ เทียบกับฝั่งขององค์กร หากมีส่วนที่ไม่ตรงกัน เราแนะนำว่าควรพูดคุยเพื่อให้เข้าใจความคาดหวังตรงกัน`,
  },
  coreValuesAnalyzer: {
    My_Core_Values: "ค่านิยมของฉัน",
    Audit: "วิเคราะห์ค่านิยม",
    Skills: "ทักษะ",
    Survey_Question: "คำถามแบบทดสอบ",
    Microlearning: "เกร็ดความรู้",
    Clarity: "ความชัดเจน",
    Practical: "สามารถทำได้จริง",
    Best_Practice: "ตรงตามแบบอย่างที่ดี",
    Inspiring: "เป็นแรงบันดาลใจ",
    Audit_Summary: "ผลสรุปการวิเคราะห์",
    Purpose_driven: "ให้ความสำคัญกับจุดประสงค์ (Purpose-driven)", // ทำตามเป้าหมาย
    Alternative_Options: "ตัวเลือกเพิ่มเติม",
    Auditing_you_values: "กำลังวิเคราะห์ค่านิยมของคุณ...",
    Overall: "ภาพรวม",
    Simple_Language: "ภาษาเข้าใจง่าย",
    Potential_Misunderstanding: "โอกาสที่จะเข้าใจผิด",
    Consistency: "ความสอดคล้องกัน",
    Actionable: "ปฏิบัติได้จริง",
    Easy_To_Reinforce: "กระตุ้นได้ง่าย",
    Measureable: "วัดผลได้จริง",
    Not_Permission_To_Play: "ไม่ใช่สิ่งพื้นฐาน",
    Sense_Of_Pride: "ความภาคภูมิใจ",
    Positive_Impact: "ผลกระทบเชิงบวก",
    Analyze_everything_you:
      "เราจะช่วยวิเคราะห์ทุกอย่างที่คุณต้องรู้เกี่ยวกับค่านิยมองค์กร ให้คำแนะนำในทักษะที่ต้องพัฒนา คำถามที่ควรถาม และข้อมูลที่ควรเผยแพร่",
    Enter_from_1_to: `ระบุค่านิยมหลักของคุณ (สูงสุด 8 ค่านิยม)`,
    Analyze: "วิเคราะห์",
    Analyzing: "กำลังวิเคราะห์",
    What_you_get: "คุณจะได้รับ",
    Audit_your_core_values: "การวิเคราะห์ค่านิยม",
    Analyze_how_well_your:
      "เราจะช่วยวิเคราะห์ และให้คะแนนค่านิยมของคุณในเรื่องความชัดเจน การนำไปใช้จริง ความตรงตามแบบอย่างที่ดี และความสามารถในการจูงใจ",
    Ratings_recommendations_and:
      "โดยมีการให้คะแนน มอบคำแนะนำ และเสนอค่านิยมทางเลือก เพื่อช่วยพัฒนาค่านิยมองค์กรของคุณ",
    Get_a_Skills_Development: "แผนพัฒนาทักษะ",
    Get_a_skill_evelopment:
      "เราจะแนะนำวิธีพัฒนาทักษะที่จะช่วยให้พนักงานของคุณสามารถแสดงออก และปฏิบัติตามค่านิยมของคุณได้",
    Leaders_an_encourage_and:
      "อย่างไรก็ตามผู้นำองค์กรควรจะสนับสนุน โดยการมอบรางวัลให้กับพนักงานที่ปฏิบัติตามค่านิยมดังกล่าว สร้างโอกาสให้พนักงานสามารถฝึกฝน และพัฒนาทักษะเหล่านี้ได้",
    Generate_Survey_Questions: "สร้างคำถามสำหรับการสำรวจ",
    Help_your_employees_regularly:
      "คุณสามารถช่วยให้พนักงานตกตะกอน และแบ่งปันฟีดแบ็กอย่างสม่ำเสมอตามค่านิยมองค์กร โดยเพิ่มคำถามที่เราเสนอแนะไปในแบบสอบถาม หรือเครื่องมือการมอบฟีดแบ็กที่คุณเลือกใช้",
    Learning_Content_to_Support: "ชุดความรู้เพื่อส่งเสริมค่านิยม",
    // --
    Get_micro_learning_content:
      "เรามอบเกร็ดความรู้ (Micro-learning) เพื่อให้คุณสามารถนำไปเผยแพร่ผ่านช่องทางต่างๆ เช่น อีเมล เว็บไซต์องค์กร สื่อโซเซียล หรือ ระบบการจัดการการเรียนรู้",
    Short_and_easily_digestible:
      "โดยเราทำให้สั้น และย่อยได้ง่าย ทำให้พนักงานสามารถตกตะกอน และพัฒนาตนตามค่านิยมได้อย่างง่ายดายและรวดเร็ว",
    Skills_and_Development_Guidance: "ทักษะที่ควรพัฒนา",
    In_order_for_your:
      "เพื่อให้พนักงานสามารถแสดงออก และแสดงความจริงใจต่อค่านิยมองค์กร พวกเขาต้องมี หรือพัฒนาทักษะเหล่านี้อย่างต่อเนื่อง",
    By_veloping_and_supporting:
      "การพัฒนา และส่งเสริมทักษะเหล่านี้ จะทำให้พนักงานสามารถแสดงออก และปฏิบัติตามค่านิยมด้าน",
    Leaders_can_encourage_and:
      "ผู้นำองค์กรควรจะสนับสนุน และมอบรางวัลให้กับพนักงานที่ปฏิบัติตามค่านิยมดังกล่าว และสร้างโอกาสให้พนักงานสามารถฝึกฝน และพัฒนาทักษะเหล่านี้ได้",
    Questions: "คำถาม",
    Regularly_get_your_employees:
      "ลองเพิ่มคำถามเหล่านี้ไปในแบบสอบถาม หรือเครื่องมือการมอบฟีดแบ็กที่คุณเลือกใช้ เพื่อให้พนักงานได้ตกตะกอน และแบ่งปันฟีดแบ็กอย่างสม่ำเสมอ",
    These_micro_learning_content:
      "เกร็ดความรู้ (Micro-learning) นั้นถูกสร้างมาให้กระชับ และย่อยง่าย เพื่อให้พนักงานสามารถตกตะกอน และพัฒนาตามแบบอย่างค่านิยมองค์กรได้อย่างง่ายดายและรวดเร็ว  โดยคุณสามารถเอาไปแชร์ได้หลากหลายช่องทาง เช่น อีเมล เว็บไซต์องค์กร สื่อโซเซียล หรือ ระบบการจัดการการเรียนรู้",
    Generate_more_content: "สร้างเพิ่ม",
    Create_an_account_to:
      "สร้างบัญชีเพื่อปลดล็อค ทักษะ คำถามแบบสอบถาม และ เกร็ดความรู้",
    Download_This_Report: "ดาวน์โหลดรายงานนี้",
    Download_to_save: "บันทึกการตรวจสอบและวิเคราะห์ค่านิยมหลักนี้เป็นไฟล์ PDF",
    Download: "ดาวน์โหลด",
    Download_pdf_title:
      "รายงานค่านิยมหลัก: การตรวจสอบ คำแนะนำด้านทักษะและการพัฒนา คำถามแบบสำรวจ และเกร็ดความรู้",
    Download_pdf_success: "กำลังเตรียมการดาวน์โหลดรายงาน...",
  },
  headerTable: {
    NAME: "ชื่อ",
    CANDIDATES: "ผู้ทำแบบทดสอบ",
    PROGRESS: "ความคืบหน้า",
    LAST_ACTIVITY: "กิจกรรมล่าสุด",
    DATE_CREATED: "วันที่ถูกสร้าง",
    ACTIONS: "ตัวเลือกเพิ่มเติม",
    JOINED: "เข้าร่วมเมื่อ",
    SCORE: "คะแนน",
    STATUS: "สถานะ",
    YOUR_RATING: "ระดับดาว",
  },
  dataTable: {
    Invite_your_first: "เพิ่มผู้สมัครหรือเพื่อนร่วมทีมคนแรก",
    Send_invitations: "เชิญผ่านอีเมลหรือแชร์ลิงก์",
  },
  popup: {
    optional: {
      Yes: "ตกลง",
      Yes_chi: "ใช่",
      Confirm: "ยืนยัน",
      Cancel: "ยกเลิก",
      Continue: "ต่อไป",
      Go_to_Next_Section: "Go to Next Section",
      OK: "ตกลง",
      Got_it: "เข้าใจแล้ว",
      Confirm_Delete: "ยืนยันการลบ",
    },
    question: {
      question_box_1: {
        title: "ส่งผลทดสอบให้ ",
        question_1: "เราจะส่งลิงค์ส่วนตัวเพื่อเข้าถึงผลลัพธ์แบบทดสอบ ให้กับ  ",
        question_2:
          " ทางอีเมล ภายใต้ชื่อของคุณ คุณต้องการดำเนินการต่อใช่หรือไม่",
      },
      question_box_2: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะลบแบบทดสอบนี้หรือไม่",
      },
      question_box_3: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะลบผู้ทำแบบทดสอบคนนี้หรือไม่",
      },
      question_box_4: {
        title: "การกำหนดค่า",
        question: "คุณต้องการแก้ไขการกำหนดค่าปัจจุบันของคุณหรือไม่",
      },
      question_box_5: {
        title: "คำเตือน",
        question: "คุณแน่ใจรึเปล่าว่าต้องการนำสถานการณ์นี้ออกไปจากแบบทดสอบ",
        // ---
      },
      save_team: {
        success_msg: "ทีมได้ถูกแก้ไขเรียบร้อยแล้ว",
      },
      delete_team_success: {
        success_msg: "ทีมได้ถูกลบเรียบร้อยแล้ว",
      },
      add_member: {
        success_msg: "สมาชิกได้ถูกเพิ่มเรียบร้อยแล้ว",
      },
      change_role: {
        success_msg: "สมาชิกได้ถูกแก้ไขเรียบร้อยแล้ว",
      },
      remove_member: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะลบสมาชิกคนนี้หรือไม่",
        success_msg: "สมาชิกได้ถูกลบเรียบร้อยแล้ว",
      },
      leave_team: {
        title: "คำเตือน",
        question: "คุณแน่ใจที่จะออกจากทีมหรือไม่",
        success_msg: "คุณได้ออกจากทีมแล้ว",
      },
      cannot_be_admin: {
        title: "คำเตือน",
        question: "คุณแน่ใจหรือไม่ว่าต้องการลบตัวเองออกจากผู้ดูแลระบบ",
      },
      delete_team: {
        title: "ลบทีม {teamName}",
        question: `คุณแน่ใจหรือไม่ว่าต้องการลบทีมนี้? เมื่อลบแล้ว การประเมินทีมและผลลัพธ์ทั้งหมดจะไม่สามารถดูได้โดยสมาชิกในทีมอีกต่อไป

        <br /><br />โปรดดำเนินการด้วยความระมัดระวัง`,
      },
      clear_results: {
        title: "ล้างผลลัพธ์",
        question: `คุณแน่ใจหรือไม่ว่าต้องการล้างผลลัพธ์ทั้งหมดเพื่อให้ผู้สมัครต้องทำการประเมินใหม่ตั้งแต่ต้น?`,
      },
    },
    alert: {
      alert_box_1: {
        title: "หมดเวลาแล้ว",
        description:
          "เวลาสำหรับส่วนนี้ได้หมดลงแล้ว โปรดดำเนินการไปขั้นตอนถัดไป",
      },
      alert_box_2: {
        title: "เซสชันหมดเวลา",
        description:
          "โปรดไปต่อที่ส่วนถัดไปของแบบทดสอบ เพื่อส่งแบบทดสอบ มิเช่นนั้น คำตอบของคุณอาจเสี่ยงที่จะหาย และคุณอาจจะต้องทำแบบทดสอบอีกรอบ",
      },
      question_box_5: {
        title: "คำเตือน",
        question: "คุณแน่ใจรึเปล่าว่าต้องการนำสถานการณ์นี้ออกไปจากแบบทดสอบ",
        // ---
      },
      cannot_remove_last_admin: {
        title: "Warning",
        message:
          "ต้องมีผู้ดูแลระบบหนึ่งคนต่อทีม โปรดมอบหมายให้สมาชิกคนอื่นเป็นผู้ดูแลระบบก่อนดำเนินการต่อ",
      },
      cannot_add_duplicated_member: {
        title: "Warning",
        message: "มีสมาชิกนี้แล้ว",
      },
    },
    message: {
      message_1: "คุณได้ส่งผลลัพธ์แบบทดสอบแล้ว",
      message_2: "อีเมลเชิญได้ถูกส่งให้กับ  ",
      message_3_1: "ผลลัพธ์แบบทดสอบของ ",
      message_3_2: " ได้ถูกลบออกเรียบร้อย",
      message_4: "ไม่พบขอมูล!",
      message_5e: "รูปภาพต้องเป็นไฟล์ JPG หรือ PNG เท่านั้น",
      message_6e: "รูปภาพนี้มีขนาดใหญ่เกิน 2MB",
      message_7e: "อีเมล หรือ OTP ไม่ถูกต้อง",
      message_8: "คัดลอกไปยังคลิปบอร์ด",
      message_9e: "เหลือ 0 ในเดือนนี้",
      message_10e: "จำนวนอีเมลที่สามารถส่งได้สูงสุดคือ ",
      message_11: "ส่งอีเมลแล้ว",
      message_12: "ไม่พบขอมูล!",
      message_13_1: "ส่งอีเมลไปยัง ",
      message_13_2: " แล้ว ",
      message_14w: "ขั้นต่ำ 1 หมวด!",
      message_15w: "โปรดกำหนดค่าให้สำเร็จ",
      message_16e: "อุ้บส์ มีบางอย่างผิดพลาด",
      message_17: "คัดลอก Referral Link ไปยังคลิปบอร์ด",
      message_18: "บักทึก ข้อมูลผู้ใช้งานได้ถูกอัปเดตแล้ว",
      message_18b: "บันทึก ข้อมูลบริษัทได้ถูกอัปเดตแล้ว",
      message_19: "ลิงก์ถูกคัดลอกไปยังคลิปบอร์ดของคุณแล้ว",
      message_20w: "คุณสามารถมีผู้รับได้สูงสุด 100 คน",
    },
  },
  datetime: {
    months: {
      January: "มกราคม",
      February: "กุมภาพันธ์",
      March: "มีนาคม",
      April: "เมษายน",
      May: "พฤษภาคม",
      June: "มิถุนายน",
      July: "กรกฎาคม",
      August: "สิงหาคม",
      September: "กันยายน",
      October: "ตุลาคม",
      November: "พฤศจิกายน",
      December: "ธันวาคม",
    },
  },
  coupon: {
    error_messages: {
      message_1: "กรุณากรอกรหัสคูปอง",
      message_2: "ไม่พบรหัสคูปอง",
      message_3: "รหัสคูปองนี้หมดอายุแล้ว",
      message_4: "รหัสคูปองนี้ถูกใช้หมดโควต้าแล้ว",
      message_5: "รหัสคูปองนี้ถูกใช้ไปแล้ว",
    },
  },
  toolbox: {
    Role: "ตำแหน่ง",
    Role_focus: "ตำแหน่งที่มุ่งเน้น (ถ้ามี)",
    Level: "ระดับชั้นองค์กร",
    Role_Level: "ตำแหน่งและระดับชั้นองค์กร",
    Type_of_organization: "ประเภทองค์กร",
    Organization_Type: "ประเภทองค์กร",
    Core_values: "ค่านิยมหลัก",
    Your_Company_Values: "ค่านิยมหลักขององค์กรคุณ",
    Company_Mission_n_Vision: "พันธกิจและวิสัยทัศน์องค์กร",
    Select_a_Role_and_Level: "เลือกตำแหน่งและระดับ",
    hiring: "จ้างงาน",
    onboarding: "เตรียมความพร้อมพนักงาน",
    people_management: "จ้ดการพนักงาน",
  },
  core_values_analyzer: {
    title: "Core Values Analyzer",
    short_desc: "ตรวจสอบและรับคำแนะนำค่านิยมที่ใช่ของคุณ",
  },
  job_role_generator: {
    Job_role_generator: "Team Role Generator",
    short_desc: "ระบุตำแหน่ง พฤติกรรม และแนวทางการปรับตำแหน่ง",
    Your_role_generator_that_makes:
      "มาทำให้หน้าที่ ความรับผิดชอบ พฤติกรรม และหนทางการเติบโตในองค์กรของแต่ละตำแหน่งชัดเจนขึ้นกันเถอะ",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "เพิ่มค่านิยมหลักในคำอธิบายตำแหน่งด้วย เพื่อให้เห็นภาพแต่ละตำแหน่งมากขึ้น",
  },
  job_post_generator: {
    Job_post_generator: "Job Post Generator",
    short_desc: "สร้างประกาศงานที่ละเอียดและมีประสิทธิภาพแบบอัตโนมัติ",
    Your_post_generator_that_makes:
      "เราสร้างประกาศที่สมบูรณ์ ครบถ้วนด้วยหน้าที่ คุณสมบัติ และความคาดหวังสำหรับแต่ละตำแหน่ง",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "เพิ่มค่านิยมหลักในประกาศงาน เพื่อให้เจอคนที่ใช่อย่างรวดเร็ว",
  },
  interview_guide: {
    Interview_guide: "Interview Guide",
    short_desc: "การสัมภาษณ์ที่มีประสิทธิภาพกับผู้สมัครระหว่างกระบวนการจ้างงาน",
    long_desc: "ชุดคำถามและกรณีศึกษาเพื่อช่วยในขั้นตอนการสัมภาษณ์ของคุณ",
    introduction:
      "เพิ่มค่านิยมหลักของคุณลงในคู่มือการสัมภาษณ์เพื่อทำให้ค่านิยมเหล่านั้นเป็นจริง",
  },
  career_path_tracker: {
    Career_path_tracker: "Career Tracker Generator",
    short_desc:
      "ขยายความและแนะนำการเติบโตในเส้นทางอาชีพสำหรับสมาชิกในทีมของคุณ",
    Your_post_generator_that_makes:
      "สร้างเครื่องมือที่ครอบคลุมทุกมิติ เพื่อให้เห็นโอกาสการเติบโตในสายอาชีพของแต่ละคนอย่างชัดเจน",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "เพิ่มค่านิยมหลักในการติดตาม เพื่อให้เติบโตไปในทิศทางเดียวกับองค์กร",
  },
  okr_generator: {
    Okr_generator: "OKR & Action Plans Generator",
    short_desc: "ระบุเป้าหมายและผลลัพธ์ที่ต้องการให้ชัดเจน และติดตามเป็นระยะ",
    Your_OKR_generator_that_crafts_objectives_and_key_results_that_are_clear_and_measurable:
      "เครื่องมือสร้าง OKR จะช่วยให้คุณกำหนดเป้าหมายและผลลัพธ์ที่ต้องการได้อย่างชัดเจน และวัดผลได้จริง",
    Select_between_Org_Team_or_Individual_OKRs:
      "เลือกระหว่างองค์กร ทีม หรือเป้าหมายเฉพาะบุคคล",
    Add_your_core_values_to_your_role_description_to_bring_them_to_life:
      "เพิ่มค่านิยมหลักใน OKR ของคุณด้วย เพื่อความเป็นหนึ่งเดียวกับองค์กร",
    Title_for_company: "OKRs สำหรับ {company}",
    Title_for_team: "OKRs สำหรับ {role} ทีม",
    Title_for_individual: "OKRs สำหรับ {role} {level}",
  },
  performance_improvement: {
    title: "Performance Improvement Plan Template",
    short_desc: "แผนเพิ่มประสิทธิภาพของพนักงานอย่างมีลำดับขั้นตอน",
    subtitle:
      "จัดทำแผนที่ชัดเจนและเป็นระบบเพื่อให้ทีมของคุณทำงานได้ตรงตามความคาดหวัง หรือเหนือความคาดหมาย",
    suggestion:
      "เพิ่มค่านิยมหลักในแผนของคุณด้วย เพื่อให้พนักงานเป็นหนึ่งเดียวกับองค์กรมากขึ้น",
  },
  num_day_plan: {
    title: "30/60/90-day Plan",
    short_desc: "ขั้นตอนเสริมสร้างความเป็นหนึ่งเดียวกันของทีมใน 30/60/90 วัน",
    subtitle:
      "สร้างแผนเตรียมความพร้อมพนักงานของคุณ โดยมีรายการกิจกรรมและความคาดหวังที่ชัดเจน",
    suggestion:
      "เพิ่มค่านิยมหลักเข้าไปในการเตรียมความพร้อมพนักงาน เพื่อให้ทีมของคุณเป็นหนึ่งเดียวกัน",
  },
  guided_tour: {
    create: {
      step_0: `
        <div class="title">พร้อมที่จะวัดวัฒนธรรมของคุณ<br />หรือยัง?</div>
        <div class="content">
          คลิก "แบบทดสอบใหม่" <br />
          เพื่อสร้างการประเมินครั้งแรกของคุณ<br />
          ใน 3 ขั้นตอน
        </div>
        <div class="footer">ความคืบหน้า: <span>ขั้นตอน 0/3</span></div>
      `,
      step_1: `
        <div class="title">มาตั้งค่าการประเมินของคุณ<br />กันเถอะ</div>
        <div class="content">
          กำหนดชื่อและกรอกรายละเอียด<br />
          ที่จะช่วยให้เราตั้งค่าการประเมิน<br />
          ส่วนบุคคลของคุณ
        </div>
        <div class="footer">ความคืบหน้า: <span>ขั้นตอน 1/3</span></div>
      `,
      step_2: `
        <div class="title">
          เลือกและการตั้งค่าส่วนต่างๆ
        </div>
        <div class="content">
          เราได้เลือกและกำหนดค่าส่วนต่างๆ<br />
          อย่างชาญฉลาดตามรายละเอียด<br />
          ที่คุณให้มา
        </div>
        <div class="footer">ความคืบหน้า: <span>ขั้นตอน 2/3</span></div>
      `,
      step_3: `
        <div class="title">การดูครั้งสุดท้าย</div>
        <div class="content">
          ตรวจสอบการตั้งค่าของคุณและกำหนดค่า<br />
          ตัวเลือกขั้นสูง<br />
          พร้อมหรือยัง? คลิก 'เผยแพร่' เพื่อเริ่มการประเมินของคุณ
        </div>
        <div class="footer">ความคืบหน้า: <span>ขั้นตอน 3/3</span></div>
      `,
    },
    invite: {
      step_0: `
        <div class="title">พร้อมเริ่มการประเมินแล้ว!</div>
        <div class="content">
          การประเมินของคุณพร้อมใช้งานแล้ว<br />
          และพร้อมสำหรับผู้สมัครแล้ว คลิกแถว<br />
          เพื่อจัดการคำเชิญและติดตามผลลัพธ์
        </div>
        <div class="footer">ถัดไป: <span>เริ่มการเชิญชวน</span></div>
      `,
      step_1: `
        <div class="title">พร้อมที่จะส่งคำเชิญ</div>
        <div class="content">
          เพียงป้อนที่อยู่อีเมล<br />
          หรือใช้ 'คัดลอกลิงก์' เพื่อแชร์กับ<br />
          ผู้สมัคร
        </div>
        <div class="footer">ขั้นตอนสุดท้าย: <span>ส่งคำเชิญครั้งแรก<br />ของคุณ</span></div>
      `,
    },
  },
};

export default th;
