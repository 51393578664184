import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, withKeys as _withKeys, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow } from "vue"
import _imports_0 from '@/assets/icons/arrow.svg'
import _imports_1 from '@/assets/icons/edit.svg'
import _imports_2 from '@/assets/icons/copy.svg'
import _imports_3 from '@/assets/icons/gray_help_24px.png'


const _hoisted_1 = { class: "menu-container flex-center" }
const _hoisted_2 = { class: "body-width-reponsive-max" }
const _hoisted_3 = { class: "nav-bar-container flex-center" }
const _hoisted_4 = { class: "flex-center" }
const _hoisted_5 = {
  key: 0,
  class: "label-container"
}
const _hoisted_6 = { class: "nav-text-header ellipsis" }
const _hoisted_7 = { class: "nav-text-description" }
const _hoisted_8 = {
  key: 0,
  class: "pl-6 pr-6"
}
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "ml-auto flex-center" }
const _hoisted_11 = { class: "ml-12" }
const _hoisted_12 = {
  class: "body-width pb-32",
  style: {"margin-top":"6.4rem"}
}
const _hoisted_13 = { class: "box-card p-32 mb-24" }
const _hoisted_14 = {
  key: 0,
  class: "invite-candidate"
}
const _hoisted_15 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_16 = {
  for: "",
  class: "label"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "flex-center ml-32" }
const _hoisted_20 = { class: "fs-12 fw-700" }
const _hoisted_21 = {
  key: 1,
  class: "btn02 btn-48 btn-primary btn02-disable flex-mid mr-12",
  style: {"width":"12rem"}
}
const _hoisted_22 = { class: "" }
const _hoisted_23 = { style: {"grid-column":"2"} }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "border" }
const _hoisted_26 = { class: "analytics-container mb-24" }
const _hoisted_27 = { class: "box-card p-32" }
const _hoisted_28 = {
  class: "fs-20 fw-700",
  style: {"color":"#1b1c1e"}
}
const _hoisted_29 = { class: "collapse-element" }
const _hoisted_30 = {
  ref: "analytics_container_ref",
  class: "mt-40"
}
const _hoisted_31 = { class: "progress" }
const _hoisted_32 = { class: "progress-details" }
const _hoisted_33 = { class: "left-text" }
const _hoisted_34 = { class: "right-text" }
const _hoisted_35 = { class: "progress-details" }
const _hoisted_36 = { class: "left-text" }
const _hoisted_37 = { class: "right-text" }
const _hoisted_38 = { class: "progress-details" }
const _hoisted_39 = { class: "left-text" }
const _hoisted_40 = { class: "right-text" }
const _hoisted_41 = { class: "progress-details" }
const _hoisted_42 = { class: "left-text" }
const _hoisted_43 = { class: "right-text" }
const _hoisted_44 = { class: "box-card p-32" }
const _hoisted_45 = { class: "box-content group-container" }
const _hoisted_46 = { class: "box-content" }
const _hoisted_47 = { class: "line-chart" }
const _hoisted_48 = {
  class: "title",
  style: {"font-size":"2rem"}
}
const _hoisted_49 = { class: "line-container flex-center" }
const _hoisted_50 = { class: "line-data" }
const _hoisted_51 = {
  key: 0,
  class: "box-card group-container p-32 mt-24"
}
const _hoisted_52 = {
  key: 0,
  class: "box-content"
}
const _hoisted_53 = { class: "line-chart" }
const _hoisted_54 = { class: "title" }
const _hoisted_55 = { class: "line-container flex-center" }
const _hoisted_56 = { class: "line-data" }
const _hoisted_57 = { class: "label flex-center ellipsis" }
const _hoisted_58 = { class: "ellipsis" }
const _hoisted_59 = ["onClick"]
const _hoisted_60 = {
  key: 1,
  class: "box-content mt-36"
}
const _hoisted_61 = { class: "line-chart" }
const _hoisted_62 = { class: "title" }
const _hoisted_63 = { class: "line-container flex-center" }
const _hoisted_64 = { class: "line-data" }
const _hoisted_65 = { class: "text-percentage" }
const _hoisted_66 = { class: "label flex-center ellipsis" }
const _hoisted_67 = { class: "ellipsis" }
const _hoisted_68 = ["onClick"]
const _hoisted_69 = {
  key: 2,
  class: "box-content mt-36"
}
const _hoisted_70 = { class: "line-chart" }
const _hoisted_71 = { class: "title" }
const _hoisted_72 = { class: "line-container flex-center" }
const _hoisted_73 = { class: "line-data" }
const _hoisted_74 = { class: "text-percentage" }
const _hoisted_75 = { class: "label flex-center ellipsis" }
const _hoisted_76 = { class: "ellipsis" }
const _hoisted_77 = ["onClick"]
const _hoisted_78 = {
  key: 3,
  class: "box-content mt-36"
}
const _hoisted_79 = { class: "line-chart" }
const _hoisted_80 = { class: "title" }
const _hoisted_81 = { class: "line-container flex-center" }
const _hoisted_82 = { class: "line-data" }
const _hoisted_83 = { class: "text-percentage" }
const _hoisted_84 = { class: "label flex-center ellipsis" }
const _hoisted_85 = { class: "ellipsis" }
const _hoisted_86 = ["onClick"]
const _hoisted_87 = {
  key: 4,
  class: "box-content mt-36"
}
const _hoisted_88 = { class: "line-chart" }
const _hoisted_89 = { class: "title" }
const _hoisted_90 = { class: "line-container flex-center" }
const _hoisted_91 = { class: "line-data" }
const _hoisted_92 = { class: "text-percentage" }
const _hoisted_93 = { class: "label flex-center ellipsis" }
const _hoisted_94 = { class: "ellipsis" }
const _hoisted_95 = {
  key: 0,
  class: "mt-24"
}
const _hoisted_96 = {
  key: 1,
  class: "box-card"
}
const _hoisted_97 = { class: "p-40 m-40 flex-mid" }
const _hoisted_98 = { class: "emove-header remove-padding-modal" }
const _hoisted_99 = { class: "ad-invite-container" }
const _hoisted_100 = { class: "" }
const _hoisted_101 = { class: "fc-1B1C1E fs-14 fw-700 mt-24" }
const _hoisted_102 = {
  class: "input-container-02 mt-16",
  style: {"width":"100%"}
}
const _hoisted_103 = { class: "flex-center mt-24" }
const _hoisted_104 = {
  class: "fc-1B1C1E fs-14 fw-700",
  style: {"color":"#1b1c1e"}
}
const _hoisted_105 = { class: "ml-auto" }
const _hoisted_106 = { class: "mt-16" }
const _hoisted_107 = { class: "end-editor flex-center" }
const _hoisted_108 = { class: "pl-16" }
const _hoisted_109 = { class: "right-grid" }
const _hoisted_110 = {
  class: "fs-14 fw-700 mt-24",
  style: {"color":"#1b1c1e"}
}
const _hoisted_111 = {
  class: "input-container-02 mt-16",
  style: {"width":"100%"}
}
const _hoisted_112 = { class: "flex-center mt-24" }
const _hoisted_113 = {
  class: "fs-14 fw-700",
  style: {"color":"#1b1c1e"}
}
const _hoisted_114 = { class: "fs-14 opa-07 ml-auto" }
const _hoisted_115 = { class: "table-container" }
const _hoisted_116 = { style: {"border-width":"0"} }
const _hoisted_117 = {
  style: {"width":"22%"},
  align: "left"
}
const _hoisted_118 = { class: "cell" }
const _hoisted_119 = {
  style: {"width":"22%"},
  align: "left"
}
const _hoisted_120 = { class: "cell" }
const _hoisted_121 = {
  style: {"width":"50%"},
  align: "left"
}
const _hoisted_122 = { class: "cell" }
const _hoisted_123 = {
  class: "thin-scrollbar",
  style: {"max-height":"37rem"}
}
const _hoisted_124 = { class: "cell opa-04 flex-mid" }
const _hoisted_125 = ["onUpdate:modelValue"]
const _hoisted_126 = ["onUpdate:modelValue"]
const _hoisted_127 = ["onUpdate:modelValue"]
const _hoisted_128 = { class: "flex-center mt-52" }
const _hoisted_129 = { class: "border" }
const _hoisted_130 = ["disabled"]
const _hoisted_131 = { class: "fs-14 fw-500" }
const _hoisted_132 = {
  key: 1,
  class: "btn btn-create btn-progress btn-44 btn-primary"
}
const _hoisted_133 = {
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve",
  style: {"width":"32px"}
}
const _hoisted_134 = {
  key: 0,
  class: "fs-20 fw-700 text-skeleton",
  style: {"width":"11rem"}
}
const _hoisted_135 = { class: "ml-auto flex-center scale-container fs-10" }
const _hoisted_136 = {
  key: 2,
  class: "thin-scrollbar min-height-modal value-content-container-modal",
  style: {"min-height":"40rem"}
}
const _hoisted_137 = { class: "value-content-container" }
const _hoisted_138 = { class: "value-content-container" }
const _hoisted_139 = { class: "value-content-container" }
const _hoisted_140 = {
  key: 3,
  class: "thin-scrollbar min-height-modal value-content-container-modal"
}
const _hoisted_141 = { class: "value-content-container" }
const _hoisted_142 = { class: "title-height flex-center" }
const _hoisted_143 = {
  class: "ellipsis",
  style: {"width":"18rem","overflow":"initial"}
}
const _hoisted_144 = {
  key: 0,
  class: "flex-center fs-12 fw-400 row-value"
}
const _hoisted_145 = { class: "x-flex-mid relative" }
const _hoisted_146 = { class: "name-label ellipsis flex-center" }
const _hoisted_147 = { class: "ellipsis" }
const _hoisted_148 = {
  class: "thin-scrollbar",
  style: {"overflow-x":"scroll"}
}
const _hoisted_149 = { class: "value-content-container" }
const _hoisted_150 = {
  key: 0,
  class: "flex-mid fs-12 fw-400 row-value opa-08"
}
const _hoisted_151 = { class: "display-container" }
const _hoisted_152 = { class: "ellipsis" }
const _hoisted_153 = { class: "value-content-container" }
const _hoisted_154 = {
  key: 0,
  class: "flex-center fs-12 fw-400 row-value"
}
const _hoisted_155 = { class: "percentage-label" }
const _hoisted_156 = { class: "pt-42 pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_157 = {
  key: 0,
  class: "fs-20 fw-700 text-skeleton",
  style: {"width":"15rem"}
}
const _hoisted_158 = {
  key: 1,
  class: "fc-1B1C1E fs-20 fw-700"
}
const _hoisted_159 = { class: "mt-24" }
const _hoisted_160 = { class: "culture-profile-modal-detail" }
const _hoisted_161 = {
  key: 0,
  class: "absolute-mid opa-06"
}
const _hoisted_162 = { class: "m-auto mt-4" }
const _hoisted_163 = { class: "chart-label-fixed" }
const _hoisted_164 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_165 = ["innerHTML"]
const _hoisted_166 = { class: "box underline" }
const _hoisted_167 = { class: "header-text fc-1B1C1E" }
const _hoisted_168 = { class: "sub-text" }
const _hoisted_169 = { class: "flex-center" }
const _hoisted_170 = { class: "chart-label-fixed" }
const _hoisted_171 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_172 = ["innerHTML"]
const _hoisted_173 = {
  class: "box underline",
  style: {"left":"3rem"}
}
const _hoisted_174 = { class: "header-text" }
const _hoisted_175 = { class: "sub-text" }
const _hoisted_176 = { style: {"width":"400px","height":"400px"} }
const _hoisted_177 = {
  ref: "culture_preference_chart",
  width: "260",
  height: "260"
}
const _hoisted_178 = { class: "chart-label-fixed" }
const _hoisted_179 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_180 = ["innerHTML"]
const _hoisted_181 = { class: "box underline" }
const _hoisted_182 = { class: "header-text" }
const _hoisted_183 = { class: "sub-text" }
const _hoisted_184 = { class: "chart-label-fixed" }
const _hoisted_185 = {
  class: "fs-14",
  style: {"width":"240px"}
}
const _hoisted_186 = ["innerHTML"]
const _hoisted_187 = { class: "box underline" }
const _hoisted_188 = { class: "header-text" }
const _hoisted_189 = { class: "sub-text" }
const _hoisted_190 = { class: "center fs-12 opa-04 mt-24" }
const _hoisted_191 = { class: "min-height-modal thin-scrollbar flex-center" }
const _hoisted_192 = {
  key: 0,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_193 = { class: "flex-center candidate-name" }
const _hoisted_194 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_195 = {
  key: 1,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_196 = { class: "flex-center candidate-name" }
const _hoisted_197 = {
  key: 0,
  class: "label ellipsis"
}
const _hoisted_198 = ["onClick"]
const _hoisted_199 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_200 = { class: "label ellipsis" }
const _hoisted_201 = { class: "pt-42 pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_202 = {
  key: 0,
  class: "fs-20 fw-700 text-skeleton",
  style: {"width":"20rem"}
}
const _hoisted_203 = {
  key: 1,
  class: "fc-1B1C1E fs-20 fw-700"
}
const _hoisted_204 = { class: "mt-30" }
const _hoisted_205 = { class: "culture-profile-modal-detail" }
const _hoisted_206 = {
  key: 0,
  class: "absolute-mid opa-06"
}
const _hoisted_207 = { class: "flex-center" }
const _hoisted_208 = { style: {"width":"400px","height":"400px"} }
const _hoisted_209 = {
  ref: "human_skills_chart",
  width: "260",
  height: "260"
}
const _hoisted_210 = { class: "min-height-modal thin-scrollbar flex-center" }
const _hoisted_211 = {
  key: 0,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_212 = { class: "flex-center candidate-name" }
const _hoisted_213 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_214 = {
  key: 1,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_215 = { class: "flex-center candidate-name" }
const _hoisted_216 = {
  key: 0,
  class: "label ellipsis"
}
const _hoisted_217 = ["onClick"]
const _hoisted_218 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_219 = { class: "label ellipsis" }
const _hoisted_220 = { class: "pt-42 pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_221 = {
  key: 0,
  class: "fs-20 fw-700 text-skeleton",
  style: {"width":"20rem"}
}
const _hoisted_222 = {
  key: 1,
  class: "fc-1B1C1E fs-20 fw-700"
}
const _hoisted_223 = { class: "mt-30" }
const _hoisted_224 = { class: "culture-profile-modal-detail" }
const _hoisted_225 = {
  key: 0,
  class: "absolute-mid opa-06"
}
const _hoisted_226 = { class: "m-auto mt-4" }
const _hoisted_227 = { class: "chart-label-fixed" }
const _hoisted_228 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_229 = {
  class: "box underline",
  style: {"width":"200px"}
}
const _hoisted_230 = { class: "header-text" }
const _hoisted_231 = { class: "sub-text" }
const _hoisted_232 = { class: "flex-center" }
const _hoisted_233 = { class: "chart-label-fixed" }
const _hoisted_234 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_235 = {
  class: "box underline",
  style: {"left":"3rem","width":"200px"}
}
const _hoisted_236 = { class: "header-text" }
const _hoisted_237 = { class: "sub-text" }
const _hoisted_238 = { style: {"width":"400px","height":"400px"} }
const _hoisted_239 = {
  ref: "logic_test_chart",
  width: "260",
  height: "260"
}
const _hoisted_240 = { class: "chart-label-fixed" }
const _hoisted_241 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_242 = {
  class: "box underline",
  style: {"width":"200px"}
}
const _hoisted_243 = { class: "header-text" }
const _hoisted_244 = { class: "sub-text" }
const _hoisted_245 = { class: "chart-label-fixed" }
const _hoisted_246 = {
  class: "box-item",
  effect: "light",
  placement: "top"
}
const _hoisted_247 = {
  class: "box underline",
  style: {"width":"200px"}
}
const _hoisted_248 = { class: "header-text" }
const _hoisted_249 = { class: "sub-text" }
const _hoisted_250 = { class: "min-height-modal thin-scrollbar flex-center" }
const _hoisted_251 = {
  key: 0,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_252 = { class: "flex-center candidate-name" }
const _hoisted_253 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_254 = {
  key: 1,
  class: "candidate-container",
  style: {"height":"min-content"}
}
const _hoisted_255 = ["onClick"]
const _hoisted_256 = {
  class: "absolute-mid",
  style: {"left":"1rem"}
}
const _hoisted_257 = { class: "label ellipsis" }
const _hoisted_258 = { class: "pt-42 pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_259 = {
  key: 0,
  class: "fs-20 fw-700 text-skeleton",
  style: {"width":"16rem"}
}
const _hoisted_260 = {
  key: 1,
  class: "fc-1B1C1E fs-20 fw-700"
}
const _hoisted_261 = {
  key: 2,
  class: "mt-32 x-thin-scrollbar relative",
  style: {"x-height":"64vh"}
}
const _hoisted_262 = { class: "scenario-alignment-container header" }
const _hoisted_263 = { class: "scenario-header flex-mid" }
const _hoisted_264 = { class: "flex" }
const _hoisted_265 = { class: "ellipsis pl-10 pr-10" }
const _hoisted_266 = {
  key: 0,
  style: {"margin-top":"5.4rem"}
}
const _hoisted_267 = {
  class: "thin-scrollbar",
  style: {"height":"48rem"}
}
const _hoisted_268 = { class: "scenario-header" }
const _hoisted_269 = {
  class: "fs-12",
  style: {"color":"#696c80"}
}
const _hoisted_270 = {
  class: "fs-14 mt-12",
  style: {"line-height":"160%"}
}
const _hoisted_271 = {
  width: "10",
  height: "10",
  viewBox: "0 0 10 10",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_272 = ["fill"]
const _hoisted_273 = { class: "pl-4" }
const _hoisted_274 = { class: "flex" }
const _hoisted_275 = { class: "flex-mid pl-10 pr-10" }
const _hoisted_276 = { key: 0 }
const _hoisted_277 = { key: 1 }
const _hoisted_278 = { key: 2 }
const _hoisted_279 = { class: "percentage-label flex-mid" }
const _hoisted_280 = {
  key: 3,
  class: "mt-32 thin-scrollbar opa-06 relative",
  style: {"height":"64vh"}
}
const _hoisted_281 = { class: "scenario-alignment-container header" }
const _hoisted_282 = { class: "scenario-header flex-mid" }
const _hoisted_283 = { class: "flex" }
const _hoisted_284 = { class: "scenario-header" }
const _hoisted_285 = {
  class: "fs-12",
  style: {"color":"#696c80"}
}
const _hoisted_286 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_CandidateTable = _resolveComponent("CandidateTable")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_editor_content = _resolveComponent("editor-content")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.backLastPage())),
              class: "btn-left"
            }, _cache[24] || (_cache[24] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "icons_navigate_right_black_24px",
                class: "icon-16 animetion"
              }, null, -1)
            ])),
            (_ctx.formTemplate)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formTemplate.form_name), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.sectionTimeTotal) + " min", 1),
                    (_ctx.sectionTimeTotal && _ctx.sections)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, "•"))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.sections) + " " + _toDisplayString(_ctx.$t("assessmentsDetails.sections")), 1),
                    _cache[25] || (_cache[25] = _createElementVNode("span", { class: "pl-6 pr-6" }, "•", -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("assessmentsDetails.Created_by")) + " " + _toDisplayString(_ctx.createdBy), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editForm())),
              class: _normalizeClass(["ml-20 btn02 btn-primary", { 'btn02-disable': !_ctx.canEdit }])
            }, [
              _cache[26] || (_cache[26] = _createElementVNode("span", null, [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: "icons_white_edit_24dp",
                  class: "icon-invert-color icon-16 mt-2"
                })
              ], -1)),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("btn.Edit")), 1)
            ], 2)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        (_ctx.user && _ctx.formTemplate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_el_popover, {
                visible: _ctx.isAllowedToShowInvitePopover(_ctx.formTemplate),
                placement: "bottom-start",
                width: 220,
                "popper-class": "mc-popover"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    _withDirectives(_createElementVNode("input", {
                      class: "input",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.email) = $event)),
                      placeholder: "",
                      type: "email"
                    }, null, 512), [
                      [_vModelText, _ctx.form.email]
                    ]),
                    _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("placeholder.Email_Address_start")), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "popover-content",
                    innerHTML: _ctx.$t('guided_tour.invite.step_1')
                  }, null, 8, _hoisted_17)
                ]),
                _: 1
              }, 8, ["visible"]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  (!_ctx.inviteButtonLoading)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.invite && _ctx.invite(...args))),
                        class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid mr-12", `${_ctx.canInvite ? '' : 'btn02-disable'}`]),
                        style: {"width":"12rem"}
                      }, [
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t("btn.Invite")), 1)
                      ], 2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[27] || (_cache[27] = [
                        _createElementVNode("svg", {
                          version: "1.1",
                          id: "L9",
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          x: "0px",
                          y: "0px",
                          viewBox: "0 0 100 100",
                          "enable-background": "new 0 0 0 0",
                          "xml:space": "preserve"
                        }, [
                          _createElementVNode("path", {
                            fill: "#fff",
                            d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                          }, [
                            _createElementVNode("animateTransform", {
                              attributeName: "transform",
                              attributeType: "XML",
                              type: "rotate",
                              dur: "1s",
                              from: "0 50 50",
                              to: "360 50 50",
                              repeatCount: "indefinite"
                            })
                          ])
                        ], -1)
                      ]))),
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.copyLink && _ctx.copyLink(...args))),
                    class: _normalizeClass(["btn-48 flex-mid", `${
                _ctx.canInvite ? 'btn02-outline' : 'btn02 btn-primary btn02-disable'
              }`]),
                    style: {"width":"13rem"}
                  }, [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: "icons_clone_20px",
                      class: "icon-16 mr-5",
                      style: _normalizeStyle(`${_ctx.canInvite ? '' : 'filter:invert(1)'}`)
                    }, null, 4),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("btn.Copy_link")), 1)
                  ], 2)
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["btn-bulk-detected fc-primary ml-auto cursor-pointer text-underline", `${_ctx.canAdvancedInvite ? '' : 'disabled'}`]),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openAdvancedInviteSenderModal())),
                    disabled: !_ctx.canAdvancedInvite
                  }, [
                    _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("assessmentsDetails.Advanced_Invite_Sender")), 1)
                  ], 10, _hoisted_24)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("assessmentResults.Analytics")), 1),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("optional.Invited")), 1),
                  _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.inviteLineData), 1)
                ]),
                _createVNode(_component_el_progress, {
                  percentage: _ctx.progressData('INVITED'),
                  "show-text": false,
                  color: "#ffc200",
                  "stroke-width": 3
                }, null, 8, ["percentage"]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t("optional.Started")), 1),
                  _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.startedLineData), 1)
                ]),
                _createVNode(_component_el_progress, {
                  percentage: _ctx.progressData('TODO'),
                  "show-text": false,
                  color: "#5eb8f6",
                  "stroke-width": 3
                }, null, 8, ["percentage"]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.$t("optional.Incompleted")), 1),
                  _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.incompleteLineData), 1)
                ]),
                _createVNode(_component_el_progress, {
                  percentage: _ctx.progressData('IN_PROGRESS'),
                  "show-text": false,
                  color: "#ff7575",
                  "stroke-width": 3
                }, null, 8, ["percentage"]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.$t("optional.Completed")), 1),
                  _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.completeLineData), 1)
                ]),
                _createVNode(_component_el_progress, {
                  percentage: _ctx.progressData('COMPLETED'),
                  "show-text": false,
                  color: "#22bc66",
                  "stroke-width": 3
                }, null, 8, ["percentage"])
              ])
            ], 512)
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$t("assessmentResults.Score_Summary")), 1),
                  _createElementVNode("div", _hoisted_49, [
                    _cache[28] || (_cache[28] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                    _createElementVNode("div", _hoisted_50, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreSummaryLineData, (value, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index + value.name,
                          class: "pointer-container",
                          style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                        }, [
                          _createVNode(_component_el_tooltip, {
                            content: value.name,
                            placement: "top"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["pointer", `${value.color}`])
                              }, null, 2)
                            ]),
                            _: 2
                          }, 1032, ["content"])
                        ], 4))
                      }), 128))
                    ]),
                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                  ])
                ])
              ])
            ])
          ]),
          (
            _ctx.hasValueAndTraitsLineData ||
            _ctx.hasCultureProfileLineData ||
            _ctx.hasHumanSkillsLineData ||
            _ctx.hasLogicTestLineData ||
            _ctx.hasAcceptableBehaviorsLineData
          )
            ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                (_ctx.hasValueAndTraitsLineData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.$t("main.Values_and_Traits")), 1),
                        _createElementVNode("div", _hoisted_55, [
                          _cache[30] || (_cache[30] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                          _createElementVNode("div", _hoisted_56, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsLineData, (value, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index + value.name,
                                class: "pointer-container",
                                style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  content: value.name,
                                  placement: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["pointer", `${value.color}`])
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 4))
                            }), 128))
                          ]),
                          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                        ])
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass(["text-percentage value-container-wrapper", `${_ctx.isV2 ? 'v2' : ''}`])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsDataDetail, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'text_pen' + index,
                            class: "value-container"
                          }, [
                            _createElementVNode("div", _hoisted_57, [
                              _createElementVNode("div", _hoisted_58, _toDisplayString(value.label), 1)
                            ]),
                            _createElementVNode("div", {
                              onClick: ($event: any) => (_ctx.openValuesAndTraitsModalDetail(value)),
                              class: _normalizeClass(["number flex-mid", `${value.color}`])
                            }, _toDisplayString(value.value), 11, _hoisted_59)
                          ]))
                        }), 128))
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasCultureProfileLineData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                      _createElementVNode("div", _hoisted_61, [
                        _createElementVNode("div", _hoisted_62, _toDisplayString(_ctx.$t("main.Culture_Profile")), 1),
                        _createElementVNode("div", _hoisted_63, [
                          _cache[32] || (_cache[32] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                          _createElementVNode("div", _hoisted_64, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cultureProfileLineData, (value, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index + value.name,
                                class: "pointer-container",
                                style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  content: value.name,
                                  placement: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["pointer", `${value.color}`])
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 4))
                            }), 128))
                          ]),
                          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_65, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cultureProfileDataDetail, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'text_pen' + index,
                            class: "value-container"
                          }, [
                            _createElementVNode("div", _hoisted_66, [
                              _createElementVNode("div", _hoisted_67, _toDisplayString(value.label), 1)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["number flex-mid", `${value.color}`]),
                              onClick: ($event: any) => (_ctx.openChartcultureModalDetail(value))
                            }, _toDisplayString(value.value), 11, _hoisted_68)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasHumanSkillsLineData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                      _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, _toDisplayString(_ctx.$t("main.Human_Skills")), 1),
                        _createElementVNode("div", _hoisted_72, [
                          _cache[34] || (_cache[34] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                          _createElementVNode("div", _hoisted_73, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.humanSkillsLineData, (value, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index + value.name,
                                class: "pointer-container",
                                style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  content: value.name,
                                  placement: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["pointer", `${value.color}`])
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 4))
                            }), 128))
                          ]),
                          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_74, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.humanSkillsDataDetail, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'text_pen' + index,
                            class: "value-container"
                          }, [
                            _createElementVNode("div", _hoisted_75, [
                              _createElementVNode("div", _hoisted_76, _toDisplayString(value.label), 1)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["number flex-mid", `${value.color}`]),
                              onClick: ($event: any) => (_ctx.openChartHumanSkillsModalDetail(value))
                            }, _toDisplayString(value.value), 11, _hoisted_77)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasLogicTestLineData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.$t("main.Logic_Test")), 1),
                        _createElementVNode("div", _hoisted_81, [
                          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                          _createElementVNode("div", _hoisted_82, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logicTestLineData, (value, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index + value.name,
                                class: "pointer-container",
                                style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  content: value.name,
                                  placement: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["pointer", `${value.color}`])
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 4))
                            }), 128))
                          ]),
                          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_83, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logicTestDataDetail, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'text_pen' + index,
                            class: "value-container"
                          }, [
                            _createElementVNode("div", _hoisted_84, [
                              _createElementVNode("div", _hoisted_85, _toDisplayString(value.label), 1)
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["number flex-mid", `${value.color}`]),
                              onClick: ($event: any) => (_ctx.openChartLogicTestModalDetail(value))
                            }, _toDisplayString(value.value), 11, _hoisted_86)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.hasAcceptableBehaviorsLineData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                      _createElementVNode("div", _hoisted_88, [
                        _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.$t("main.Acceptable_Behaviors")), 1),
                        _createElementVNode("div", _hoisted_90, [
                          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "percentage-label right mr-8" }, "0%", -1)),
                          _createElementVNode("div", _hoisted_91, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviorsLineData, (value, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index + value.name,
                                class: "pointer-container",
                                style: _normalizeStyle(`left: ${value.percentageOfBar}%`)
                              }, [
                                _createVNode(_component_el_tooltip, {
                                  content: value.name,
                                  placement: "top"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass(["pointer", `${value.color}`])
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1032, ["content"])
                              ], 4))
                            }), 128))
                          ]),
                          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "percentage-label ml-8" }, "100%", -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_92, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableBehaviorsDataDetail, (value, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: 'text_pen' + index,
                            class: "value-container"
                          }, [
                            _createElementVNode("div", _hoisted_93, [
                              _createElementVNode("div", _hoisted_94, _toDisplayString(value.label), 1)
                            ]),
                            _createElementVNode("div", {
                              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.openAcceptableBehaviorsDataDetail && _ctx.openAcceptableBehaviorsDataDetail(...args))),
                              class: _normalizeClass(["number flex-mid", `${value.color}`])
                            }, _toDisplayString(value.value), 3)
                          ]))
                        }), 128))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.cadidateData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_95, [
            _createVNode(_component_CandidateTable)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_96, [
            _createElementVNode("div", _hoisted_97, [
              _createVNode(_component_el_empty, { description: " " })
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_98, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.advancedInviteStatus,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.advancedInviteStatus) = $event)),
        width: "1000px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_99, [
            _createElementVNode("div", _hoisted_100, [
              _createElementVNode("div", {
                class: _normalizeClass(["fs-14 fw-700", `${_ctx.$t('lang_code') == 'th' ? 'fs-16' : 'fs-14'}`]),
                style: {"color":"#1b1c1e"}
              }, _toDisplayString(_ctx.$t("assessmentsDetails.ADVANCED_INVITE_SENDER")), 3),
              _createElementVNode("div", _hoisted_101, _toDisplayString(_ctx.$t("assessmentsDetails.Email_Subject")), 1),
              _createElementVNode("div", _hoisted_102, [
                _withDirectives(_createElementVNode("input", {
                  class: "input",
                  type: "text",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.advancedForm.subject) = $event)),
                  placeholder: 'Subject',
                  disabled: ""
                }, null, 512), [
                  [_vModelText, _ctx.advancedForm.subject]
                ])
              ]),
              _createElementVNode("div", _hoisted_103, [
                _createElementVNode("div", _hoisted_104, _toDisplayString(_ctx.$t("assessmentsDetails.Email_Body")), 1),
                _createElementVNode("div", _hoisted_105, [
                  _createVNode(_component_el_tooltip, { placement: "top" }, {
                    content: _withCtx(() => _cache[40] || (_cache[40] = [
                      _createElementVNode("div", {
                        class: "fs-12 pt-4",
                        style: {"width":"240px"}
                      }, [
                        _createElementVNode("div", { class: "mb-4" }, "$RECIPIENT_FIRST_NAME"),
                        _createElementVNode("div", { class: "mb-4" }, "$RECIPIENT_LAST_NAME"),
                        _createElementVNode("div", { class: "mb-4" }, "$SENDER_FIRST_NAME"),
                        _createElementVNode("div", { class: "mb-4" }, "$SENDER_LAST_NAME"),
                        _createElementVNode("div", { class: "" }, "$BUTTON_TO_ASSESSMENT")
                      ], -1)
                    ])),
                    default: _withCtx(() => [
                      _cache[41] || (_cache[41] = _createElementVNode("img", {
                        src: _imports_3,
                        alt: "icons_gray_help_24px",
                        style: {"width":"2rem","height":"2rem"}
                      }, null, -1))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_106, [
                _createVNode(_component_editor_content, {
                  editor: _ctx.advancedForm.emailBody
                }, null, 8, ["editor"]),
                _createElementVNode("div", _hoisted_107, [
                  _createElementVNode("div", _hoisted_108, _toDisplayString(_ctx.$t("assessmentsDetails.The_email_will_include")), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_109, [
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  class: _normalizeClass(["fs-14 fw-700", `${_ctx.$t('lang_code') == 'th' ? 'fs-16' : 'fs-14'}`])
                }, "   ", 2),
                _createElementVNode("div", _hoisted_110, _toDisplayString(_ctx.$t("assessmentsDetails.Email_Sender")), 1),
                _createElementVNode("div", _hoisted_111, [
                  _withDirectives(_createElementVNode("input", {
                    class: "input",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.advancedForm.emailSender) = $event)),
                    placeholder: 'Email Sender'
                  }, null, 512), [
                    [_vModelText, _ctx.advancedForm.emailSender]
                  ])
                ]),
                _createElementVNode("div", _hoisted_112, [
                  _createElementVNode("div", _hoisted_113, _toDisplayString(_ctx.$t("assessmentsDetails.Recipient_s")), 1),
                  _createElementVNode("div", _hoisted_114, _toDisplayString(_ctx.$t("assessmentsDetails.Tip_Paste_contact")), 1)
                ]),
                _createElementVNode("table", _hoisted_115, [
                  _createElementVNode("tr", _hoisted_116, [
                    _cache[42] || (_cache[42] = _createElementVNode("th", { style: {"width":"6%"} }, [
                      _createElementVNode("div", { class: "cell" }, " ")
                    ], -1)),
                    _createElementVNode("th", _hoisted_117, [
                      _createElementVNode("div", _hoisted_118, _toDisplayString(_ctx.$t("assessmentsDetails.First_Name")), 1)
                    ]),
                    _createElementVNode("th", _hoisted_119, [
                      _createElementVNode("div", _hoisted_120, _toDisplayString(_ctx.$t("assessmentsDetails.Last_Name")), 1)
                    ]),
                    _createElementVNode("th", _hoisted_121, [
                      _createElementVNode("div", _hoisted_122, _toDisplayString(_ctx.$t("assessmentsDetails.Email")), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_123, [
                  _createElementVNode("table", {
                    class: "table-container",
                    style: {"margin-top":"0"},
                    onPaste: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handlePaste && _ctx.handlePaste(...args)))
                  }, [
                    _cache[43] || (_cache[43] = _createElementVNode("tr", { style: {"height":"0px"} }, [
                      _createElementVNode("th", { style: {"width":"6%","height":"0px","padding":"0","border-width":"0"} }),
                      _createElementVNode("th", { style: {"width":"22%","height":"0px","padding":"0","border-width":"0"} }),
                      _createElementVNode("th", { style: {"width":"22%","height":"0px","padding":"0","border-width":"0"} }),
                      _createElementVNode("th", { style: {"width":"50%","height":"0px","padding":"0","border-width":"0"} })
                    ], -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advancedForm.recipients, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        class: _normalizeClass(["table-row", { 'red-row': _ctx.isDuplicateEmail(item.email, index) }]),
                        key: index
                      }, [
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_124, _toDisplayString(index + 1), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "table-input",
                            "onUpdate:modelValue": ($event: any) => ((item.firstname) = $event),
                            onKeydown: _cache[9] || (_cache[9] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addRecipient && _ctx.addRecipient(...args)), ["enter"]))
                          }, null, 40, _hoisted_125), [
                            [_vModelText, item.firstname]
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "table-input",
                            "onUpdate:modelValue": ($event: any) => ((item.lastname) = $event),
                            onKeydown: _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addRecipient && _ctx.addRecipient(...args)), ["enter"]))
                          }, null, 40, _hoisted_126), [
                            [_vModelText, item.lastname]
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "email",
                            class: "table-input",
                            "onUpdate:modelValue": ($event: any) => ((item.email) = $event),
                            onKeydown: _cache[11] || (_cache[11] = _withKeys(
//@ts-ignore
(...args) => (_ctx.addRecipient && _ctx.addRecipient(...args)), ["enter"]))
                          }, null, 40, _hoisted_127), [
                            [_vModelText, item.email]
                          ])
                        ])
                      ], 2))
                    }), 128))
                  ], 32)
                ])
              ]),
              _createElementVNode("div", _hoisted_128, [
                _createElementVNode("div", {
                  onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.sendTestEmail && _ctx.sendTestEmail(...args))),
                  class: "btn-bulk-detected fc-primary ml-auto mr-24 cursor-pointer text-underline"
                }, [
                  _createElementVNode("span", _hoisted_129, _toDisplayString(_ctx.$t("assessmentsDetails.Send_Test_Email")), 1)
                ]),
                (!_ctx.inviteBulkButtonLoading)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.canSendAdvancedInvite ? '' : 'btn02-disable'}`]),
                      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.sendAdvancedInvite && _ctx.sendAdvancedInvite(...args))),
                      disabled: _ctx.canSendAdvancedInvite
                    }, [
                      _createElementVNode("span", _hoisted_131, _toDisplayString(_ctx.$t("assessmentsDetails.Send_Invite")), 1)
                    ], 10, _hoisted_130))
                  : (_openBlock(), _createElementBlock("div", _hoisted_132, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentsDetails.Send_Invite")), 1),
                      (_openBlock(), _createElementBlock("svg", _hoisted_133, _cache[44] || (_cache[44] = [
                        _createElementVNode("path", {
                          fill: "#fff",
                          d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        }, [
                          _createElementVNode("animateTransform", {
                            attributeName: "transform",
                            attributeType: "XML",
                            type: "rotate",
                            dur: "1s",
                            from: "0 50 50",
                            to: "360 50 50",
                            repeatCount: "indefinite"
                          })
                        ], -1)
                      ])))
                    ]))
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.valueAndTraitsModalStatus,
        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.valueAndTraitsModalStatus) = $event)),
        width: "572px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["pt-42 pb-42 pl-32 pr-24", `${_ctx.isV2 ? 'values_and_traits v2' : 'values_and_traits'}`])
          }, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_134, "   "))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["fc-1B1C1E fs-20 fw-700", `${_ctx.isV2 ? 'mb-12' : ''}`])
                }, _toDisplayString(_ctx.$t("assessmentResults.Values_alignment")), 3)),
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.valueAndTraitsTapSelected,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.valueAndTraitsTapSelected) = $event)),
              class: _normalizeClass(["tab-custom mt-8", { 'opa-04': _ctx.loading, v2: _ctx.isV2, 'd-none': _ctx.isV2 }])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: "Values",
                  name: "Values"
                }),
                (!_ctx.isV2)
                  ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: 0,
                      label: "Traits",
                      name: "Traits"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "class"]),
            _createElementVNode("div", {
              class: _normalizeClass(["flex mt-12 mb-16", { 'opa-04': _ctx.loading }])
            }, [
              _createElementVNode("div", _hoisted_135, [
                _cache[45] || (_cache[45] = _createTextVNode(" importance ")),
                _createElementVNode("div", {
                  class: _normalizeClass(["mr-16", `${_ctx.isV2 ? 'd-none' : ''}`])
                }, "1", 2),
                _cache[46] || (_cache[46] = _createElementVNode("div", { class: "scale-box mr-8 one" }, null, -1)),
                _createElementVNode("div", {
                  class: _normalizeClass(["scale-box mr-8 two", `${_ctx.isV2 ? 'd-none' : ''}`])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(["scale-box mr-8 three", `${_ctx.isV2 ? 'd-none' : ''}`])
                }, null, 2),
                _cache[47] || (_cache[47] = _createElementVNode("div", { class: "scale-box mr-8 four" }, null, -1)),
                _cache[48] || (_cache[48] = _createElementVNode("div", { class: "scale-box five" }, null, -1)),
                _createElementVNode("div", {
                  class: _normalizeClass(["ml-16", `${_ctx.isV2 ? 'd-none' : ''}`])
                }, "5", 2)
              ])
            ], 2),
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_136, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsMockData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: ""
                      }, [
                        _createElementVNode("div", _hoisted_137, [
                          _cache[50] || (_cache[50] = _createElementVNode("div", { class: "title-height flex-center" }, [
                            _createElementVNode("div", {
                              class: "text-skeleton",
                              style: {"width":"12rem"}
                            }, " ")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, _cache[49] || (_cache[49] = [
                              _createElementVNode("div", {
                                class: "flex-mid relative",
                                style: {"background-color":"#fff"}
                              }, [
                                _createElementVNode("div", { class: "name-label ellipsis flex-center" }, [
                                  _createElementVNode("div", {
                                    class: "text-skeleton",
                                    style: {"width":"9rem"}
                                  }, "   ")
                                ])
                              ], -1)
                            ])))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsMockData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: ""
                      }, [
                        _createElementVNode("div", _hoisted_138, [
                          _cache[52] || (_cache[52] = _createElementVNode("div", { class: "title-height flex-center" }, [
                            _createElementVNode("div", { class: "ellipsis" }, " ")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, _cache[51] || (_cache[51] = [
                              _createElementVNode("div", { class: "display-container" }, [
                                _createElementVNode("div", { class: "display-box flex-mid opa-08" }),
                                _createElementVNode("div", { class: "display-box flex-mid opa-06" }),
                                _createElementVNode("div", { class: "display-box flex-mid opa-04" }),
                                _createElementVNode("div", { class: "display-box flex-mid opa-02" })
                              ], -1)
                            ])))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsMockData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: "value-content-modal opa-03"
                      }, [
                        _createElementVNode("div", _hoisted_139, [
                          _cache[54] || (_cache[54] = _createElementVNode("div", { class: "title-height flex-center" }, [
                            _createElementVNode("div", { class: "ellipsis" }, " ")
                          ], -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, _cache[53] || (_cache[53] = [
                              _createElementVNode("div", { class: "percentage-label" }, "--%", -1)
                            ])))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_140, [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsModalData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: ""
                      }, [
                        _createElementVNode("div", _hoisted_141, [
                          _createElementVNode("div", _hoisted_142, [
                            _createElementVNode("div", _hoisted_143, _toDisplayString(item.label_en), 1)
                          ]),
                          (!item.value.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_144, "   "))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, [
                              _createElementVNode("div", _hoisted_145, [
                                _createElementVNode("div", _hoisted_146, [
                                  _createElementVNode("div", _hoisted_147, _toDisplayString(value.name), 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_148, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsModalData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: ""
                      }, [
                        _createElementVNode("div", _hoisted_149, [
                          _cache[55] || (_cache[55] = _createElementVNode("div", { class: "title-height flex-center" }, [
                            _createElementVNode("div", { class: "ellipsis" }, " ")
                          ], -1)),
                          (!item.value.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_150, _toDisplayString(_ctx.$t("assessmentResults.There_are_no_values_assigned")), 1))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, [
                              _createElementVNode("div", _hoisted_151, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.candidate, (candidate, index3) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: candidate.id + '_index3_' + index3
                                  }, [
                                    _createVNode(_component_el_tooltip, {
                                      content: candidate.displayName,
                                      placement: "top"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          class: _normalizeClass(["display-box flex-mid ellipsis", `${candidate.color}`])
                                        }, [
                                          _createElementVNode("div", _hoisted_152, _toDisplayString(candidate.shortName), 1)
                                        ], 2)
                                      ]),
                                      _: 2
                                    }, 1032, ["content"])
                                  ]))
                                }), 128))
                              ])
                            ]))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueAndTraitsModalData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id + '_index_' + index,
                        class: "value-content-modal"
                      }, [
                        _createElementVNode("div", _hoisted_153, [
                          _cache[56] || (_cache[56] = _createElementVNode("div", { class: "title-height flex-center" }, [
                            _createElementVNode("div", { class: "ellipsis" }, " ")
                          ], -1)),
                          (!item.value.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_154, "   "))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (value, index2) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: value.id + '_index2_' + index2,
                              class: "row-value"
                            }, [
                              _createElementVNode("div", _hoisted_155, _toDisplayString(value.percentage) + "%", 1)
                            ]))
                          }), 128))
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
          ], 2)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.cultureProfileModalStatus,
        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.cultureProfileModalStatus) = $event)),
        width: "960px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_156, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_157, "   "))
              : (_openBlock(), _createElementBlock("div", _hoisted_158, _toDisplayString(_ctx.cultureProfileSelected.label) + ": " + _toDisplayString(_ctx.cultureProfileSelected.value) + "% ", 1)),
            _createElementVNode("div", {
              class: _normalizeClass([`${_ctx.loading ? 'opa-03' : ''}`, "fs-14 mt-12"])
            }, _toDisplayString(_ctx.$t("assessmentResults.The_overlap_between_the__workplace")), 3),
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.cultureProfileTabSelected,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.cultureProfileTabSelected) = $event)),
              class: _normalizeClass(["tab-custom mt-8", { 'opa-04': _ctx.loading }]),
              onTabChange: _ctx.handleClickOnCultureProfileTab
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cultureProfileDataDetail, (value, index) => {
                  return (_openBlock(), _createBlock(_component_el_tab_pane, {
                    key: 'tap_' + index,
                    label: value.label,
                    name: value.label
                  }, null, 8, ["label", "name"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "class", "onTabChange"]),
            _createElementVNode("div", _hoisted_159, [
              _createElementVNode("div", _hoisted_160, [
                _createElementVNode("div", {
                  class: _normalizeClass(["chart-container min-height-modal relative", `${_ctx.loading ? 'opa-01' : ''}`])
                }, [
                  (_ctx.culturePreferenceRenderChartValue > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_161, " Loading... "))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_162, [
                    _createElementVNode("div", _hoisted_163, [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_164, [
                            _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_165),
                            _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.collaborate), 1)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_166, [
                            _createElementVNode("div", _hoisted_167, _toDisplayString(_ctx.$t("assessmentResults.Collaborate")), 1),
                            _createElementVNode("div", _hoisted_168, _toDisplayString(_ctx.$t("assessmentResults.people_oriented")), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_169, [
                    _createElementVNode("div", _hoisted_170, [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_171, [
                            _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_172),
                            _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.control), 1)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_173, [
                            _createElementVNode("div", _hoisted_174, _toDisplayString(_ctx.$t("assessmentResults.Control")), 1),
                            _createElementVNode("div", _hoisted_175, _toDisplayString(_ctx.$t("assessmentResults.process_oriented")), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_176, [
                      _createElementVNode("canvas", _hoisted_177, null, 512)
                    ]),
                    _createElementVNode("div", _hoisted_178, [
                      _createVNode(_component_el_tooltip, {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, {
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_179, [
                            _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_180),
                            _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.create), 1)
                          ])
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_181, [
                            _createElementVNode("div", _hoisted_182, _toDisplayString(_ctx.$t("assessmentResults.Create")), 1),
                            _createElementVNode("div", _hoisted_183, _toDisplayString(_ctx.$t("assessmentResults.dynamic_entrepreneurial")), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_184, [
                    _createVNode(_component_el_tooltip, {
                      class: "box-item",
                      effect: "light",
                      placement: "top"
                    }, {
                      content: _withCtx(() => [
                        _createElementVNode("div", _hoisted_185, [
                          _createElementVNode("span", { innerHTML: _ctx.tooltipOncultureLabelPrefix }, null, 8, _hoisted_186),
                          _createElementVNode("span", null, _toDisplayString(_ctx.tooltipOncultureLabel.compete), 1)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_187, [
                          _createElementVNode("div", _hoisted_188, _toDisplayString(_ctx.$t("assessmentResults.Compete")), 1),
                          _createElementVNode("div", _hoisted_189, _toDisplayString(_ctx.$t("assessmentResults.results_oriented")), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_190, [
                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("assessmentResults.Hover_over_each_dimension")) + " " + _toDisplayString(_ctx.$t("assessmentResults.how_each_value_is")), 1)
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_191, [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_192, [
                        _createElementVNode("div", _hoisted_193, [
                          _createElementVNode("div", _hoisted_194, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["candidate-line", `${_ctx.loading ? 'opa-03' : ''}`])
                            }, null, 2)
                          ]),
                          _cache[57] || (_cache[57] = _createElementVNode("div", {
                            class: "label ellipsis text-skeleton",
                            style: {"width":"7rem"}
                          }, "   ", -1))
                        ]),
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (value, index) => {
                          return _withDirectives(_createElementVNode("div", {
                            key: value + '_mock_' + index,
                            class: _normalizeClass(["flex-center candidate-name", `opa-0${9 - index}`])
                          }, _cache[58] || (_cache[58] = [
                            _createElementVNode("div", {
                              class: "absolute-mid",
                              style: {"left":"1rem"}
                            }, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: `border-color: #eee`
                              })
                            ], -1),
                            _createElementVNode("div", {
                              class: "label text-skeleton",
                              style: {"width":"10rem"}
                            }, "   ", -1)
                          ]), 2), [
                            [_vShow, index != 0]
                          ])
                        }), 64))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_195, [
                        _createElementVNode("div", _hoisted_196, [
                          _cache[59] || (_cache[59] = _createElementVNode("div", {
                            class: "absolute-mid",
                            style: {"left":"1rem"}
                          }, [
                            _createElementVNode("div", { class: "candidate-line" })
                          ], -1)),
                          (_ctx.radarChartCandidateData)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_197, _toDisplayString(_ctx.companyName), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarChartCandidateData, (value, index) => {
                          return _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: value.name + index,
                            class: _normalizeClass(["flex-center candidate-name cursor-pointer", _ctx.candidateActiveClassOnCulturePreference(value.id)]),
                            onClick: ($event: any) => (_ctx.actionOnCandidate(value))
                          }, [
                            _createElementVNode("div", _hoisted_199, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: _normalizeStyle(`border-color: ${value.color}`)
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_200, _toDisplayString(value.name), 1)
                          ], 10, _hoisted_198)), [
                            [_vShow, index != 0]
                          ])
                        }), 128))
                      ]))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.humanSkillsModalStatus,
        "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.humanSkillsModalStatus) = $event)),
        width: "960px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_201, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_202, "   "))
              : (_openBlock(), _createElementBlock("div", _hoisted_203, _toDisplayString(_ctx.humanSkillsSelected.label) + ": " + _toDisplayString(_ctx.humanSkillsSelected.value) + "% ", 1)),
            _createElementVNode("div", {
              class: _normalizeClass([`${_ctx.loading ? 'opa-03' : ''}`, "fs-14 mt-12"])
            }, _toDisplayString(_ctx.$t("assessmentResults.A_human_skills_profile_for")), 3),
            false
              ? (_openBlock(), _createBlock(_component_el_tabs, {
                  key: 2,
                  modelValue: _ctx.humanSkillsTabSelected,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.humanSkillsTabSelected) = $event)),
                  class: _normalizeClass(["tab-custom mt-8", { 'opa-04': _ctx.loading }]),
                  onTabChange: _ctx.handleClickOnHumanSkillsTab
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.humanSkillsDataDetail, (value, index) => {
                      return (_openBlock(), _createBlock(_component_el_tab_pane, {
                        key: 'tap_' + index,
                        label: value.label,
                        name: value.label
                      }, null, 8, ["label", "name"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "class", "onTabChange"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_204, [
              _createElementVNode("div", _hoisted_205, [
                _createElementVNode("div", {
                  class: _normalizeClass(["chart-container min-height-modal relative", `${_ctx.loading ? 'opa-01' : ''}`])
                }, [
                  (_ctx.humanSkillsRenderChartValue > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_206, " Loading... "))
                    : _createCommentVNode("", true),
                  _cache[62] || (_cache[62] = _createElementVNode("div", { class: "m-auto mt-4" }, [
                    _createElementVNode("div", { class: "chart-label-fixed" }, [
                      _createElementVNode("div", {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, [
                        _createElementVNode("div", {
                          class: "box underline",
                          style: {"width":"200px"}
                        }, [
                          _createElementVNode("div", { class: "header-text" }, _toDisplayString(`Written Communication`))
                        ])
                      ])
                    ])
                  ], -1)),
                  _createElementVNode("div", _hoisted_207, [
                    _cache[60] || (_cache[60] = _createElementVNode("div", { class: "chart-label-fixed" }, [
                      _createElementVNode("div", {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, [
                        _createElementVNode("div", {
                          class: "box underline",
                          style: {"left":"3rem"}
                        }, [
                          _createElementVNode("div", { class: "header-text" }, _toDisplayString(`Empathy`))
                        ])
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_208, [
                      _createElementVNode("canvas", _hoisted_209, null, 512)
                    ]),
                    _cache[61] || (_cache[61] = _createElementVNode("div", { class: "chart-label-fixed" }, [
                      _createElementVNode("div", {
                        class: "box-item",
                        effect: "light",
                        placement: "top"
                      }, [
                        _createElementVNode("div", { class: "box underline" }, [
                          _createElementVNode("div", { class: "header-text" }, [
                            _createTextVNode(_toDisplayString(`Situational`)),
                            _createElementVNode("br"),
                            _createTextVNode(_toDisplayString(`Awareness`))
                          ])
                        ])
                      ])
                    ], -1))
                  ]),
                  _cache[63] || (_cache[63] = _createElementVNode("div", { class: "chart-label-fixed" }, [
                    _createElementVNode("div", {
                      class: "box-item",
                      effect: "light",
                      placement: "top"
                    }, [
                      _createElementVNode("div", {
                        class: "box underline",
                        style: {"width":"200px"}
                      }, [
                        _createElementVNode("div", { class: "header-text" }, _toDisplayString(`Critical Thinking`))
                      ])
                    ])
                  ], -1))
                ], 2),
                _createElementVNode("div", _hoisted_210, [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_211, [
                        _createElementVNode("div", _hoisted_212, [
                          _createElementVNode("div", _hoisted_213, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["candidate-line", `${_ctx.loading ? 'opa-03' : ''}`])
                            }, null, 2)
                          ]),
                          _cache[64] || (_cache[64] = _createElementVNode("div", {
                            class: "label ellipsis text-skeleton",
                            style: {"width":"7rem"}
                          }, "   ", -1))
                        ]),
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (value, index) => {
                          return _withDirectives(_createElementVNode("div", {
                            key: value + '_mock_' + index,
                            class: _normalizeClass(["flex-center candidate-name", `opa-0${9 - index}`])
                          }, _cache[65] || (_cache[65] = [
                            _createElementVNode("div", {
                              class: "absolute-mid",
                              style: {"left":"1rem"}
                            }, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: `border-color: #eee`
                              })
                            ], -1),
                            _createElementVNode("div", {
                              class: "label text-skeleton",
                              style: {"width":"10rem"}
                            }, "   ", -1)
                          ]), 2), [
                            [_vShow, index != 0]
                          ])
                        }), 64))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_214, [
                        _createElementVNode("div", _hoisted_215, [
                          _cache[66] || (_cache[66] = _createElementVNode("div", {
                            class: "absolute-mid",
                            style: {"left":"1rem"}
                          }, [
                            _createElementVNode("div", { class: "candidate-line" })
                          ], -1)),
                          (_ctx.radarChartHumanSkillsCandidateData)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_216, _toDisplayString(_ctx.radarChartHumanSkillsCandidateData[0].name), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarChartHumanSkillsCandidateData, (value, index) => {
                          return _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: value.name + index,
                            class: _normalizeClass(["flex-center candidate-name cursor-pointer", _ctx.candidateActiveClassOnHumanSkillsPreference(value.id)]),
                            onClick: ($event: any) => (_ctx.actionOnHumanSkillsCandidate(value))
                          }, [
                            _createElementVNode("div", _hoisted_218, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: _normalizeStyle(`border-color: ${value.color}`)
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_219, _toDisplayString(value.name), 1)
                          ], 10, _hoisted_217)), [
                            [_vShow, index != 0]
                          ])
                        }), 128))
                      ]))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.logicTestModalStatus,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.logicTestModalStatus) = $event)),
        width: "960px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_220, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_221, "   "))
              : (_openBlock(), _createElementBlock("div", _hoisted_222, _toDisplayString(_ctx.logicTestSelected.label) + ": " + _toDisplayString(_ctx.logicTestSelected.value) + "% ", 1)),
            _createElementVNode("div", {
              class: _normalizeClass([`${_ctx.loading ? 'opa-03' : ''}`, "fs-14 mt-12"])
            }, _toDisplayString(_ctx.$t("assessmentResults.A_logic_test_profile_for")), 3),
            _createElementVNode("div", _hoisted_223, [
              _createElementVNode("div", _hoisted_224, [
                _createElementVNode("div", {
                  class: _normalizeClass(["chart-container min-height-modal relative", `${_ctx.loading ? 'opa-01' : ''}`])
                }, [
                  (_ctx.logicTestRenderChartValue > 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_225, " Loading... "))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_226, [
                    _createElementVNode("div", _hoisted_227, [
                      _createElementVNode("div", _hoisted_228, [
                        _createElementVNode("div", _hoisted_229, [
                          _createElementVNode("div", _hoisted_230, _toDisplayString(_ctx.$t("assessmentResults.Pattern_Recognition")), 1),
                          _createElementVNode("div", _hoisted_231, _toDisplayString(_ctx.$t("assessmentResults.trend_analysis")), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_232, [
                    _createElementVNode("div", _hoisted_233, [
                      _createElementVNode("div", _hoisted_234, [
                        _createElementVNode("div", _hoisted_235, [
                          _createElementVNode("div", _hoisted_236, _toDisplayString(_ctx.$t("assessmentResults.Problem_Resolving")), 1),
                          _createElementVNode("div", _hoisted_237, _toDisplayString(_ctx.$t("assessmentResults.strategic_planning")), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_238, [
                      _createElementVNode("canvas", _hoisted_239, null, 512)
                    ]),
                    _createElementVNode("div", _hoisted_240, [
                      _createElementVNode("div", _hoisted_241, [
                        _createElementVNode("div", _hoisted_242, [
                          _createElementVNode("div", _hoisted_243, _toDisplayString(_ctx.$t("assessmentResults.Logical_Reasoning")), 1),
                          _createElementVNode("div", _hoisted_244, _toDisplayString(_ctx.$t("assessmentResults.decision_making")), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_245, [
                    _createElementVNode("div", _hoisted_246, [
                      _createElementVNode("div", _hoisted_247, [
                        _createElementVNode("div", _hoisted_248, _toDisplayString(_ctx.$t("assessmentResults.Data_Interpretation")), 1),
                        _createElementVNode("div", _hoisted_249, _toDisplayString(_ctx.$t("assessmentResults.business_intelligence")), 1)
                      ])
                    ])
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_250, [
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_251, [
                        _createElementVNode("div", _hoisted_252, [
                          _createElementVNode("div", _hoisted_253, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["candidate-line", `${_ctx.loading ? 'opa-03' : ''}`])
                            }, null, 2)
                          ]),
                          _cache[67] || (_cache[67] = _createElementVNode("div", {
                            class: "label ellipsis text-skeleton",
                            style: {"width":"7rem"}
                          }, "   ", -1))
                        ]),
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4, 5], (value, index) => {
                          return _withDirectives(_createElementVNode("div", {
                            key: value + '_mock_' + index,
                            class: _normalizeClass(["flex-center candidate-name", `opa-0${9 - index}`])
                          }, _cache[68] || (_cache[68] = [
                            _createElementVNode("div", {
                              class: "absolute-mid",
                              style: {"left":"1rem"}
                            }, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: `border-color: #eee`
                              })
                            ], -1),
                            _createElementVNode("div", {
                              class: "label text-skeleton",
                              style: {"width":"10rem"}
                            }, "   ", -1)
                          ]), 2), [
                            [_vShow, index != 0]
                          ])
                        }), 64))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_254, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarChartLogicTestCandidateData, (value, index) => {
                          return _withDirectives((_openBlock(), _createElementBlock("div", {
                            key: value.name + index,
                            class: _normalizeClass(["flex-center candidate-name cursor-pointer", _ctx.candidateActiveClassOnLogicTestPreference(value.id)]),
                            onClick: ($event: any) => (_ctx.actionOnLogicTestCandidate(value))
                          }, [
                            _createElementVNode("div", _hoisted_256, [
                              _createElementVNode("div", {
                                class: "candidate-line",
                                style: _normalizeStyle(`border-color: ${value.color}`)
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_257, _toDisplayString(value.name), 1)
                          ], 10, _hoisted_255)), [
                            [_vShow, index != 0]
                          ])
                        }), 128))
                      ]))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.acceptableBehaviorsModalStatus,
        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.acceptableBehaviorsModalStatus) = $event)),
        width: "960px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_258, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_259, "   "))
              : (_openBlock(), _createElementBlock("div", _hoisted_260, _toDisplayString(_ctx.$t("assessmentsDetails.Scenario_Alignment")) + ": " + _toDisplayString(_ctx.acceptableBehaviorsDataDetail[0].value) + "% ", 1)),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_261, [
                  _createElementVNode("div", _hoisted_262, [
                    _createElementVNode("div", _hoisted_263, _toDisplayString(_ctx.$t("assessmentsDetails.Scenario")), 1),
                    _createElementVNode("div", _hoisted_264, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableData[0].candidateValue, (candidate, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'new_header_' + candidate.displayName + '_mock_' + index,
                          class: "flex-mid user-respondent"
                        }, [
                          _createElementVNode("div", _hoisted_265, _toDisplayString(candidate.displayName), 1)
                        ]))
                      }), 128))
                    ]),
                    _cache[69] || (_cache[69] = _createElementVNode("div", { class: "flex-mid percentage-label" }, "%", -1))
                  ]),
                  false
                    ? (_openBlock(), _createElementBlock("div", _hoisted_266))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_267, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "scenario-alignment-container",
                        key: 'new_' + item.name + '_mock_' + index
                      }, [
                        _createElementVNode("div", _hoisted_268, [
                          _createElementVNode("div", _hoisted_269, _toDisplayString(_ctx.$t("assessmentsDetails.Scenario")) + " " + _toDisplayString(item.order), 1),
                          _createElementVNode("div", _hoisted_270, _toDisplayString(item.question), 1),
                          (item.companyValueLabel)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                style: _normalizeStyle(`color: ${_ctx.companyValueColorHex(
                    item.companyValueLabel
                  )}`),
                                class: "mt-20 fs-12 fw-700 flex-center"
                              }, [
                                (_openBlock(), _createElementBlock("svg", _hoisted_271, [
                                  _createElementVNode("path", {
                                    d: "M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM3 5C3 6.10457 3.89543 7 5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5Z",
                                    fill: _ctx.companyValueColorHex(item.companyValueLabel)
                                  }, null, 8, _hoisted_272)
                                ])),
                                _createElementVNode("span", _hoisted_273, _toDisplayString(item.companyValueLabel), 1)
                              ], 4))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_274, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.candidateValue, (candidate, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: 
                    'new_header_' + candidate.displayName + '_mock_' + index
                  ,
                              class: "flex-mid user-respondent"
                            }, [
                              _createElementVNode("div", _hoisted_275, [
                                (candidate.value == '1' || candidate.value == '2')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_276, _cache[70] || (_cache[70] = [
                                      _createElementVNode("svg", {
                                        width: "20",
                                        height: "20",
                                        viewBox: "0 0 20 20",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }, [
                                        _createElementVNode("path", {
                                          d: "M3.33301 9.9992L7.4578 14.124L16.2966 5.28516",
                                          stroke: "#22BC66",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ])))
                                  : (candidate.value == '3')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_277, _cache[71] || (_cache[71] = [
                                        _createElementVNode("svg", {
                                          width: "20",
                                          height: "20",
                                          viewBox: "0 0 20 20",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg"
                                        }, [
                                          _createElementVNode("path", {
                                            d: "M15 15L5 5",
                                            stroke: "#FF4343",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round"
                                          }),
                                          _createElementVNode("path", {
                                            d: "M15 5L5 15",
                                            stroke: "#FF4343",
                                            "stroke-width": "1.5",
                                            "stroke-linecap": "round",
                                            "stroke-linejoin": "round"
                                          })
                                        ], -1)
                                      ])))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_278))
                              ])
                            ]))
                          }), 128))
                        ]),
                        _createElementVNode("div", _hoisted_279, _toDisplayString(item.valuePercentage) + "% ", 1)
                      ]))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_280, [
                  _createElementVNode("div", _hoisted_281, [
                    _createElementVNode("div", _hoisted_282, _toDisplayString(_ctx.$t("assessmentsDetails.Scenario")), 1),
                    _createElementVNode("div", _hoisted_283, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableMockData[0]
                  .candidateValue, (candidate, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: 'new_header_' + candidate.displayName + '_mock_' + index,
                          class: "flex-mid user-respondent"
                        }, _cache[72] || (_cache[72] = [
                          _createElementVNode("div", { class: "ellipsis pl-10 pr-10" }, [
                            _createElementVNode("div", {
                              class: "text-skeleton",
                              style: {"width":"50%"}
                            }, " ")
                          ], -1)
                        ])))
                      }), 128))
                    ]),
                    _cache[73] || (_cache[73] = _createElementVNode("div", { class: "flex-mid percentage-label" }, "%", -1))
                  ]),
                  _cache[78] || (_cache[78] = _createElementVNode("div", { style: {"margin-top":"5.4rem"} }, null, -1)),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptableMockData, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["scenario-alignment-container", `opa-0${9 - index}`]),
                        key: 'new_' + item.name + '_mock_' + index
                      }, [
                        _createElementVNode("div", _hoisted_284, [
                          _createElementVNode("div", _hoisted_285, _toDisplayString(_ctx.$t("assessmentsDetails.Scenario")) + " - ", 1),
                          _cache[74] || (_cache[74] = _createElementVNode("div", {
                            class: "fs-12 mt-12",
                            style: {"line-height":"160%"}
                          }, [
                            _createElementVNode("div", {
                              class: "text-skeleton",
                              style: {"width":"100%"}
                            }, " "),
                            _createElementVNode("div", {
                              class: "text-skeleton mt-2",
                              style: {"width":"100%"}
                            }, "   "),
                            _createElementVNode("div", {
                              class: "text-skeleton mt-2",
                              style: {"width":"60%"}
                            }, "   ")
                          ], -1)),
                          _cache[75] || (_cache[75] = _createElementVNode("div", { class: "mt-20 mt-20 fs-12 fw-700" }, [
                            _createElementVNode("div", {
                              class: "text-skeleton",
                              style: {"width":"11rem"}
                            }, " ")
                          ], -1))
                        ]),
                        _createElementVNode("div", _hoisted_286, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.candidateValue, (candidate, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: 
                    'new_header_' + candidate.displayName + '_mock_' + index
                  ,
                              class: "flex-mid user-respondent"
                            }, _cache[76] || (_cache[76] = [
                              _createElementVNode("div", { class: "flex-mid pl-10 pr-10 opa-04" }, [
                                _createElementVNode("div", { class: "fs-26" }, "-")
                              ], -1)
                            ])))
                          }), 128))
                        ]),
                        _cache[77] || (_cache[77] = _createElementVNode("div", { class: "percentage-label flex-mid" }, "--%", -1))
                      ], 2))
                    }), 128))
                  ])
                ]))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}