import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  addDoc,
  collection,
  deleteField,
} from "firebase/firestore";

import _ from "lodash";
import {
  default as helpers,
  DEFAULT_TIMEZONE,
  DEFAULT_DATE_FORMAT,
} from "@/helpers/global";
import add from "date-fns/add";
import { formatInTimeZone } from "date-fns-tz";

const NS = "quota";

interface Quota {
  quota_total: number;
  expired_at?: string;
}

function getExpiredAt(now?: string, monthTotal = 1, days = 1): string {
  if (!now) now = helpers.now();
  if (helpers.isSafari()) {
    now = now.replace(/-/g, "/");
  }
  const date = new Date(now);
  const duration = {
    months: monthTotal,
    days: days,
  };
  const expiredDate = add(date, duration);
  const format = DEFAULT_DATE_FORMAT.replace("HH:mm:ss", "23:59:59");
  return formatInTimeZone(expiredDate, DEFAULT_TIMEZONE, format);
}

export default {
  namespaced: true,
  state: {
    error: "",
    loading: false,
    data: null,
    canInvite: false,
    canAdvancedInvite: false,
    canUseCredits: false,
  },
  mutations: {
    error(state: { error: boolean }, error: boolean): any {
      state.error = error;
    },
    loading(state: { loading: boolean }, loading: boolean): any {
      state.loading = loading;
    },
    data(state: { data: Quota }, data: Quota): any {
      state.data = data;
    },
    canInvite(state: { canInvite: boolean }, canInvite: boolean): any {
      state.canInvite = canInvite;
    },
    canAdvancedInvite(
      state: { canAdvancedInvite: boolean },
      canAdvancedInvite: boolean
    ): any {
      state.canAdvancedInvite = canAdvancedInvite;
    },
    canUseCredits(
      state: { canUseCredits: boolean },
      canUseCredits: boolean
    ): any {
      state.canUseCredits = canUseCredits;
    },
  },
  getters: {
    error(state: { error: boolean }): any {
      return state.error;
    },
    loading(state: { loading: boolean }): any {
      return state.loading;
    },
    data(state: { data: Quota }): Quota {
      return state.data;
    },
    canInvite(state: { canInvite: boolean }): any {
      return state.canInvite;
    },
    canAdvancedInvite(state: { canAdvancedInvite: boolean }): any {
      return state.canAdvancedInvite;
    },
    canUseCredits(state: { canUseCredits: boolean }): any {
      return state.canUseCredits;
    },
  },
  actions: {
    async upsertQuota(
      { commit }: { commit: any },
      { email, formDetails }: { email: string; formDetails: Quota }
    ): Promise<any> {
      // @todo validate email
      if (!email) {
        commit("error", "Invalid email");
        return;
      }
      commit("loading", true);
      const _id = helpers.emailKey(email);
      const $db = getFirestore();
      await setDoc(doc($db, "quota", _id), formDetails, { merge: true });
      commit("loading", false);
    },
    async createDefaultQuota(
      { dispatch }: { dispatch: any },
      { email }: { email: string }
    ): Promise<any> {
      const now = helpers.now();
      // const expiredAt = getExpiredAt(now, 1);
      const formDetails = {
        quota_used: 0,
        quota_remaining: 5,
        quota_total: 5,
        default_quota_total: 5,
        advanced_quota_total: 0,
        unlimited: false,
        // expired_at: expiredAt,
        plan: "free",
        free_monthly_reset_at: getExpiredAt(now, 1, 0),
        created_at: now,
      };
      await dispatch(
        NS + "/upsertQuota",
        { email, formDetails },
        { root: true }
      );
    },
    async createDefaultQuotaByTeamId(
      { commit }: { commit: any },
      {
        teamId,
        creator,
        owner,
      }: { teamId: string; creator: string; owner: string }
    ): Promise<any> {
      const now = helpers.now();
      // const expiredAt = getExpiredAt(now, 1);
      const formDetails = {
        creator: creator,
        owner: owner,
        quota_used: 0,
        quota_remaining: 0,
        quota_total: 0,
        default_quota_total: 0,
        advanced_quota_total: 0,
        unlimited: false,
        // expired_at: expiredAt,
        plan: "free",
        free_monthly_reset_at: getExpiredAt(now, 1, 0),
        created_at: now,
      };

      commit("loading", true);

      // prevent to lower case from orignal createDefaultQuota() to transform emailKey
      const fs = getFirestore();
      await setDoc(doc(fs, "quota", teamId), formDetails, { merge: true });

      commit("loading", false);
    },
    // @todo firestore function: update expired at (date)
    async updateExpiredAt(
      { commit, dispatch }: { commit: any; dispatch: any },
      { email, months, plan }: { email: string; plan: string; months: number }
    ): Promise<any> {
      const _id = helpers.emailKey(email);
      const $db = getFirestore();
      const docRef = doc($db, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const now = helpers.now();
        const formDetails = {
          plan: plan,
          unlimited: true,
          expired_at: getExpiredAt(data.expired_at, months),
          updated_at: now,
        };
        await dispatch(
          "quota/upsertQuota",
          { email: email, formDetails: formDetails },
          { root: true }
        );
      } else {
        commit("error", "Quota was not found.");
      }
    },
    // @todo firestore function: event after paid then checking SKU
    async addAdvancedQuotaTotal(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { email, credit, plan }: { email: string; credit: number; plan: string }
    ): Promise<any> {
      // console.log(`addAdvancedQuotaTotal(${email}, ${credit}, ${plan})`);

      const fs = getFirestore();
      let _id;

      // user quota from team owner
      const user = await dispatch("user/getUser", email, { root: true });
      const teamId = user.teamId;
      await dispatch("team/getTeam", { teamId: teamId }, { root: true });
      const team = rootState.team.team;

      if (team && team.personal === false) {
        _id = teamId;
      } else {
        _id = helpers.emailKey(email);
      }

      const docRef = doc(fs, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const now = helpers.now();
        const data = docSnap.data();
        // console.log(data, "data");
        credit = Number(credit);
        data.advanced_quota_total =
          parseInt(data.advanced_quota_total) + credit;

        // @todo on change (firestore function)
        data.quota_total = parseInt(data.quota_total) + credit;
        data.quota_remaining = parseInt(data.quota_remaining) + credit;

        data.plan = plan;
        data.unlimited = plan == "unlimited";
        data.updated_at = now;

        const fields = [
          "plan",
          "advanced_quota_total",
          "quota_total",
          "quota_remaining",
          "unlimited",
          "updated_at",
        ];
        const formDetails = _.pick(data, fields);
        // await dispatch(
        //   NS + "/upsertQuota",
        //   { email, formDetails },
        //   { root: true }
        // );

        await setDoc(doc(fs, "quota", _id), formDetails, { merge: true });
      } else {
        commit("error", "Not found user: " + email);
      }
    },
    // @todo firestore function: event on completed assessment
    // WARNING: updateAcceptableBehavior (prevent RECOMPUTE)
    async processQuotaSummariesAfterCompleted(
      { commit, dispatch }: { commit: any; dispatch: any; rootState: any },
      params: { doc: any }
    ): Promise<any> {
      commit("loading", true);
      const document = params.doc;
      const data = document.data();

      if (data.status != "COMPLETED") {
        commit("loading", false);
        return;
      }

      const $db = getFirestore();

      // const creator = data.creator;
      // let _id = helpers.emailKey(creator);

      const formId = data.form_id;
      const docSnap = await getDoc(doc($db, "assessment_templates", formId));
      if (docSnap.exists()) {
        const temaplate = docSnap.data();
        const teamId = temaplate.team_id;

        await dispatch(
          "quota/useCredits",
          {
            teamId,
            credits: 1,
            scope: "assessment_completed",
          },
          { root: true }
        );
      }

      /*
      const docRef = doc($db, "quota", _id);
      docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const quotaData = docSnap.data();
        const fields = [
          "advanced_quota_total",
          "plan",
          "quota_remaining",
          "quota_total",
          "quota_used",
        ];
        const updatedData = _.pick(quotaData, fields);
        updatedData.quota_used++;
        updatedData.quota_remaining--;
        updatedData.quota_remaining = Math.max(updatedData.quota_remaining, 0);

        // @todo keep transactios, logs, can describe what's happen
        const now = helpers.now();
        updatedData.updated_at = now;
        await setDoc(docRef, updatedData, {
          merge: true,
        });

        const credits = 1;
        const scope = "assessment_completed";
        await addDoc(collection($db, "quota_history"), {
          email: creator,
          credits: credits,
          type: "withdraw",
          scope: scope,
          created_at: helpers.now(),
        });
      }
      //*/

      commit("loading", false);
    },
    async load(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      if (!email) return;
      commit("loading", true);
      let _id = helpers.emailKey(email);

      const fs = getFirestore();

      // user quota from team owner
      const user = await dispatch("user/getUser", email, { root: true });
      const teamId = user.teamId;
      let team;
      if (teamId) {
        await dispatch("team/getTeam", { teamId: teamId }, { root: true });
        team = rootState.team.team;
      }
      if (team) {
        _id = helpers.emailKey(team.owner);

        // Does the user have credits yet?
        const docRef = doc(fs, "quota", teamId);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          // auto create quota for the team
          await dispatch(
            "quota/createDefaultQuotaByTeamId",
            { teamId, creator: team.creator, owner: team.owner },
            { root: true }
          );
        }
      }

      let isPersonal = true;
      if (team && team.personal === false) {
        isPersonal = false;
      }

      const checkCanInvite = function (data: any): any {
        if (process.env.VUE_APP_PAYMENT_ENABLED !== "on") return true;
        if (!data) return false;
        if (data.unlimited) return true;
        return parseInt(data.quota_remaining) > 0;
      };

      const checkAdvancedCanInvite = function (data: any): any {
        if (process.env.VUE_APP_PAYMENT_ENABLED !== "on") return true;
        if (!data) return false;
        return _.get(data, "plan", "free") != "free";
      };

      let data = null;

      const docRef = doc(fs, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        data = docSnap.data();
        // commit("data", data);
        commit("canInvite", checkCanInvite(data));
        commit("canAdvancedInvite", checkAdvancedCanInvite(data));
      } else {
        // auto create in firestore funciton (cloud function)
        // @todo bind this on login (setup al) or on create new user
        await dispatch(
          "quota/createDefaultQuota",
          { email: email },
          { root: true }
        );
        const docRef = doc(fs, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          data = docSnap.data();
          // commit("data", data);
          commit("canInvite", checkCanInvite(data));
          commit("canAdvancedInvite", checkAdvancedCanInvite(data));
        } else {
          commit("error", `Can't create quota for user: ${email}`);
        }
      }

      // merge user quota and team quota (user + team)
      if (!isPersonal) {
        const docRef = doc(fs, "quota", teamId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData: any = _.cloneDeep(data);
          const teamData = docSnap.data();
          // console.log(userData, "userData");
          // console.log(teamData, "teamData");
          userData.unlimited |= teamData.unlimited;

          // merge quota with unlimited case
          userData.quota_remaining += teamData.quota_remaining;
          userData.quota_total += teamData.quota_total;
          userData.quota_used += teamData.quota_used;

          data = userData;
        }
      }

      if (data) {
        commit("data", data);
      }

      commit("loading", false);
    },
    async canUseCredits(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      { teamId, credits }: { teamId: string; credits: number; scope?: string }
    ): Promise<any> {
      // console.log(`canUseCredits()`);
      // console.log(`teamId ${teamId} use ${credits} credits`);

      if (!teamId) {
        commit("canUseCredits", false);
        commit("error", `Unfefined teamId: ${teamId}`);
        return;
      }

      await dispatch("team/getTeam", { teamId: teamId }, { root: true });
      const team = rootState.team.team;
      if (!team) {
        commit("canUseCredits", false);
        commit("error", `Can't find team: ${teamId}`);
        return;
      }

      const fs = getFirestore();

      if (team.personal === true) {
        // use credis from user quota
        const _id = helpers.emailKey(team.owner);
        const docRef = doc(fs, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          commit("canUseCredits", false);
          return;
        }
        const userQuota = docSnap.data();
        // console.log(userQuota, "userQuota");

        if (userQuota.unlimited) {
          commit("canUseCredits", true);
          return;
        }

        if (userQuota.quota_remaining >= credits) {
          commit("canUseCredits", true);
          return;
        }

        commit("canUseCredits", false);
        commit("error", `Not enough credits for personal user: ${team.owner}`);
        return;
      } else {
        const _id = teamId;
        const docRef = doc(fs, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          commit("canUseCredits", false);
          commit("error", `Not found credits for team: ${teamId}`);
          return;
        }

        const teamQuota = docSnap.data();
        // console.log(teamQuota, "teamQuota");

        if (teamQuota.unlimited) {
          commit("canUseCredits", true);
          return;
        }

        const teamCredits = teamQuota.quota_remaining;
        let ownerCredits = 0;
        // console.log(credits, "credits");
        // console.log(teamCredits, "teamCredits");

        if (teamCredits >= credits) {
          commit("canUseCredits", true);
          return;
        }

        // borrow credits from owner
        const owner = team.owner;
        if (owner) {
          const _id = helpers.emailKey(owner);
          const docRef = doc(fs, "quota", _id);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            commit("canUseCredits", false);
            commit("error", `Not found credits for owner: ${owner}`);
            return;
          }

          const ownerQuota = docSnap.data();
          // console.log(ownerQuota, "ownerQuota");

          if (ownerQuota.unlimited) {
            commit("canUseCredits", true);
            return;
          }

          ownerCredits = ownerQuota.quota_remaining;
          // console.log(ownerCredits, "ownerCredits", _id);

          if (ownerCredits >= credits) {
            commit("canUseCredits", true);
            return;
          }

          const totalCredits = ownerCredits + teamCredits;
          // console.log(totalCredits, "totalCredits");
          if (totalCredits >= credits) {
            commit("canUseCredits", true);
            return;
          }

          commit("canUseCredits", false);
          commit(
            "error",
            `Not enough credits for btoh team: ${team.name}, ID: ${teamId}} and owner: ${owner}`
          );
          return;
        }

        commit("canUseCredits", false);
        commit(
          "error",
          `Not enough credits for team: ${team.name}, ID: ${teamId}}`
        );
        return;
      }
    },
    async useCredits(
      {
        commit,
        dispatch,
        getters,
        rootState,
      }: { commit: any; dispatch: any; getters: any; rootState: any },
      {
        teamId,
        credits,
        scope,
        variables,
      }: { teamId: string; credits: number; scope: string; variables?: any }
    ): Promise<any> {
      // console.log(`useCredits()`);
      // console.log(`use ${credits} credits for ${scope}`);
      commit("loading", true);

      credits = Number(credits);
      // console.group("useCredits");
      await dispatch("canUseCredits", { teamId: teamId, credits, scope });
      // console.groupEnd();
      if (getters["canUseCredits"] === false) {
        console.log("Can't use credits");
        return;
      }

      await dispatch("team/getTeam", { teamId: teamId }, { root: true });
      const team = rootState.team.team;
      if (!team) {
        commit("error", `Can't find team: ${teamId}`);
        return;
      }

      const fs = getFirestore();

      // console.log(team.personal, "team.personal");

      if (team.personal === true) {
        const _id = helpers.emailKey(team.owner);
        const docRef = doc(fs, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userQuota = docSnap.data();

          // usage
          userQuota.quota_used += credits;
          if (!userQuota.unlimited) {
            userQuota.quota_remaining -= credits;
          }
          userQuota.updated_at = helpers.now();

          const updatedData = {
            quota_used: userQuota.quota_used,
            quota_remaining: userQuota.quota_remaining,
            updated_at: userQuota.updated_at,
          };

          await setDoc(docRef, updatedData, {
            merge: true,
          });

          await addDoc(collection(fs, "quota_history"), {
            email: team.owner,
            teamId: teamId,
            credits: credits,
            credits_remaining: userQuota.unlimited
              ? "-"
              : userQuota.quota_remaining,
            type: "withdraw",
            scope: scope,
            variables: variables ? variables : {},
            created_at: helpers.now(),
          });
        }
      } else {
        const _id = teamId;
        const docRef = doc(fs, "quota", _id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const teamQuota = docSnap.data();
          // console.log(_id, "_id");
          // console.log(teamQuota, "teamQuota");

          // used by team frist
          if (teamQuota.unlimited || teamQuota.quota_remaining >= credits) {
            teamQuota.quota_used += credits;
            if (!teamQuota.unlimited) {
              teamQuota.quota_remaining -= credits;
            }
            teamQuota.updated_at = helpers.now();

            const updatedData = {
              quota_used: teamQuota.quota_used,
              quota_remaining: teamQuota.quota_remaining,
              updated_at: teamQuota.updated_at,
            };

            await setDoc(docRef, updatedData, {
              merge: true,
            });

            // @todo common function
            await addDoc(collection(fs, "quota_history"), {
              email: team.owner,
              teamId: teamId,
              credits: credits,
              credits_remaining: teamQuota.unlimited
                ? "-"
                : teamQuota.quota_remaining,
              type: "withdraw",
              scope: scope,
              variables: variables ? variables : {},
              created_at: helpers.now(),
            });

            commit("loading", false);
            return;
          } else if (!teamQuota.unlimited && teamQuota.quota_remaining > 0) {
            // console.log("withdraw from both team and owner");

            // console.log(credits, "credits");
            // console.log(teamQuota.quota_remaining, "teamQuota.quota_remaining");

            let ownerQuota;

            const owner = team.owner;
            let docOwnerRef;
            if (owner) {
              const _id = helpers.emailKey(owner);
              // console.log(_id, "docOwnerRef: _id");
              docOwnerRef = doc(fs, "quota", _id);
              const docOwnerSnap = await getDoc(docOwnerRef);
              if (docOwnerSnap.exists()) {
                ownerQuota = docOwnerSnap.data();
              }
            }

            if (ownerQuota) {
              console.log(
                ownerQuota.quota_remaining,
                "ownerQuota.quota_remaining",
                helpers.emailKey(owner)
              );

              // console.log(ownerQuota, "ownerQuota");

              if (!ownerQuota.unlimited && ownerQuota.quota_remaining > 0) {
                const teamCredits = teamQuota.quota_remaining;
                const ownerCredits = ownerQuota.quota_remaining;
                const totalCredits = ownerCredits + teamCredits;
                // console.log(totalCredits, "totalCredits");
                // console.log(credits, "credits");
                if (credits > teamCredits && totalCredits >= credits) {
                  console.log("Separate withdraw from two quota");

                  teamQuota.quota_used += teamQuota.quota_remaining;
                  if (!teamQuota.unlimited) {
                    teamQuota.quota_remaining -= teamQuota.quota_remaining;
                  }
                  teamQuota.updated_at = helpers.now();

                  let updatedData = {};

                  updatedData = {
                    quota_used: teamQuota.quota_used,
                    quota_remaining: teamQuota.quota_remaining,
                    updated_at: teamQuota.updated_at,
                  };

                  await setDoc(docRef, updatedData, {
                    merge: true,
                  });

                  // @todo common function
                  await addDoc(collection(fs, "quota_history"), {
                    email: team.owner,
                    teamId: teamId,
                    credits: teamQuota.quota_remaining,
                    credits_remaining: teamQuota.unlimited
                      ? "-"
                      : teamQuota.quota_remaining,
                    type: "withdraw",
                    scope: scope,
                    variables: variables ? variables : {},
                    created_at: helpers.now(),
                  });

                  // new remaing request credits for owenr withdraw
                  credits -= teamQuota.quota_remaining;

                  // owner withdraw
                  ownerQuota.quota_used += credits;
                  if (!ownerQuota.unlimited) {
                    ownerQuota.quota_remaining -= credits;
                  }
                  ownerQuota.updated_at = helpers.now();

                  updatedData = {
                    quota_used: ownerQuota.quota_used,
                    quota_remaining: ownerQuota.quota_remaining,
                    updated_at: ownerQuota.updated_at,
                  };

                  // console.log(docOwnerRef, "docOwnerRef");
                  // console.log(updatedData, "updatedData");
                  if (docOwnerRef) {
                    await setDoc(docOwnerRef, updatedData, {
                      merge: true,
                    });

                    // @todo common function
                    await addDoc(collection(fs, "quota_history"), {
                      email: owner,
                      teamId: helpers.emailKey(owner),
                      credits: credits,
                      credits_remaining: ownerQuota.unlimited
                        ? "-"
                        : ownerQuota.quota_remaining,
                      type: "withdraw",
                      scope: scope,
                      variables: variables ? variables : {},
                      created_at: helpers.now(),
                    });

                    console.log("yesss");
                  }

                  commit("loading", false);
                  return;
                }
              }
            }
          }

          // borrow credits from owner
          const owner = team.owner;
          if (owner) {
            const _id = helpers.emailKey(owner);
            const docRef = doc(fs, "quota", _id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              const ownerQuota = docSnap.data();
              // console.log(ownerQuota, "ownerQuota");

              if (
                ownerQuota.unlimited ||
                ownerQuota.quota_remaining >= credits
              ) {
                ownerQuota.quota_used += credits;
                if (!ownerQuota.unlimited) {
                  ownerQuota.quota_remaining -= credits;
                }
                ownerQuota.updated_at = helpers.now();

                const updatedData = {
                  quota_used: ownerQuota.quota_used,
                  quota_remaining: ownerQuota.quota_remaining,
                  updated_at: ownerQuota.updated_at,
                };

                await setDoc(docRef, updatedData, {
                  merge: true,
                });

                // @todo common function
                await addDoc(collection(fs, "quota_history"), {
                  email: owner,
                  teamId: teamId,
                  credits: credits,
                  credits_remaining: ownerQuota.unlimited
                    ? "-"
                    : ownerQuota.quota_remaining,
                  type: "withdraw",
                  scope: scope,
                  variables: variables ? variables : {},
                  created_at: helpers.now(),
                });
              }
            }
          }
        }
      }

      commit("loading", false);

      /*

      const email = _.get(rootState, "user.user.email");
      const _id = helpers.emailKey(email);

      // @fixed test
      if (_id) return;

      

      // user quota from team owner
      const user = await dispatch("user/getUser", email, { root: true });
      await dispatch("team/getTeam", { teamId: user.teamId }, { root: true });
      const team = rootState.team.team;
      // let teamId = "";
      if (team) {
        _id = helpers.emailKey(team.owner);
        // teamId = team._id;
      }

      const fs = getFirestore();
      const docRef = doc(fs, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // @todo check unlimited
        if (!data.unlimited) {
          if (data.quota_remaining < credits) {
            commit("error", "Not enough quota");
            return;
          }
        }

        data.quota_used += credits;
        if (!data.unlimited) {
          data.quota_remaining -= credits;
        }
        data.updated_at = helpers.now();

        const updatedData = {
          quota_used: data.quota_used,
          quota_remaining: data.quota_remaining,
          updated_at: data.updated_at,
        };

        await setDoc(docRef, updatedData, {
          merge: true,
        });

        // add history
        await addDoc(collection(fs, "quota_history"), {
          email: email,
          teamId: teamId,
          credits: credits,
          credits_remaining: data.unlimited ? "-" : data.quota_remaining,
          type: "withdraw",
          scope: scope,
          variables: variables,
          created_at: helpers.now(),
        });

        commit("data", data);
      }
      */

      commit("loading", false);
    },
    async resetQuota(
      { commit }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      commit("loading", true);
      const _id = helpers.emailKey(email);
      const fs = getFirestore();
      const docRef = doc(fs, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const credit = _.get(data, "default_quota_total", 5);
        const formDetails = {
          plan: "free",
          quota_total: credit,
          quota_used: 0,
          quota_remaining: credit,
          advanced_quota_total: 0,
          expired_at: deleteField(),
          free_monthly_reset_at: deleteField(),
          unlimited: false,
          updated_at: helpers.now(),
        };
        await setDoc(doc(fs, "quota", _id), formDetails, { merge: true });

        commit("data", { ...data, ...formDetails });
      }
      commit("loading", false);
    },
    async expireQuota(
      { commit }: { commit: any; dispatch: any; rootState: any },
      { email }: { email: string }
    ): Promise<any> {
      commit("loading", true);
      const _id = helpers.emailKey(email);
      const fs = getFirestore();
      const docRef = doc(fs, "quota", _id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate plabn
        const plan = _.get(data, "plan", "free");
        if (plan === "free") {
          console.log("No action on free plan.");
          return;
        }

        // @todo validate expired_at

        const now = helpers.now();
        const formDetails = {
          quota_used: parseInt(_.get(data, "quota_used", 0)),
          quota_total: parseInt(_.get(data, "quota_total", 0)),
          quota_remaining: parseInt(_.get(data, "quota_remaining", 0)),
          advanced_quota_total: 0,
          plan: "free",
          unlimited: false,
          expired_at: deleteField(),
          free_monthly_reset_at: deleteField(),
          updated_at: now,
        };

        if (formDetails.plan == "unlimited") {
          formDetails.quota_remaining = 5;
          formDetails.quota_used = 0;
          formDetails.quota_total = 5;
        }

        await setDoc(docRef, formDetails, { merge: true });

        commit("data", { ...data, ...formDetails });
      }
      commit("loading", false);
    },
  },
};
